import React , { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import WithPermission from '../components/WithPermission';
import ReactPaginate from 'react-paginate';

let limit = 10;
const CleanerTransaction = (props) => {
    const token = Cookies.get('token');
    const [PageCount, setPageCount] = useState(0);
    const [earningList, setEarningList] = useState([]);
    const [filterRecords, setFilterRecords] = useState({
        sortBy: "desc",
        type: ""
    });

    useEffect(() => {
        fetchEarningList();
    }, [filterRecords]);

    const fetchEarningList = async (datas) => {
        props.setLoading(true);
        let pageNo = (datas) ? datas.selected : 0;
        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'dashboard/earning',
                        {
                            role_id: 1,
                            page: pageNo,
                            limit: limit,
                            filterRecords: filterRecords
                        },{
                            headers: {
                                'Content-Type': 'application/json',
                                'token': token
                            }
                        })
                        .catch(function (error) {
                            props.setLoading(false);
                        });

        props.setLoading(false);
        if(data.status === 1){
            setPageCount(data.total_pages);
            setEarningList(data.result);
        }
    };

    const handleFilters = ( keyValue, value) => {
        filterRecords[keyValue] = value
        setFilterRecords({ ...filterRecords })
    }

    return (
        <>
        <WithPermission module="Commission" name="list_commission" message="yes" multiple="false">
        <div className="page-content">
          <div className="container-fluid">
              <div className="row">
                  <div className="col-12">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                          <h4 className="mb-sm-0">Commissions</h4>
                          <div className="page-title-right">
                              <ol className="breadcrumb m-0">
                                  <li className="breadcrumb-item">
                                      <Link to="/">Home</Link>
                                  </li>
                                  <li className="breadcrumb-item active">Commission</li>
                              </ol>
                          </div>

                      </div>
                  </div>
              </div>


              <div className="row">

                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {/* <div className="col-md-3">
                                    <input type="text" className="form-control" 
                                        name="searchText"
                                        onChange={(e) => handleFilters('searchText', e.target.value)}
                                        value={filterRecords.searchText}
                                        placeholder="Search by name, email and phone number" />
                                </div> */}
                                <div className="col-md-3">
                                    <select 
                                        className="form-select"
                                        value={filterRecords.type}
                                        onChange={(e) => handleFilters('type', e.target.value)}
                                    >
                                        <option value="">Filter by type</option>
                                        <option value="1">Host</option>
                                        <option value="2">Cleaner</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <select 
                                    className="form-select"
                                    value={filterRecords.sortBy}
                                    onChange={(e) => handleFilters('sortBy', e.target.value)}
                                    >
                                        <option value="">Sort by date</option>
                                        <option value="desc">DESC</option>
                                        <option value="asc">ASC</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                  <div className="col-xl-12">
                  {earningList.length > 0 ?
                    <>
                      <div className="card mt-0">
                          <div className="card-body">
                              <div className="table-responsive mb-3" id="transaction-table">
                                  <table className="table table-striped table-nowrap align-middle mb-0 ">
                                      <thead>
                                          <tr>
                                              <th scope="col">Assignment Name</th>
                                              <th scope="col" className="text-center">User Name</th>
                                              <th scope="col" className="text-center">Type</th>
                                              <th scope="col">Price</th>
                                              <th scope="col">Commission</th>
                                              <th scope="col">Date</th>
                                              <th scope="col">Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                      {
                                        (earningList.length >= 1) ?
                                            earningList.map(function(object, i){
                                                return <tr key={i}>
                                                            <td className="number">{object.title}</td>
                                                            <td className="number text-center"> {object.userName} </td>
                                                            <td className="number text-center"> {(object.payType === 1) ? "Host" : "Cleaner" } </td>
                                                            <td className="number">${parseFloat(object.price).toFixed(2)}</td>
                                                            <td>
                                                                <div className="number">${parseFloat(object.earning).toFixed(2)}</div>
                                                            </td>
                                                            <td>
                                                                <div className="number"> { moment(object.date).format('MMM Do YYYY') } </div>  
                                                            </td>
                                                            <td>
                                                                <Link to={`/commission-detail/${object._id}`} className="p-2 text-capitalize badge view-receipt-color">View Details</Link>
                                                            </td>
                                                        </tr>;
                                            })
                                        :
                                            <>
                                                <tr>
                                                    <td colSpan="4">No earning recorded yet</td>
                                                </tr>
                                            </>
                                      }
                                      </tbody>
                                  </table>
                              </div>
                              <ReactPaginate
                                    previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                    nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                    breakLabel="..."
                                    pageCount={PageCount}
                                    pageRangeDisplayed={3}
                                    onPageChange={fetchEarningList}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    renderOnZeroPageCount={null}
                                    activeClassName={'active'}
                                />
                          </div>
                          
                      </div>
                    </>
                      :
                      <div className="row">
                          <div className="col-xl-12">
                              <div className="alert alert-danger" role="alert">No earning recorded yet</div>
                          </div>
                      </div>
                  }
                      
                  </div>
              </div>
          </div>
        </div>
        </WithPermission>
    </>
    )
}

export default CleanerTransaction;
