import React,{useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from '../../fireabse';
import { doc, setDoc } from 'firebase/firestore';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';
import helper from '../../helper/index';

const AddCleaner = (props) => {

    const token = Cookies.get('token');
    // get user data from token in cookie
    const decoded = decodeToken(token);

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [EmailAddress, setEmailAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, SetConfirmPassword] = useState("");

    // Errors

    const [errorFirstName, setFNErrorMessage] = useState("");
    const [errorLastName, setLNErrorMessage] = useState("");
    const [errorEmail, setEErrorMessage] = useState("");
    const [errorPhone, setPErrorMessage] = useState("");
    const [errorPassword, setPDErrorMessage] = useState("");
    const [errorConfirmPassword, setCPErrorMessage] = useState("");

    const [type, setType] = useState('password');
    const [confirmType, setConfirmType] = useState('password');

    const submitData = async (event) => {

        event.preventDefault();

        setFNErrorMessage("");
        setLNErrorMessage("");
        setEErrorMessage("");
        setPErrorMessage("");
        setPDErrorMessage("");
        setCPErrorMessage("");

        let error  = 0;
        if(firstName.trim().length === 0) {
            setFNErrorMessage('First Name field is required.');
            error = 1;
        }
        if(lastName.trim().length === 0) {
            setLNErrorMessage('Last Name field is required.');
            error = 1;
        }
        if(EmailAddress.trim().length === 0) {
            setEErrorMessage('Email Address field is required.');
            error = 1;
        } else if (!helper.isValidEmail(EmailAddress)) {
            setEErrorMessage('Email Address is invalid.');
            error = 1;
        }
        if(phone.trim().length === 0) {
            setPErrorMessage('Phone Number field is required.');
            error = 1;
        } else if((phone.trim().length !== 10)){
            setPErrorMessage('Phone Number is invalid.');
            error = 1;
        }
        if(password.trim().length === 0) {
            setPDErrorMessage('Password field is required.');
            error = 1;
        }
        if(confirmPassword.trim().length === 0) {
            setCPErrorMessage('Confirm Password field is required.');
            error = 1;
        }else{
            if(password.trim() !== confirmPassword.trim()){
                setCPErrorMessage('Confirm Password is not matched with password field.');
                error = 1;
            }
        }

        if(error === 1){
            return false;
        }

        try {
            props.setLoading(true);
            // Create Host User
            const response = await axios.post(process.env.REACT_APP_BACKENDURL+'user/create-cleaner', {
                first_name: firstName.trim(),
                last_name: lastName.trim(),
                phone_number: phone.trim(), 
                email: EmailAddress.trim(),
                password: password.trim(),
                added_by: decoded.user_id,
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            });


            if(response.data.status === 1){

                // Authenticate firebase user
                const userCredential =  await createUserWithEmailAndPassword(auth, EmailAddress, response.data.id);
                const user = userCredential.user;

                // Save firebase uid in the database
                const resp = await axios.post(process.env.REACT_APP_BACKENDURL+'user/update-firebase-uid', {
                    uid: user.uid,
                    user_id: response.data.id,
                },{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if(resp.data.status === 1){

                    // Save name in firebase user
                    const update = await updateProfile(user, {
                        displayName: firstName + ' ' + lastName,
                    });

                    setDoc(doc(db, "users", user.uid), {
                        uid: user.uid,
                        name: firstName + ' ' + lastName,
                        email: EmailAddress
                    });

                    props.setLoading(false);
                    helper.Toast().fire({ icon: 'success', title: response.data.message }).then(() => {
                        navigate('/cleaner-list');
                    });

                }else{
                    props.setLoading(false);
                    helper.Toast().fire({ icon: 'success', title: response.data.message }).then(() => {
                        navigate('/cleaner-list');
                    });
                }
                
            }else{
                props.setLoading(false);
                helper.Toast().fire({ icon: 'error', title: response.data.message });
            }

        } catch (error) {
            props.setLoading(false);
            console.log('Error',error);
        }
    }

    const handleChange = (e) => {
        const result = e.target.value.replace(/\D/g, '');
        setPhone(result);
    };

    function capitalizeFirstLetter(str) {
        return str.trim().charAt(0).toUpperCase() + str.slice(1);
    }

    const handleToggle = (e, fieldName) => {
        e.preventDefault();
        if(fieldName === 'password') {
            if (type === 'password'){
                setType('text');
            } else {
                setType('password');
            }
        } else if(fieldName === 'confirm_password') {
            if (confirmType === 'password'){
                setConfirmType('text');
            } else {
                setConfirmType('password');
            }
        }
    }

    const asteriskMarkComponent = () => {
        return (<span style={{font: 'caption'}}>*</span>);
    }

    return (
        <div className="page-content">
            <div className="container-fluid">


                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Add New Cleaner</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/cleaner-list">Cleaner List</Link></li>
                                    <li className="breadcrumb-item active">Add New Cleaner</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="live-preview">
                                    <form onSubmit={submitData}>
                                        <div className="row gy-4">
                                            <div className="col-md-6">
                                                <label className="form-label">First Name{asteriskMarkComponent()}</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    value={firstName} 
                                                    onKeyDown={(e) => helper.checkOnlyAlphabetInput(e)} 
                                                    onChange={(e) => setFirstName(e.target.value = capitalizeFirstLetter(e.target.value))}
                                                    maxLength={30}
                                                />
                                                {errorFirstName && <div className="text-danger"> {errorFirstName} </div>}
                                            </div>
                                            
                                            <div className="col-md-6">
                                                <label className="form-label">Last Name{asteriskMarkComponent()}</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    value={lastName} 
                                                    onKeyDown={(e) => helper.checkOnlyAlphabetInput(e)} 
                                                    onChange={(e) => setLastName(e.target.value = capitalizeFirstLetter(e.target.value))} 
                                                    maxLength={30} 
                                                />
                                                {errorLastName && <div className="text-danger"> {errorLastName} </div>}
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label">Email{asteriskMarkComponent()}</label>
                                                <input type="text" 
                                                    className="form-control" 
                                                    value={EmailAddress}
                                                    onKeyDown={(e) => helper.checkSpaceInput(e)} 
                                                    onChange={(e) => setEmailAddress(e.target.value.replace(/\s/g, ''))} 
                                                    maxLength={50} />
                                                {errorEmail && <div className="text-danger"> {errorEmail} </div>}
                                            </div>
                                            
                                            <div className="col-md-6">
                                                <label className="form-label">Phone Number{asteriskMarkComponent()}</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    value={phone} 
                                                    onKeyDown={(e) => helper.checkNumericInput(e)}
                                                    onChange={handleChange} 
                                                    maxLength={10} />
                                                {errorPhone && <div className="text-danger"> {errorPhone} </div>}
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label">Password{asteriskMarkComponent()}</label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <input type={type} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} maxLength={50} />
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={(e) => handleToggle(e, 'password')} ><i className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                                {errorPassword && <div className="text-danger"> {errorPassword} </div>}
                                            </div>
                                            
                                            <div className="col-md-6">
                                                <label className="form-label">Confirm Password{asteriskMarkComponent()}</label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <input type={confirmType} className="form-control" value={confirmPassword} onChange={(e) => SetConfirmPassword(e.target.value)} maxLength={50}  />
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="confirm-password-addon" onClick={(e) => handleToggle(e, 'confirm_password')} ><i className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                                {errorConfirmPassword && <div className="text-danger"> {errorConfirmPassword} </div>}
                                            </div>
                                            
                                            <div className="col-12">
                                                <button className="btn btn-success" type="submit">Submit form</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>


                
            </div>
        </div>
    )
}

export default AddCleaner;
