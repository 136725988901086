import React, { useState } from 'react'
import { useAuth } from "../hooks/auth";
import BeatLoader from "react-spinners/ClipLoader";
import { useNavigate } from 'react-router-dom';
import helper from '../helper/index';

const Home = () => {
const navigate = useNavigate();
const { login } = useAuth();
const [password, setPassword] = useState('');
const [email, setEmail] = useState('');
const [type, setType] = useState('password');

const [errorEmail, setEmailMessage] = useState("");
const [errorPassword, setPasswordMessage] = useState("");

// eslint-disable-next-line no-unused-vars
let [loading, setLoading] = useState(false)

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#8f368dc7",
    position: "fixed",
    top: "40%",
    left: "50%",
    zIndex: "99999",
    transform: "translate(-50%, -40%)",
    border: "3px solid #8f368dc7"
};

const loderWrap  = {
    position: "absolute",
    width: "100%",
    background: "rgba(0,0,0,0.5)",
    height: "100%",
    zIndex: "99999999"
  };

const handleSubmit = async (event) => {
    event.preventDefault();
    setPasswordMessage('');
    setEmailMessage('');
    let error = 0;
    if(email.trim().length === 0) {
        setEmailMessage('Email field is required.');
        error = 1;
    }
    if(password.trim().length === 0) {
        setPasswordMessage('Password field is required.');
        error = 1;
    }

    if(error === 1){
        return;
    }
    setLoading(true)
    let res = await login({ email, password });
    setLoading(false)
    if(res){
        navigate('/dashboard');
    }
    
  };

    const handleToggle = () => {
        if (type==='password'){
            setType('text')
        } else {
            setType('password')
        }
    }

  return (    
    <>
     {loading && 
        <div style={loderWrap}>
            <BeatLoader
            color={'#8f368dc7'}
            loading={loading}
            cssOverride={override}
            size={125}
            aria-label="Loading Spinner"
            data-testid="loader"
            />
        </div>
    }
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                
                <div className="row g-0">
                        

                    <div className="col-lg-6 offset-3 mt-10rem">
                        <div className="card">
                            <div className="text-center mt-5">
                                <img src="/assets/images/logo-light.png" alt="" height="40" />
                            </div>
                            <div className="p-lg-5 p-4">

                                <form onSubmit={handleSubmit} >
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="email" 
                                            placeholder="Enter email"
                                            onKeyDown={(e) => helper.checkSpaceInput(e)}
                                            onChange={(e) => setEmail(e.target.value)} />
                                            {errorEmail && <div className="text-danger"> {errorEmail} </div>}
                                    </div>

                                    <div className="mb-3">
                                        {/* <div className="float-end">
                                            <a href="auth-pass-reset-cover.html" className="text-muted">Forgot password?</a>
                                        </div> */}
                                        <label className="form-label" htmlFor="password-input">Password</label>
                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                            <input 
                                                type={type} 
                                                className="form-control pe-5 password-input" 
                                                placeholder="Enter password" 
                                                id="password-input" 
                                                onChange={(e) => setPassword(e.target.value)} />
                                                {errorPassword && <div className="text-danger"> {errorPassword} </div>}

                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={handleToggle} ><i className="ri-eye-fill align-middle"></i></button>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <button className="btn btn-success w-100" type="submit" >Sign In</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    </>
  )
}

export default Home;
