import React,{useMemo, useEffect, useState} from 'react'
import {  useWizard } from 'react-use-wizard';
import ProgressBar from '../../../components/common/ProgressBar'
// import GoogleMapReact from 'google-map-react';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import AutoComplete from '../../../components/common/AutoComplete';
import helper from '../../../helper/index';

const Step2 = (props) => {
  const [ mapCenter, setMapCenter ] = useState({ lat: 37.09024, lng: -95.712891 });
  const center = useMemo(() => ({ lat: parseFloat(props.addressArray.lat), lng: parseFloat(props.addressArray.lng) }), [props.addressArray.lat, props.addressArray.lng]);
  const { previousStep, nextStep } = useWizard();

  const containerStyle = {
    width: '100%',
    height: '50vh'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
  })

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null)
  const [place, setPlace] = React.useState(null)

  useEffect(() => {
    if(place !== null && place !== undefined && place.geometry !== undefined){
      getAddressFromPlace(place)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[place])

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    var listener = new window.google.maps.event.addListener(map, "idle", function() { 
      if (map.getZoom() > 17) map.setZoom(17);
      new window.google.maps.event.removeListener(listener); 
    });

    setMap(map)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => {
    setMapCenter(center);
  }, [center, map])

  const getAddressFromPlace = (place) => {
    props.parentCallback('lat',place.geometry.location.lat())
    props.parentCallback('lng',place.geometry.location.lng())
    props.parentCallback('fullAddress',place.name);
    props.parentCallback('streetAddress',place.name);
    for (var j = 0; j < place.address_components.length; j++) {
        for (var k = 0; k < place.address_components[j].types.length; k++) {

            if (place.address_components[j].types[k] === "locality") {
                props.parentCallback('city',place.address_components[j].short_name);
            }
            if (place.address_components[j].types[k] === "postal_code") {
                props.parentCallback('pincode',place.address_components[j].short_name);
            }
            if (place.address_components[j].types[k] === "country") {
                props.parentCallback('country',place.address_components[j].long_name);
            }
            if (place.address_components[j].types[k] === "administrative_area_level_1") {
                props.parentCallback('state',place.address_components[j].long_name);
            }
        }
    }
}


  return (
    <>
    <ProgressBar stepNames={props.stepNames}/>
    <div className="card">
        <div className="card-body">
            <div className="live-preview">
              <div className="row gy-4">
                <div className='col-md-6'>
                  <div className='row'>
                    <div className="col-md-12">
                      <h4>Where is your place located</h4>
                      <label className="form-label" name='sub_type'>Enter Address</label>
                      {/* <input type="text" 
                        className="form-control mb-2" 
                        placeholder='Enter Address'
                        maxLength={50} /> */}
                        
                        {/* <Autocomplete
                          className="form-control mb-2" 
                          placeholder='Enter Address'
                          apiKey="AIzaSyBRoSvRF0nwsV83FS8Oh-Zl43Xgz8ebNSs"
                          defaultValue={props.addressArray.fullAddress}
                          onPlaceSelected={(place) => {
                            getAddressFromPlace(place)
                          }}
                        /> */}
                        <AutoComplete setPlace={setPlace}/>


                    {/* <GoogleMapReact 
                      bootstrapURLKeys={{ 
                          key: "AIzaSyBRoSvRF0nwsV83FS8Oh-Zl43Xgz8ebNSs",
                          libraries:['places'],
                      }} 
                    /> */}

                  { isLoaded ? (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={mapCenter}
                        zoom={17}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      >
                        <MarkerF position={mapCenter} />
                      </GoogleMap>
                  ) : <></>
                  }

                      {/* <div style={{ height: '50vh', width: '100%' }}>
                        <GoogleMapReact
                          bootstrapURLKeys={{ 
                              key: "AIzaSyBRoSvRF0nwsV83FS8Oh-Zl43Xgz8ebNSs",
                              libraries:['places'] 
                          }}
                          defaultCenter={defaultProps.center}
                          defaultZoom={defaultProps.zoom}
                          
                        >
                          <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text="My Marker"
                          />
                        </GoogleMapReact>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/*  *********************************************** */}

                <div className='col-md-6'>
                  <div className='row'>
                    <h4>Confirm Your Address</h4>
                    
                    <div className="col-md-12">
                      <label className="form-label" name='sub_type'>Country</label>
                      <input type="text" 
                        className="form-control mb-2" 
                        placeholder='Enter Country'
                        value={props.addressArray.country}
                        onChange={(e) => props.parentCallback('country', e.target.value)}
                        maxLength={50} />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label" name='sub_type'>Street Address</label>
                      <input type="text" 
                        className="form-control mb-2" 
                        value={props.addressArray.streetAddress}
                        onChange={(e) => props.parentCallback('streetAddress', e.target.value)}
                        placeholder='Enter Street Address'
                        maxLength={50} />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label" name='sub_type'>City</label>
                      <input type="text" 
                        className="form-control mb-2" 
                        placeholder='Enter City'
                        value={props.addressArray.city}
                        onChange={(e) => props.parentCallback('city', e.target.value)}
                        maxLength={50} />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label" name='sub_type'>State</label>
                      <input type="text" 
                        className="form-control mb-2" 
                        placeholder='Enter State'
                        value={props.addressArray.state}
                        onChange={(e) => props.parentCallback('state', e.target.value)}
                        maxLength={50} />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label" name='sub_type'>Zipcode</label>
                      <input type="text" 
                        className="form-control mb-2" 
                        placeholder='Enter Zipcode'
                        value={props.addressArray.pincode}
                        onKeyDown={(e) => helper.checkNumericInput(e)}
                        onChange={(e) => props.parentCallback('pincode', e.target.value)}
                        maxLength={5} />
                    </div>


                  </div>
                </div>
                
                
                <div className="col-12">
                    <button className="btn btn-success me-2" onClick={() => previousStep()}>Previous</button>
                    <button className="btn btn-success me-2" 
                            disabled={ !props.step3Validation ? true : false} 
                            onClick={() => nextStep()}>Next</button>
                </div>

              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Step2;