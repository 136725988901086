import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL =  process.env.REACT_APP_BACKENDURL;


export const socket = io(URL, {
  autoConnect: true,
  query: "token="
});
// export const socket = io(URL)