import { createContext, useContext, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
// import api from '../../services/api';
import axios from 'axios';
import { signInWithEmailAndPassword,signOut } from "firebase/auth";
import { db, auth } from '../../fireabse';
import { doc, updateDoc } from "firebase/firestore";
import { decodeToken } from "react-jwt";
import helper from '../../helper/index';
import { BroadcastChannel } from 'broadcast-channel';

const tabLogoutChannel = new BroadcastChannel('handleAdminTabsLogout');
const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const navigate = useNavigate();
    const [roleId, setRoleId] = useState(0);
    const [permission, setPermission] = useState([]);
    const login = async ({ email, password }) => {

        try {
          const response = await axios.post(process.env.REACT_APP_BACKENDURL+'general/adminLogin', {
            email: email,
            password: password
          },{
            headers: {
                'Content-Type': 'application/json'
            }
          });

          if(response.data.status === 1){
              // Set Token in cookie
              Cookies.set('token', response.data.data.token);
         
               // get user data from token in cookie
              // eslint-disable-next-line no-unused-vars
              const decoded = decodeToken(response.data.data.token);

              //firebase sign in
              // eslint-disable-next-line no-unused-vars
              var FData = await signInWithEmailAndPassword(auth, email, response.data.data._id);
              Cookies.set('auth_user', response.data.data.firebase_uid);
              await updateUserActiveStatus(response.data.data.firebase_uid, true);
              setRoleId(response.data.data.role_id);
              setPermission(response.data.permissions);

              return true;
          }else{
            helper.Toast().fire({ icon: 'error', title: response.data.message });
            return false
          }
          
        } catch (error) {
          console.log(error);
        } 
    };

    const logout = async() => {
        const lastActiveToken = Cookies.get('token');
        // update user status in firestore
        const auth_user = Cookies.get('auth_user');
        await updateUserActiveStatus(auth_user, false);
        Cookies.remove('token');
        Cookies.remove('auth_user');
        // logout from firebase
        try {
          signOut(auth);
        } catch { }

        setTimeout(() => {
          tabLogoutChannel.postMessage(lastActiveToken);
          navigate('/login');
        }, 500);
    };

    const updateUserActiveStatus = (userId, status) => {
      return new Promise((resolve) => {
        const result = updateDoc(doc(db, "users", userId), {
                          status: status
                        }).catch (err => {
                          
                        });
        resolve(result);
      });
    }
    
    const value = useMemo(
        () => ({
            login,
            logout,
            roleId,
            permission
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [roleId, permission]
    );

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
};

export const useAuth = () => {
    return useContext(UserContext)
};