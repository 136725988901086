import React,{useState, useEffect } from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import { ResponsiveBump } from '@nivo/bump';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { Link } from 'react-router-dom';

const Home = () => {
    
    const token = Cookies.get('token');
    const [OverallData, setOverallData] = useState({});
    const [Flag, setFlag] = useState(0); 

    // const getGraphData = () => {
    //     if(Flag === 1 && OverallData.salesRecord.length >= 1){
    //         return [{
    //                     "id": "Earnings",
    //                     "data": OverallData.salesRecord
    //                 }];
    //     }else{
    //         return [
    //                 {
    //                     "id": "Earnings",
    //                     "data": [
    //                         {
    //                             "x": 2022,
    //                             "y": 0
    //                         },
    //                         {
    //                             "x": 2023,
    //                             "y": 0
    //                         },
    //                         {
    //                             "x": 2024,
    //                             "y": 0
    //                         }
    //                     ]
    //                 }
    //             ];
    //     }
    // } 

    const getEarningGraphData = () => {
        if(Flag === 1 && OverallData.salesRecord.length >= 1){
            return (OverallData.salesRecord);
        } else {
            return ([{earning: 0, year: new Date().getFullYear()}]);
        }
    }

    // const [graph, setGraph] = useState(getGraphData())
    const [earningGraph, setEarningGraph] = useState([{earning: 0, year: new Date().getFullYear()}])

    const getPieChartData = () => {
        return [
                    {
                    "id": "Total Cleaners",
                    "label": "Total Cleaners",
                    "value": Flag === 1 ? OverallData.cRatio : 0,
                    "color": "hsl(122, 70%, 50%)"
                    },
                    {
                    "id": "Total Hosts",
                    "label": "Total Hosts",
                    "value": Flag === 1 ? OverallData.hRatio : 0,
                    "color": "hsl(336, 70%, 50%)"
                    }
                ];
    } 

    const [PieChart, setPieChart] = useState(getPieChartData())

    const getDashboardRecord = () => {
        axios.post(process.env.REACT_APP_BACKENDURL+'dashboard/index', 
        {},
        {
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        }
        )
        .then(function (response) {
            if(response.data.status === 1){
                setOverallData({...response.data.data});
                setFlag(1);
            }else{
                setOverallData({});
                setFlag(0);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    } 

    useEffect(() => {
        getDashboardRecord();
    }, []);

    useEffect(() => {
        setPieChart(getPieChartData())
        // setGraph(getGraphData());
        setEarningGraph(getEarningGraphData());
    }, [OverallData, Flag]);
  return (
    

            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Dashboard</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#javascript">Dashboards</a></li>
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="h-100">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row inner-row-d">
                                            <div className="col-xl-6 col-md-6">
                                            
                                                <div className="card card-animate">
                                                    <div className="card-body">
                                                        <Link to="/cleaner-list">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1 overflow-hidden align-items-center d-flex gap-2">
                                                                    <i className="ri-account-pin-box-line fs-20"></i>
                                                                    <span
                                                                        className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                                        Total Cleaners</span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-end justify-content-between mt-4">
                                                                <div>
                                                                    <h4 className="fs-22 fw-semibold ff-secondary"><span
                                                                            className="counter-value" data-target={Flag === 1 ? OverallData.totalCleaner : 0 }>{Flag === 1 ? OverallData.totalCleaner : 0 }</span>
                                                                    </h4>
                                                                    <p className="mb-0 p-b-color raleway-font">Last 30 days: {Flag === 1 ? OverallData.totalCleanersLastMonth : 0 }</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-md-6">
                                                <div className="card card-animate">
                                                    <div className="card-body">
                                                        <Link to="/host-list">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1 overflow-hidden align-items-center d-flex gap-2">
                                                                    <i className="ri-account-pin-box-line fs-20"></i>
                                                                    <span
                                                                        className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                                        Total Hosts</span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-end justify-content-between mt-4">
                                                                <div>
                                                                    <h4 className="fs-22 fw-semibold ff-secondary"><span
                                                                            className="counter-value" data-target={Flag === 1 ? OverallData.totalHosts : 0 }>{Flag === 1 ? OverallData.totalHosts : 0 }</span></h4>
                                                                    <p className="mb-0 p-b-color raleway-font">Last 30 days: {Flag === 1 ? OverallData.totalHostsLastMonth : 0 }</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-md-6">
                                            
                                                <div className="card card-animate">
                                                    <div className="card-body">
                                                        <Link to="/property-list">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1 overflow-hidden align-items-center d-flex gap-2">
                                                                    <i className="ri-server-line fs-20"></i>
                                                                    <span
                                                                        className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                                        Total properties</span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-end justify-content-between mt-4">
                                                                <div>
                                                                    <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span
                                                                            className="counter-value" data-target={Flag === 1 ? OverallData.totalProperties : 0 }>{Flag === 1 ? OverallData.totalProperties : 0 }</span>
                                                                    </h4>
                                                                    <p className="mb-0 p-b-color raleway-font">Last 30 days: {Flag === 1 ? OverallData.totalPropertiesLastMonth : 0 }</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-md-6">
                                            
                                                <div className="card card-animate">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1 overflow-hidden align-items-center d-flex gap-2">
                                                                <i className="ri-exchange-dollar-line fs-20"></i>
                                                                <span
                                                                    className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                                    Value of assignments</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                                            <div>
                                                                <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span
                                                                        className="counter-value" data-target={Flag === 1 ? OverallData.totalValueAssignments : 0 }>${Flag === 1 ? OverallData.totalValueAssignments : 0 }</span>
                                                                </h4>
                                                                <p className="mb-0 p-b-color raleway-font">Last 30 days: ${Flag === 1 ? OverallData.totalValueAssignmentsLastMonth : 0 }</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="card card-animate">
                                                <div className="card-body" style={{'height': '305px'}}>
                                                    <h3>Total Hosts/Cleaners {Flag === 1 ? OverallData.totalCleaner+OverallData.totalHosts : 0}</h3>
                                                    {(Flag === 1 && OverallData.totalCleaner+OverallData.totalHosts > 0) ? 
                                                        <ResponsivePie
                                                            data={PieChart}
                                                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                                            innerRadius={0.2}
                                                            padAngle={0.7}
                                                            cornerRadius={3}
                                                            activeOuterRadiusOffset={8}
                                                            borderWidth={1}
                                                            borderColor={{
                                                                from: 'color',
                                                                modifiers: [
                                                                    [
                                                                        'darker',
                                                                        0.2
                                                                    ]
                                                                ]
                                                            }}
                                                            arcLinkLabelsSkipAngle={10}
                                                            arcLinkLabelsTextColor="#333333"
                                                            arcLinkLabelsThickness={2}
                                                            arcLinkLabelsColor={{ from: 'color' }}
                                                            arcLabelsSkipAngle={10}
                                                            arcLabelsTextColor={{
                                                                from: 'color',
                                                                modifiers: [
                                                                    [
                                                                        'darker',
                                                                        2
                                                                    ]
                                                                ]
                                                            }}
                                                            defs={[
                                                                {
                                                                    id: 'dots',
                                                                    type: 'patternDots',
                                                                    background: 'inherit',
                                                                    color: 'rgba(255, 255, 255, 0.3)',
                                                                    size: 4,
                                                                    padding: 1,
                                                                    stagger: true
                                                                },
                                                                {
                                                                    id: 'lines',
                                                                    type: 'patternLines',
                                                                    background: 'inherit',
                                                                    color: 'rgba(255, 255, 255, 0.3)',
                                                                    rotation: -45,
                                                                    lineWidth: 6,
                                                                    spacing: 10
                                                                }
                                                            ]}
                                                            fill={[
                                                                {
                                                                    match: {
                                                                        id: 'Total Hosts'
                                                                    },
                                                                    id: 'lines'
                                                                },
                                                                {
                                                                    match: {
                                                                        id: 'Total Cleaners'
                                                                    },
                                                                    id: 'lines'
                                                                }
                                                            ]}
                                                            legends={[
                                                                {
                                                                    anchor: 'bottom',
                                                                    direction: 'row',
                                                                    justify: false,
                                                                    translateX: 0,
                                                                    translateY: 56,
                                                                    itemsSpacing: 0,
                                                                    itemWidth: 100,
                                                                    itemHeight: 18,
                                                                    itemTextColor: '#999',
                                                                    itemDirection: 'left-to-right',
                                                                    itemOpacity: 1,
                                                                    symbolSize: 18,
                                                                    symbolShape: 'circle',
                                                                    effects: [
                                                                        {
                                                                            on: 'hover',
                                                                            style: {
                                                                                itemTextColor: '#000'
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]}
                                                        />
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-animate">
                                            <div className="card-body" style={{'height': '100vh'}}>
                                                <h3>Earnings</h3>
                                                <ResponsiveBump
                                                        data={graph}
                                                        colors={{ scheme: 'spectral' }}
                                                        lineWidth={3}
                                                        activeLineWidth={6}
                                                        inactiveLineWidth={3}
                                                        inactiveOpacity={0.15}
                                                        pointSize={10}
                                                        activePointSize={16}
                                                        inactivePointSize={0}
                                                        pointColor={{ theme: 'background' }}
                                                        pointBorderWidth={3}
                                                        activePointBorderWidth={3}
                                                        pointBorderColor={{ from: 'serie.color' }}
                                                        axisTop={{
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            legend: '',
                                                            legendPosition: 'middle',
                                                            legendOffset: -36
                                                        }}
                                                        axisBottom={{
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            legend: '',
                                                            legendPosition: 'middle',
                                                            legendOffset: 32
                                                        }}
                                                        axisLeft={{
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            legend: 'Amount($)',
                                                            legendPosition: 'middle',
                                                            legendOffset: -40
                                                        }}
                                                        margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
                                                        axisRight={null}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-animate">
                                            {/* <div className="card-body" style={{'height': '60vh', 'width': '150vh'}}> */}
                                            <div className="card-body" style={{'height': '60vh', 'width': '180vh', 'maxHeight': '400px', 'maxWidth': '1050px'}}>
                                                <h3>Earnings</h3>
                                                <ResponsiveBar
                                                    data={earningGraph}
                                                    keys={[
                                                        'earning'
                                                    ]}
                                                    indexBy="year"
                                                    margin={{ top: 50, right: 130, bottom: 60, left: 60 }}
                                                    padding={0.3}
                                                    valueScale={{ type: 'linear' }}
                                                    indexScale={{ type: 'band', round: true }}
                                                    colors={{ scheme: 'nivo' }}
                                                    borderColor={{
                                                        from: 'color',
                                                        modifiers: [
                                                            [
                                                                'darker',
                                                                1.6
                                                            ]
                                                        ]
                                                    }}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: 'Years',
                                                        legendPosition: 'middle',
                                                        legendOffset: 32,
                                                        truncateTickAt: 0
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: 'Amount (in USD)',
                                                        legendPosition: 'middle',
                                                        legendOffset: -40,
                                                        truncateTickAt: 0,
                                                        format: (e) => (Math.floor(e) === e ? `$${e}` : "")
                                                    }}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor="white"
                                                    legends={[
                                                        {
                                                            dataFrom: 'keys',
                                                            anchor: 'bottom-right',
                                                            direction: 'column',
                                                            justify: false,
                                                            translateX: 120,
                                                            translateY: 0,
                                                            itemsSpacing: 2,
                                                            itemWidth: 100,
                                                            itemHeight: 20,
                                                            itemDirection: 'left-to-right',
                                                            itemOpacity: 0.85,
                                                            symbolSize: 20,
                                                            effects: [
                                                                {
                                                                    on: 'hover',
                                                                    style: {
                                                                        itemOpacity: 1
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        
  )
}

export default Home;
