import { useEffect, useState } from "react";
import { db } from '../../fireabse';
import Cookies from 'js-cookie';
import {
    collection,
    onSnapshot,
    orderBy,
    query,
    where,
    or,
    and
} from "firebase/firestore";

const UnreadChatCount = (props) => {
    useEffect(() => {
        const userId = Cookies.get('auth_user');
        const snapshotQuery = query(collection(db, "chats"), or( and (
                where("receiverId", "==", userId),
                where("isRead", "==", false)
            )),orderBy("createdTimestamp"));

        onSnapshot(snapshotQuery, (snapshot) => {
            props.setUnreadChatCount(snapshot.docs.length);
        },
        (error) => {

        });
    }, []);
};

export default UnreadChatCount;