import React,{useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import helper from '../../helper/index.js';
import WithPermission from '../../components/WithPermission';

const EditSubuser = () => {
    const navigate = useNavigate();
    const fullurl =  window.location.href;
    const segment = fullurl.split("/").pop(); 

    // Fetch Host Details
    const fetchSubuserDetails = async () => {

        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'user/subuser-detail', {
            id: segment.trim(),
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        });
       
        if(data.status === 1){
            setFirstName(data.data.first_name);
            setLastName(data.data.last_name);
            setEmailAddress(data.data.email);
            setPhone(data.data.phone_number);
            let statusData =  0;
            statusData = (typeof data.data.phone_number !== "undefined") ?  statusData = data.data.status : 0;
            setStatus(statusData);
        }else{
            navigate('/host-list');
        }
    };

    useEffect(() => {
        initPermission();
        fetchSubuserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update Host Details
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [EmailAddress, setEmailAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [status, setStatus] = useState(0);

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");



    // Errors
    const [errorFirstName, setFNErrorMessage] = useState("");
    const [errorLastName, setLNErrorMessage] = useState("");
    const [errorEmail, setEErrorMessage] = useState("");
    const [errorPhone, setPErrorMessage] = useState("");

    const [errorNewPassword, setNewPassMessage] = useState("");
    const [errorConfirmPass, setConfPassMessage] = useState("");

    const [type, setType] = useState('password');
    const [confirmType, setConfirmType] = useState('password');

    const [permissions, setPermissions] = useState([]);

    const collectData = async (event) => {
        event.preventDefault();

        setFNErrorMessage("");
        setLNErrorMessage("");
        setEErrorMessage("");
        setPErrorMessage("");


        let error = 0;
        if(firstName.trim().length === 0) {
            setFNErrorMessage('First Name field is required.');
            error = 1;
        }
        if(lastName.trim().length === 0) {
            setLNErrorMessage('Last Name field is required.');
            error = 1;
        }
        if(EmailAddress.trim().length === 0) {
            setEErrorMessage('Email Address field is required.');
            error = 1;
        }
        if(phone.length === 0) {
            setPErrorMessage('Phone Number field is required.');
            error = 1;
        }
        
        if(error === 1){
            return false;
        }

        axios.post(process.env.REACT_APP_BACKENDURL+'user/update-subuser', {
            first_name: firstName.trim(),
            last_name: lastName.trim(),
            phone_number: phone, 
            email: EmailAddress.trim(),
            id: segment.trim(),
            status: status
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                helper.Toast().fire({ icon: 'success', title: response.data.message }).then(() => {
                    navigate('/subuser-list');
                });
            }else{
                helper.Toast().fire({ icon: 'error', title: response.data.message });
            }
        })
        .catch(function (error) {
            helper.Toast().fire({ icon: 'error', title: "Something went wrong. Please try again." });
        });
    };

    const PasswordUpdate = async (event) => {
        event.preventDefault();

        setNewPassMessage("");
        setConfPassMessage("");
        let error = 0;
        if(newPassword.trim().length === 0) {
            setNewPassMessage('Password field is required.');
            error = 1;
        }

        if(confirmPassword.trim().length === 0) {
            setConfPassMessage('Confirm Password field is required.');
            error = 1;
        }else{
            if(newPassword.trim() !== confirmPassword.trim()){
                setConfPassMessage('Confirm Password is not matched with password field.');
                error = 1;
            }
        }

        if(error === 1){
            return 0;
        }

        axios.post(process.env.REACT_APP_BACKENDURL+'user/password-update', {
            new_password: newPassword.trim(),
            confirm_password: confirmPassword.trim(),
            id: segment.trim(),
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                helper.Toast().fire({ icon: 'success', title: response.data.message });
                setNewPassword("");
                setConfirmPassword("");
            }else{
                helper.Toast().fire({ icon: 'error', title: response.data.message });
            }
        })
        .catch(function (error) {
            helper.Toast().fire({ icon: 'error', title: "Something went wrong. Please try again." });
        });
    };

    const handleChange = (e) => {
        const result = e.target.value.replace(/\D/g, '');
        setPhone(result);
    };

    const permissionOnChange = (main, per_name) => {
        if(permissions[main][per_name].value === true){
            permissions[main][per_name].value = false;
        }else{
            permissions[main][per_name].value = true;
        }
        setPermissions({...permissions});
        
    };

    const permissionUpdate = async () => {
        axios.post(process.env.REACT_APP_BACKENDURL+'permission/permission-update', {
            permissions: permissions,
            user_id: segment.trim(),
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                helper.Toast().fire({ icon: 'success', title: response.data.message });
            }else{
                helper.Toast().fire({ icon: 'error', title: response.data.message });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    const initPermission = async () => {

        setPermissions({...helper.subuserPermissions});

        try {
            var perData = await axios.post(process.env.REACT_APP_BACKENDURL+'permission/get-permissions', {
                user_id: segment.trim(),
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if(perData.data.status === 1){

                var old_permission_data = helper.subuserPermissions;
                var new_permission_data = perData.data.data;

                Object.keys(new_permission_data).map((index,i) => {
                    Object.keys(new_permission_data[index]).map((per, ip) => {
                        if(typeof new_permission_data[index][per].value !== "undefined" && new_permission_data[index][per].value === true){
                           if(typeof old_permission_data[index] !== "undefined" && typeof old_permission_data[index][per] !== "undefined"){
                                old_permission_data[index][per].value = true;
                           }
                        }
                    });
                });

                setPermissions({...old_permission_data});
            }else{
                setPermissions({...helper.subuserPermissions});
            }

        } catch (error) {
            setPermissions({...helper.subuserPermissions});
        }
       
    }

    function capitalizeFirstLetter(str) {
        return str.trim().charAt(0).toUpperCase() + str.slice(1);
    }

    const handleToggle = (e, fieldName) => {
        e.preventDefault();
        if(fieldName === 'password') {
            if (type === 'password'){
                setType('text');
            } else {
                setType('password');
            }
        } else if(fieldName === 'confirm_password') {
            if (confirmType === 'password'){
                setConfirmType('text');
            } else {
                setConfirmType('password');
            }
        }
    }

    const asteriskMarkComponent = () => {
        return (<span style={{font: 'caption'}}>*</span>);
    }

    return (
        <div className="page-content">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Update Subuser Details</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/subuser-list">Subuser List</Link></li>
                                    <li className="breadcrumb-item active">Update Subuser Details</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card">
                            <div className="card-body">
                               
                                <ul className="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link active" data-bs-toggle="tab" to="#personal-information" role="tab" aria-selected="true">
                                            <span className="d-block d-sm-none"><i className="mdi mdi-home-variant"></i></span>
                                            <span className="d-none d-sm-block">Personal Information</span>
                                        </Link>
                                    </li>
                                    <WithPermission module="Subuser" name="change_password" message="no" multiple="false">
                                        <li className="nav-item" role="presentation">
                                            <Link className="nav-link" data-bs-toggle="tab" to="#change-password" role="tab" aria-selected="false" tabIndex="-1">
                                                <span className="d-block d-sm-none"><i className="mdi mdi-account"></i></span>
                                                <span className="d-none d-sm-block">Change Password</span>
                                            </Link>
                                        </li>
                                    </WithPermission>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link" data-bs-toggle="tab" to="#permissions" role="tab" aria-selected="false" tabIndex="-1">
                                            <span className="d-block d-sm-none"><i className="mdi mdi-account"></i></span>
                                            <span className="d-none d-sm-block">Permissions</span>
                                        </Link>
                                    </li>
                                    
                                </ul>
                                
                                <div className="tab-content text-muted">
                                    <div className="tab-pane active show" id="personal-information" role="tabpanel">
                                        <form onSubmit={collectData}>
                                            <div className="row gy-4">
                                                <div className="col-xxl-3 col-md-6">
                                                    <label className="form-label">First Name{asteriskMarkComponent()}</label>
                                                    <input type="text" 
                                                        className="form-control" 
                                                        value={firstName} 
                                                        onChange={(e) => setFirstName(e.target.value = capitalizeFirstLetter(e.target.value))}
                                                        maxLength={25} />
                                                    {errorFirstName && <div className="text-danger"> {errorFirstName} </div>}
                                                </div>
                                                
                                                <div className="col-xxl-3 col-md-6">
                                                    <label className="form-label">Last Name{asteriskMarkComponent()}</label>
                                                    <input type="text" 
                                                        className="form-control" 
                                                        value={lastName} 
                                                        onChange={(e) => setLastName(e.target.value = capitalizeFirstLetter(e.target.value))} 
                                                        maxLength={25} />

                                                    {errorLastName && <div className="text-danger"> {errorLastName} </div>}
                                                </div>

                                                <div className="col-xxl-3 col-md-6">
                                                    <label className="form-label">Email{asteriskMarkComponent()}</label>
                                                    {/* <input type="text" className="form-control" value={EmailAddress} onChange={(e) => setEmailAddress(e.target.value)} maxLength={50} readOnly style={{backgroundColor: '#cdccc747'}} /> */}
                                                    <input type="text" className="form-control" value={EmailAddress} maxLength={50} readOnly style={{backgroundColor: '#cdccc747'}} />
                                                    {errorEmail && <div className="text-danger"> {errorEmail} </div>}
                                                </div>
                                                
                                                <div className="col-xxl-3 col-md-6">
                                                    <label className="form-label">Phone Number{asteriskMarkComponent()}</label>
                                                    <input type="text" className="form-control" value={phone} onChange={handleChange} maxLength={12} />
                                                    {errorPhone && <div className="text-danger"> {errorPhone} </div>}
                                                </div>

                                                <div className="col-xxl-3 col-md-6">
                                                    <label className="form-label">Status{asteriskMarkComponent()}</label>
                                                    <select
                                                        value={status} 
                                                        onChange={e => setStatus(e.target.value)} 
                                                        className="form-control"
                                                    >
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </select>
                                                    
                                                </div>

                                            
                                                
                                                <div className="col-12">
                                                    <button className="btn btn-success" type="submit">Update Details</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <WithPermission module="Subuser" name="change_password" message="no" multiple="false">
                                        <div className="tab-pane" id="change-password" role="tabpanel">
                                            <form id="UpdatePassForm" onSubmit={PasswordUpdate}>
                                                <div className="row gy-4">
                                                    <div className="col-xxl-3 col-md-6">
                                                        <label className="form-label">New Password</label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <input type={type} 
                                                            className="form-control" 
                                                            value={newPassword} 
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                            maxLength={30} />
                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={(e) => handleToggle(e, 'password')} ><i className="ri-eye-fill align-middle"></i></button>
                                                        </div>
                                                        {errorNewPassword && <div className="text-danger"> {errorNewPassword} </div>}
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-md-6">
                                                        <label className="form-label">Confirm Password</label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <input type={confirmType} 
                                                            className="form-control" 
                                                            value={confirmPassword} 
                                                            onChange={(e) => setConfirmPassword(e.target.value)} 
                                                            maxLength={30} />
                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="confirm-password-addon" onClick={(e) => handleToggle(e, 'confirm_password')} ><i className="ri-eye-fill align-middle"></i></button>
                                                        </div>

                                                        {errorConfirmPass && <div className="text-danger"> {errorConfirmPass} </div>}
                                                    </div>

                                                    <div className="col-12">
                                                        <button className="btn btn-success" type="submit">Update Details</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </WithPermission>

                                    <div className="tab-pane" id="permissions" role="tabpanel">
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               
                                                {Object.keys(permissions).map((index,i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{index}</td>
                                                                
                                                            <td>
                                                                {Object.keys(permissions[index]).map((per, ip) => {
                                                                    return (
                                                                        <div className="form-check form-check-inline" key={ip}>
                                                                            <input 
                                                                                className="form-check-input" 
                                                                                type="checkbox" 
                                                                                id={`formCheck${i}${ip}`}
                                                                                checked={permissions[index][per].value }
                                                                                onChange={() => permissionOnChange(index, per)}
                                                                             />
                                                                            <label className="form-check-label ms-1" htmlFor={`formCheck${i}${ip}`}>{permissions[index][per].name}</label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </td>
                                                        </tr>
                                                        )                                       
                                                })}
                                            </tbody>

                                        </table>

                                        <div className="col-12">
                                            <button className="btn btn-success" onClick={permissionUpdate} type="button">Update Permissions</button>
                                        </div>

                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default EditSubuser;
