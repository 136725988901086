import React,{ useEffect } from 'react'
import { useWizard } from 'react-use-wizard';
import ProgressBar from '../../../components/common/ProgressBar'
import {useDropzone} from 'react-dropzone'
import helper from '../../../helper/index';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


const ImageUpload = (props) => {
  const { previousStep, nextStep } = useWizard();

  useEffect(() => {
      validateImageCount(props.files.length + props.uploadedFiles.length)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.files, props.uploadedFiles])
  
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpeg', '.jpg'],
    },
    maxFiles: 15,
    onDrop: (acceptedFiles, fileRejections) => {
      // Manage rejected files
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            console.log(`Error: ${err.message}`);
          }

          if (err.code === "file-invalid-type") {
            console.log(`Error: ${err.message}`);
          }
        });
      });
      // rejected files logic ends

      if((props.files.length + props.uploadedFiles.length + acceptedFiles.length) > 15){
        helper.Toast().fire({ icon: 'warning', title: "You can upload between 1 to 15 images." });
        return;
      }

      // props.setSelectedFiles(acceptedFiles);
      var newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      props.setFiles(prevState => [...prevState, ...newFiles]);
      props.setSelectedFiles(prevState => [...prevState, ...acceptedFiles]);

      // validateImageCount(props.files.length + props.uploadedFiles.length) 
    }
  });

  const validateImageCount = (count) => {
    count >= 1 ? props.setStep4Validation(true) : props.setStep4Validation(false);
    return;
  }

  

  return (
    <>
    <ProgressBar stepNames={props.stepNames}/>
    <div className="card">
        <div className="card-body">
          <div className="live-preview">
            <section className="container">
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                <p className='note'>(**Upload Image between 1-15**)</p>
              </div>
              <aside style={thumbsContainer}>
                  {props.files.length > 0 &&
                    props.files.map((image, index) => (
                      <div style={thumb} key={image.name+index}>
                        <div style={thumbInner} >
                          <span style={thumbInner} 
                          className="image-wrap"
                          onClick={(e) => props.setFiles(crt => {
                              return crt.filter((_el, ind) => (ind !== index))
                          })}
                          >
                            <img src={`${URL.createObjectURL(image)}`} style={img} key={index} alt={image.name} />
                          </span>
                        </div>
                      </div>
                    ))}
                    {/* Render already uploaded images */}
                    {(props.pageName === "edit property" && props.uploadedFiles && props.uploadedFiles.length > 0) ? 
                    props.uploadedFiles.map((img, i) => (
                      <div style={thumb} key={img.filename}>
                        <div 
                          style={thumbInner} 
                          className="image-wrap"
                          onClick={(e) => props.setUploadedFiles(crt => {
                              return crt.filter((_el, index) => (index !== i))
                          })}
                          >
                            {/* <span className="close-btn">&times;</span> */}
                            <img src={process.env.REACT_APP_BACKENDURL+'images/'+img.filename} style={img} key={i} alt={img.filename} />
                        </div>
                      </div>
                      ))
                    : <></>}
              </aside>

              <div className="col-12">
                  <button className="btn btn-success me-2" onClick={() => previousStep()}>Previous</button>
                  <button className="btn btn-success me-2" 
                          disabled={ !props.step4Validation ? true : false} 
                          onClick={() => nextStep()}>Next</button>
              </div>
            </section>
          </div>
        </div>
    </div>
    </>
  )
}

export default ImageUpload;