import React from 'react'
import { Link, useParams } from 'react-router-dom';
import CleanerInsightBreadcrump from './component/breadcrump';

const Insight = (props) => {
    let params = useParams()
    let cleanerId = params.cleanerId
  return (
    <div className="page-content">
        <div className="container-fluid">

        <CleanerInsightBreadcrump name="Insight" isInsight={false} cleanerId={cleanerId} />
            
              <div className="row">
                <div className="col-xxl-12 col-md-12">
                    <div className="">
                        <div className="card-header align-items-center d-flex justify-content-between">
                            <div className="d-flex align-items-start flex-column ">
                                <h4 className="card-title mb-0 flex-grow-1 ">Insights</h4>
                                
                            </div>

                        </div>

                        <div className="card-body">
                            <div className="row setting-list mt-4">
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to={"/cleaner/insight/perfomace/"+cleanerId} className="text-prime">
                                        <div className="card p-lg-3 assigment-card mb-0 card-animate">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="ri-bar-chart-grouped-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1"> Performance </h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to={"/cleaner/insight/customer/"+cleanerId} className="text-prime">
                                        <div className="card p-lg-3 assigment-card mb-0 card-animate">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="ri-user-2-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1"> Customers </h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to={"/cleaner/insight/project/"+cleanerId} className="text-prime">
                                        <div className="card p-lg-3 assigment-card mb-0 card-animate">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="ri-calendar-line"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1"> Assignment </h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to={"/cleaner/insight/earning/"+cleanerId} className="text-prime">
                                        <div className="card p-lg-3 assigment-card mb-0 card-animate">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="ri-wallet-2-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1"> Payouts </h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to={'/cleaner/insight/reviews/'+cleanerId} className="text-prime">
                                        <div className="card p-lg-3 assigment-card mb-0 card-animate">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="ri-edit-box-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1"> Reviews </h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>    
  )
}

export default Insight;
