import React , { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import helper from '../../helper/index';
import ReactPaginate from 'react-paginate';
import WithPermission from '../../components/WithPermission';

let limit = 10;
const Home = (props) => {
    const fullurl =  window.location.href;
    const segment = fullurl.split("/").pop(); 

    const [data, setData] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const [properties, setProperties] = useState([]);

    const getProperties = (datas) => {
        let pageNo = (datas) ? datas.selected : 0;
        axios.get(process.env.REACT_APP_BACKENDURL+'property/get-properties',{
            params: {
                page: pageNo,
                limit: 100
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                setProperties(response.data.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    
    const [filterRecords, setFilterRecords] = useState({
        propertyId: "",
        sortBy: "asc",
        searchText: ""
    });

    useEffect(() => {
        getData([]);
    }, [filterRecords]);

    useEffect(() => {
        getProperties()
    }, [])

    const getData = async (datas) => {
        props.setLoading(true);
        let pageNo = (datas) ? datas.selected : 0;
        await axios.get(process.env.REACT_APP_BACKENDURL+'assignment/list',{
            params: {
                page: pageNo,
                limit: limit,
                hostId: segment,
                filterRecords: filterRecords
            }
        })
        .then(function (response) {
            props.setLoading(false);
            if(response.data.status === 1){
                setPageCount(response.data.total_pages);
                setData(response.data.data);
            }
        })
        .catch(function (error) {
            props.setLoading(false);
            console.log(error);
        });
    }

    const onDelete = (id) => {
        Swal.fire({
            text: 'Are you sure want to delete this assignment?',
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Delete',
            denyButtonText: `Close`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_BACKENDURL}assignment/delete/${id}`)
                .then(function (response) {
                    if(response.data.status === 1){
                        helper.Toast().fire({ icon: 'success', title: response.data.message });
                        getData();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else if (result.isDenied) {
                
            }
        })
        
    }

    const handleFilters = ( keyValue, value) => {
        filterRecords[keyValue] = value
        setFilterRecords({ ...filterRecords })
    }

    return (
        <>
        <div className="page-content">
        <div className="container-fluid">


            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Assignment Listing</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item"><Link to="/host-list">Host List</Link></li>
                                <li className="breadcrumb-item"><Link to={"/host-edit/"+segment}>Host Detail</Link></li>
                                <li className="breadcrumb-item active">Assignment Listing</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>


            <div className="row">
            <div className="col-xl-12 text-end mb-3">
                    <div className="row g-3">

                        <div className="col-md-3">
                            <input type="text" className="form-control" 
                                name="searchText"
                                onChange={(e) => handleFilters('searchText', e.target.value)}
                                value={filterRecords.searchText}
                                placeholder="Search for something" />
                        </div>

                        <div className="col-md-3">
                            <div className="input-light">
                                <select className="form-control" 
                                    name="propertyId" 
                                    onChange={(e) => handleFilters('propertyId', e.target.value)}>
                                    <option value="">Filter by property</option>
                                    {properties.map((item) => {
                                        return(
                                            <option key={item._id} value={item._id}>{item.propertyName}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="input-light">
                                <select className="form-control" name="sortBy" 
                                    value={filterRecords.sortBy}
                                    onChange={(e) => handleFilters('sortBy', e.target.value)}>
                                    <option value="">Sort by</option>
                                    <option value="asc" >Ascending</option>
                                    <option value="desc" >Descending </option>
                                </select>
                            </div>
                        </div>
                        <WithPermission module='Assignments' name='add_assignments' message='no' multiple="false">
                        <div className="col-md-3 text-end mb-3">
                            <Link className='btn btn-success text-end' to={"/create-assignment/"+segment}>Add Assignment</Link>
                        </div>
                        </WithPermission>
                    </div>
                </div>
                <div className="col-xl-12">
                            
                                {data.length > 0 ?
                                 <div className="card">
                                    <div className="card-body">
                                        <div className="live-preview">
                                            <div className="table-responsive mb-3">
                                                <table className="table table-striped table-nowrap align-middle mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Title</th>
                                                            <th scope="col">Property Name</th>
                                                            <th scope="col">Date</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((item, i) => { 
                                                            return ( 
                                                                <tr key={i}>
                                                                    <td>{item.title}</td>
                                                                    <td>{item.propertyDetail[0].propertyName}</td>
                                                                    <td>{new Date(item.date.toString()).toDateString()}</td>
                                                                    <td>
                                                                        {
                                                                            (item.status === 1 || item.status === 2) && <span className="badge bg-warning p-2 text-capitalize">Pending</span>
                                                                        }
                                                                        {
                                                                            (item.status === 3) && <span className="badge bg-secondary p-2 text-capitalize">Running</span>
                                                                        }
                                                                        {
                                                                            (item.status === 4) && <span className="badge completed p-2 text-capitalize">Completed</span>
                                                                        }
                                                                        {
                                                                            (item.status === 5) && <span className="badge bg-danger p-2 text-capitalize">Cancelled</span>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex gap-2">
                                                                            <WithPermission module='Assignments' name='edit_assignments' message='no' multiple="false">
                                                                            <div className="edit">
                                                                                <Link to={`/assignment-edit/${segment}/${item._id}`} className="btn btn-sm btn-success edit-item-btn" >View Details</Link>
                                                                            </div>
                                                                            </WithPermission>
                                                                            <WithPermission module='Assignments' name='delete_assignments' message='no' multiple="false">
                                                                            <div className="remove">
                                                                                <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => onDelete(item._id)}>Remove</button>
                                                                            </div>
                                                                            </WithPermission>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <ReactPaginate
                                                    previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                    nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                    breakLabel="..."
                                                    pageCount={PageCount}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={getData}
                                                    containerClassName={'pagination'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link'}
                                                    renderOnZeroPageCount={null}
                                                    activeClassName={'active'}
                                                />
                                        </div>
                                    </div>
                                </div>
                                    :
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="alert alert-danger" role="alert">No assignment yet.</div>
                                    </div>
                                </div>
                                }
                    
                </div>
            </div>
        </div>
    </div>
      
    </>
    )
}

export default Home;
