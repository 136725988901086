import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Cookies from 'js-cookie';
let limit = 10;

const FeedbackList = () => {
    const token = Cookies.get('token');
    const [data, setData] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const [titleText, setTitleText] = useState('');
    const [messageText, setMessageText] = useState('');
    const [hostList, setHostList] = useState([]);
    const [cleanerList, setCleanerList] = useState([]);
    const [filterRecords, setFilterRecords] = useState({
                                                        hostId: "",
                                                        cleanerId: "",
                                                        sortBy: "desc",
                                                        searchText: "",
                                                        roleType: "",
                                                        submissionType: ""
                                                    });

    useEffect(() => {
        getHostList();
        getCleanerList();
        updateNotificationReadStatus();
    }, []);

    useEffect(() => {
        getData([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterRecords]);

    const getData = (datas) => {
        let pageNo = (datas) ? datas.selected : 0;
        axios.get(process.env.REACT_APP_BACKENDURL + 'feedback/get-list', {
            params: {
                page: pageNo,
                limit: limit,
                sortBy: 'desc',
                filterRecords: filterRecords
            },
            headers: { 'Content-Type': 'application/json' }
        }).then(function (response) {
                if (response.data.status === 1) {
                    setPageCount(response.data.total_pages);
                    setData(response.data.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const renderContect = (title, message) => {
        setTitleText(title);
        setMessageText(message);
    }

    const getHostList = () => {
        axios.get(process.env.REACT_APP_BACKENDURL+'user/get-all-hosts')
        .then(function (response) {
            if(response.data.status === 1){
                setHostList(response.data.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getCleanerList = () => {
        axios.get(process.env.REACT_APP_BACKENDURL+'user/get-all-cleaners')
        .then(function (response) {
            if(response.data.status === 1){
                setCleanerList(response.data.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleFilters = ( keyValue, value) => {
        filterRecords[keyValue] = value;
        setFilterRecords({ ...filterRecords })
    }

    const updateNotificationReadStatus = () => {
        axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-notification-status',
        {
            role_id: 1,
            type: ['Help', 'feedback'],
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Feedback</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Feedback</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 text-end mb-3">
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <form>
                                <div className="row g-3">

                                    {/* <div className="col-md-2">
                                        <input type="text" className="form-control" 
                                            name="searchText"
                                            onChange={(e) => handleFilters('searchText', e.target.value)}
                                            value={filterRecords.searchText}
                                            placeholder="Search for something" />
                                    </div> */}

                                    <div className="col-md-2">
                                        <div className="input-light">
                                            <select className="form-control" 
                                                name="roleType" 
                                                value={filterRecords.roleType}
                                                onChange={(e) => handleFilters('roleType', e.target.value)}>
                                                <option value="">Filter by role</option>
                                                <option value='2'> Host </option>
                                                <option value='3'> Cleaner </option>
                                            </select>
                                        </div>
                                    </div>

                                    {(filterRecords.roleType === "2") && 
                                        <div className="col-md-2">
                                            <div className="input-light">
                                                <select className="form-control" 
                                                    name="hostId" 
                                                    value={filterRecords.hostId}
                                                    onChange={(e) => handleFilters('hostId', e.target.value)}>
                                                    <option value="">Filter by host name</option>
                                                    {hostList.map((item, index) => {
                                                        return(
                                                            <option key={index} value={item._id}>{item.first_name} {item.last_name}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    {(filterRecords.roleType === "3") && 
                                        <div className="col-md-2">
                                            <div className="input-light">
                                                <select className="form-control" 
                                                    name="cleanerId" 
                                                    value={filterRecords.cleanerId}
                                                    onChange={(e) => handleFilters('cleanerId', e.target.value)}>
                                                    <option value="">Filter by cleaner name</option>
                                                    {cleanerList.map((item, index) => {
                                                        return(
                                                            <option key={index} value={item._id}>{item.first_name} {item.last_name}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    <div className="col-md-2">
                                        <div className="input-light">
                                            <select className="form-control" 
                                                name="submissionType" 
                                                value={filterRecords.submissionType}
                                                onChange={(e) => handleFilters('submissionType', e.target.value)}>
                                                <option value="">Filter by type</option>
                                                <option value='1'> Feedback </option>
                                                <option value='2'> Query </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="input-light">
                                            <select className="form-control" name="sortBy" 
                                                value={filterRecords.sortBy}
                                                onChange={(e) => handleFilters('sortBy', e.target.value)}>
                                                <option value="">Sort by date</option>
                                                <option value="asc" >Ascending</option>
                                                <option value="desc" >Descending </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="live-preview">
                                    {(data.length > 0) ?
                                        <>
                                            <div className="table-responsive mb-3">
                                                <table className="table table-striped table-nowrap align-middle mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Title</th>
                                                            <th scope="col">Message</th>
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Username</th>
                                                            <th scope="col">Role</th>
                                                            <th scope="col">Date</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>
                                                                        {(item.title.length > 30) ?
                                                                           
                                                                           item.title.substring(0, 30)
                                                                           
                                                                        : 
                                                                            item.title
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {(item.message.length > 30) ?
                                                                           
                                                                           item.message.substring(0, 30)
                                                                           
                                                                        : 
                                                                            item.message
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {(item.type === 1) && "Feedback"}
                                                                        {(item.type === 2) && "Query"}
                                                                    </td>
                                                                    <td> {item.username} </td>
                                                                    <td> {(item.roleId === 2 ? "Host" : "Cleaner")} </td>
                                                                    <td>
                                                                    {moment(item.createdAt).format('MMM Do YYYY, hh:mm A')}
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex gap-2">
                                                                            <div className="edit">
                                                                                <button type="button" onClick={(e) => {renderContect(item.title, item.message)}} className="btn btn-sm btn-success edit-item-btn"  data-bs-toggle="modal" data-bs-target="#viewContact" >View Details</button>
                                                                            </div>                                                                        
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <ReactPaginate
                                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                breakLabel="..."
                                                pageCount={PageCount}
                                                pageRangeDisplayed={3}
                                                onPageChange={getData}
                                                containerClassName={'pagination'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                renderOnZeroPageCount={null}
                                                activeClassName={'active'}
                                            />
                                        </>
                                        :
                                        <>
                                            <h5>No feedback submission found</h5>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade zoomIn" id="viewContact" tabIndex="-1" aria-labelledby="viewContactLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content border-0">
                            <div className="modal-header p-3 bg-soft-info">
                                <h5 className="modal-title" id="viewContactLabel">View Detail</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    id="close-modal"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row g-3">  
                                    <div className="col-lg-12">
                                        <div>
                                            <label className="form-label">Subject</label>
                                            <p>{titleText}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div>
                                            <label className="form-label">Message</label>
                                            <p>{messageText}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackList;
