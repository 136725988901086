import React, {  useEffect, useState } from 'react'
import Footer from "../footer";
// import Navbar from "../navbar";
import Sidebar from "../sidebar";
import Header from "../header";
import { Outlet, useLocation, useBeforeUnload  } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import BeatLoader from "react-spinners/ClipLoader";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { BroadcastChannel } from 'broadcast-channel';

const tabLogoutChannel = new BroadcastChannel('handleAdminTabsLogout');

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#8f368dc7",
  position: "fixed",
  top: "40%",
  left: "50%",
  zIndex: "99999",
  transform: "translate(-50%, -40%)",
  border: "3px solid #8f368dc7"
};

const loderWrap  = {
  position: "absolute",
  width: "100%",
  background: "rgba(0,0,0,0.5)",
  height: "100%",
  zIndex: "99999999"
};

const Layout = (props) => {
  const handleTabLogout = (token) => {
    if(Cookies.get('token') === undefined || Cookies.get('token') === token) {
      navigate('/login');
    }
  }
  
  tabLogoutChannel.onmessage = msg => handleTabLogout(msg);
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = React.useState(null);

  // eslint-disable-next-line no-unused-vars
  let [color, setColor] = useState("#8f368dc7");

  // save it off before users navigate away
  useBeforeUnload(
    React.useCallback(() => {
      localStorage.stuff = state;
    }, [state])
  );

  const location = useLocation();
  useEffect(() => {
    props.setProgress(100)
  }, [location]);

  return (
    <div id="layout-wrapper">
      {props.loading && 
      <div style={loderWrap}>
       <BeatLoader
          color={color}
          loading={props.loading}
          cssOverride={override}
          size={125}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      }
       <LoadingBar
        color='#8f368d'
        progress={props.progress}
        waitingTime={300}
        height={3}
        onLoaderFinished={() => props.setProgress(0)}
      />
    {/* <Navbar /> */}
      <Header />
      <Sidebar />
      <div className="main-content">
        <Outlet />
       </div>
      <Footer />
    </div>
      
  );
};

export default Layout;