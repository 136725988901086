import React,{useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import helper from '../../helper/index.js';
import Cookies from 'js-cookie';
import { decodeToken  } from "react-jwt";
import { updateUserName } from '../../helper/fs-common.js';

const AdminProfile = () => {

    const navigate = useNavigate();
    const token = Cookies.get('token');
    // get user data from token in cookie
    const decoded = decodeToken(token);

    // Fetch Host Details
    const getProfileDetails = async () => {

        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'user/admin-detail', {
            id: decoded.user_id
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        });
       
        if(data.status === 1){
            setFirstName(data.data.first_name);
            setLastName(data.data.last_name);
            setEmailAddress(data.data.email);
            setPhone(data.data.phone_number);
        }else{
            navigate('/dashboard');
        }
    };

    useEffect(() => {
        getProfileDetails();
    }, []);

    // Update Host Details
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [EmailAddress, setEmailAddress] = useState("");
    const [phone, setPhone] = useState(""); 

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // Errors
    const [errorFirstName, setFNErrorMessage] = useState("");
    const [errorLastName, setLNErrorMessage] = useState("");
    const [errorEmail, setEErrorMessage] = useState("");
    const [errorPhone, setPErrorMessage] = useState("");

    const [errorNewPassword, setNewPassMessage] = useState("");
    const [errorConfirmPass, setConfPassMessage] = useState("");

    const [type, setType] = useState('password');
    const [confirmType, setConfirmType] = useState('password');

    const collectData = async (event) => {
        event.preventDefault();

        setFNErrorMessage("");
        setLNErrorMessage("");
        setEErrorMessage("");
        setPErrorMessage("");

        let error = 0;

        if(firstName.trim().length === 0) {
            return setFNErrorMessage('First Name field is required.');
            error = 1;
        }
        if(lastName.trim().length === 0) {
            return setLNErrorMessage('Last Name field is required.');
            error = 1;
        }
        if(EmailAddress.trim().length === 0) {
            setEErrorMessage('Email Address field is required.');
            error = 1;
        }
        if(phone.length === 0) {
            setPErrorMessage('Phone Number field is required.');
            error = 1;
        }

        if(error === 1){
            return false;
        }

        axios.post(process.env.REACT_APP_BACKENDURL+'user/update-admin', {
            first_name: firstName.trim(),
            last_name: lastName.trim(),
            phone_number: phone, 
            email: EmailAddress.trim(),
            id: decoded.user_id
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                const firestoreData = {
                    'name': firstName+' '+lastName,
                    'email': EmailAddress
                }
                updateUserName(firestoreData);
                helper.Toast().fire({ icon: 'success', title: response.data.message});
            }else{
                helper.Toast().fire({ icon: 'error', title: response.data.message });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    const PasswordUpdate = async (event) => {
        event.preventDefault();

        setNewPassMessage("");
        setConfPassMessage("");
        let error = 0;
        if(newPassword.trim().length === 0) {
            setNewPassMessage('Password field is required.');
            error = 1;
        }

        if(confirmPassword.trim().length === 0) {
            setConfPassMessage('Confirm Password field is required.');
            error = 1;
        }else{
            if(newPassword.trim() !== confirmPassword.trim()){
                setConfPassMessage('Confirm Password is not matched with password field.');
                error = 1;
            }
        }

        if(error === 1){
            return 0;
        }

        axios.post(process.env.REACT_APP_BACKENDURL+'user/admin-password-update', {
            new_password: newPassword.trim(),
            confirm_password: confirmPassword.trim(),
            id: decoded.user_id
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                helper.Toast().fire({ icon: 'success', title: response.data.message })
                setNewPassword("");
                setConfirmPassword("");
            }else{
                helper.Toast().fire({ icon: 'error', title: response.data.message });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    const handleChange = (e) => {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (e.target.value === '' || re.test(e.target.value)) {
            setPhone(e.target.value);
        }
    };

    const handleToggle = (e, fieldName) => {
        e.preventDefault();
        if(fieldName === 'password') {
            if (type === 'password'){
                setType('text');
            } else {
                setType('password');
            }
        } else if(fieldName === 'confirm_password') {
            if (confirmType === 'password'){
                setConfirmType('text');
            } else {
                setConfirmType('password');
            }
        }
    }

    return (
        <div className="page-content">
            <div className="container-fluid">


                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Profile Details</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Profile Details</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card">
                            <div className="card-body">
                               
                                <ul className="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link active" data-bs-toggle="tab" to="#personal-information" role="tab" aria-selected="true">
                                            <span className="d-block d-sm-none"><i className="mdi mdi-home-variant"></i></span>
                                            <span className="d-none d-sm-block">Personal Information</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link" data-bs-toggle="tab" to="#change-password" role="tab" aria-selected="false" tabIndex="-1">
                                            <span className="d-block d-sm-none"><i className="mdi mdi-account"></i></span>
                                            <span className="d-none d-sm-block">Change Password</span>
                                        </Link>
                                    </li>
                                    
                                </ul>
                                
                                <div className="tab-content text-muted">
                                    <div className="tab-pane active show" id="personal-information" role="tabpanel">
                                        <form onSubmit={collectData}>
                                            <div className="row gy-4">
                                                <div className="col-xxl-3 col-md-6">
                                                    <label className="form-label">First Name</label>
                                                    <input type="text" 
                                                        className="form-control" 
                                                        value={firstName} 
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                        maxLength={25} />
                                                    {errorFirstName && <div className="text-danger"> {errorFirstName} </div>}
                                                </div>
                                                
                                                <div className="col-xxl-3 col-md-6">
                                                    <label className="form-label">Last Name</label>
                                                    <input type="text" 
                                                        className="form-control" 
                                                        value={lastName} 
                                                        onChange={(e) => setLastName(e.target.value)} 
                                                        maxLength={25} />

                                                    {errorLastName && <div className="text-danger"> {errorLastName} </div>}
                                                </div>

                                                <div className="col-xxl-3 col-md-6">
                                                    <label className="form-label">Email address</label>
                                                    {/* <input type="text" className="form-control" value={EmailAddress} onChange={(e) => setEmailAddress(e.target.value)} maxLength={50} readOnly style={{backgroundColor: '#cdccc747'}}/> */}
                                                    <input type="text" className="form-control" value={EmailAddress} maxLength={50} readOnly style={{backgroundColor: '#cdccc747'}}/>
                                                    {errorEmail && <div className="text-danger"> {errorEmail} </div>}
                                                </div>
                                                
                                                <div className="col-xxl-3 col-md-6">
                                                    <label className="form-label">Phone Number</label>
                                                    <input type="text" className="form-control" value={phone} onChange={handleChange} maxLength={12}  />
                                                    {errorPhone && <div className="text-danger"> {errorPhone} </div>}
                                                </div>
                                                
                                                <div className="col-12">
                                                    <button className="btn btn-success" type="submit">Update Details</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane" id="change-password" role="tabpanel">
                                        <form id="UpdatePassForm" onSubmit={PasswordUpdate}>
                                            <div className="row gy-4">
                                                <div className="col-xxl-3 col-md-6">
                                                    <label className="form-label">New Password</label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <input type={type} 
                                                            className="form-control" 
                                                            value={newPassword} 
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                            maxLength={30} />
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={(e) => handleToggle(e, 'password')} ><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                    {errorNewPassword && <div className="text-danger"> {errorNewPassword} </div>}
                                                </div>
                                                
                                                <div className="col-xxl-3 col-md-6">
                                                    <label className="form-label">Confirm Password</label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <input type={confirmType} 
                                                            className="form-control" 
                                                            value={confirmPassword} 
                                                            onChange={(e) => setConfirmPassword(e.target.value)} 
                                                            maxLength={30} />
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="confirm-password-addon" onClick={(e) => handleToggle(e, 'confirm_password')} ><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>

                                                    {errorConfirmPass && <div className="text-danger"> {errorConfirmPass} </div>}
                                                </div>

                                                <div className="col-12">
                                                    <button className="btn btn-success" type="submit">Update Details</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AdminProfile;
