import React , { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import helper from '../../helper/index';
import WithPermission from '../../components/WithPermission';
let limit = 10;


const ClenerList = (props) => {

    const [data, setData] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const [filterRecords, setFilterRecords] = useState({
        sortBy: "desc",
        searchText: "",
        status: ""
    });

    useEffect(() => {
        getData([]);
    }, [filterRecords]);

    const getData = (datas) => {
        props.setLoading(true);
        let pageNo = (datas) ? datas.selected : 0;
        axios.get(process.env.REACT_APP_BACKENDURL+'user/get-cleaner',{
            params: {
                page: pageNo,
                limit: limit,
                filterRecords: filterRecords
            }
        })
        .then(function (response) {
            props.setLoading(false);
            if(response.data.status === 1){
                setPageCount(response.data.total_pages);
                setData(response.data.data);
            }
        })
        .catch(function (error) {
            props.setLoading(false);
            console.log(error);
        });
    }

    const onDelete = (id) => {
        Swal.fire({
            text: 'Are you sure you want to delete?',
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Delete',
            denyButtonText: `Close`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                props.setLoading(true);
                axios.delete(process.env.REACT_APP_BACKENDURL+`user/delete-cleaner/${id}`)
                .then(function (response) {
                    props.setLoading(false);
                    if(response.data.status === 1){
                        helper.Toast().fire({ icon: 'success', title: response.data.message });
                        getData();
                    } else {
                        helper.Toast().fire({ icon: 'error', title: response.data.message });
                        getData();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else if (result.isDenied) {
                
            }
        })
        
    }

    const handleFilters = ( keyValue, value) => {
        filterRecords[keyValue] = value
        setFilterRecords({ ...filterRecords })
    }

  return (
    <div className="page-content">
                <div className="container-fluid">

                    
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Cleaner Listing</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="#javascript">Home</a></li>
                                        <li className="breadcrumb-item active">Cleaner Listing</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    

                    <div className="row">

                        <div className="col-xl-12">

                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <input type="text" className="form-control" 
                                                name="searchText"
                                                onChange={(e) => handleFilters('searchText', e.target.value)}
                                                value={filterRecords.searchText}
                                                placeholder="Search by name, email and phone number" />
                                        </div>
                                        <div className="col-md-3">
                                            <select 
                                                className="form-select"
                                                value={filterRecords.status}
                                                onChange={(e) => handleFilters('status', e.target.value)}
                                            >
                                                <option value="">Select Status</option>
                                                <option value="1">Active</option>
                                                <option value="2">Inactive</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <select 
                                            className="form-select"
                                            value={filterRecords.sortBy}
                                            onChange={(e) => handleFilters('sortBy', e.target.value)}
                                            >
                                                <option value="">Sort By Date</option>
                                                <option value="desc">DESC</option>
                                                <option value="asc">ASC</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="text-end">
                                                <WithPermission module="Cleaner" name="add_cleaner" message="no" multiple="false" >
                                                    <Link to="/cleaner-add" className="btn btn-primary">Add New Cleaner</Link>
                                                </WithPermission>
                                            
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="live-preview">
                                        <div className="table-responsive mb-3">
                                            <table className="table table-striped table-nowrap align-middle mb-0">
                                                <thead>
                                                    <tr>
                                                        {/* <th scope="col">ID</th> */}
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Phone Number</th>
                                                        <th scope="col">Email Address</th>
                                                        {/* <th scope="col">Joining Date</th> */}
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Is Verify</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((item, i) => { 
                                                        return ( 
                                                            <tr key={i}>
                                                                {/* <td>{i + 1}</td> */}
                                                                <td>{item.first_name} {item.last_name}</td>
                                                                <td>{item.phone_number}</td>
                                                                <td>{item.email}</td>
                                                                <td>{(item.status === 1) ?  <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}</td>
                                                                <td>
                                                                    {
                                                                    (item.isApproved === 1) ?  
                                                                        <span className="badge bg-success">Verified</span> 
                                                                    : 
                                                                        (item.isApproved === 0) ?  
                                                                            <span className="badge bg-warning">Pending</span>
                                                                        :
                                                                        <span className="badge bg-danger">Declined</span>
                                                                    }
                                                                    </td>
                                                                <td>
                                                                <div className="d-flex gap-2">
                                                                    <WithPermission module="Cleaner" name="edit_cleaner" message="no" multiple="false" >
                                                                        <div className="edit">
                                                                            <Link to={`/cleaner-edit/${item._id}`} className="btn btn-sm btn-success edit-item-btn" >View Details</Link>
                                                                        </div>
                                                                    </WithPermission>
                                                                    <WithPermission module="Cleaner" name="cleaner_messages" message="no" multiple="false" >
                                                                        {(item.firebase_uid !== undefined && item.firebase_uid !== "" && item.status === 1 && item.isApproved === 1) &&
                                                                            <div className="edit">
                                                                                <Link to={`/cleaner/messages/${item._id}`} className="btn btn-sm btn-secondary edit-item-btn" >View Messages</Link>
                                                                            </div>
                                                                        }
                                                                    </WithPermission>
                                                                    <WithPermission module="Cleaner" name="delete_cleaner" message="no" multiple="false" >
                                                                        <div className="remove">
                                                                            <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => onDelete(item._id)}>Remove</button>
                                                                        </div>
                                                                    </WithPermission>
                                                                </div>
                                                            </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <ReactPaginate
                                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                            breakLabel="..."
                                            pageCount={PageCount}
                                            pageRangeDisplayed={3}
                                            onPageChange={getData}
                                            containerClassName={'pagination'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            renderOnZeroPageCount={null}
                                            activeClassName={'active'}
                                        />
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default ClenerList;
