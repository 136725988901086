import React from 'react';
import Cookies from 'js-cookie';
import { decodeToken  } from "react-jwt";


const WithPermission =  ({module, name, message, multiple, children}) => {

    const token = Cookies.get('token');

    // get user data from token in cookie
    const decoded = (typeof token !== "undefined") ? decodeToken(token) : {};
    
    var is_view = 0;
    if((decoded.userlevel === 1 ||  decoded.userlevel === 4)){
        if(decoded.userlevel === 1){
            is_view = 1;
        }else{
            // Check both module and name
            if(multiple === "false"){
                if(typeof decoded['permissions'][module] !== "undefined"  && typeof decoded['permissions'][module][name] !== "undefined" && decoded['permissions'][module][name].value === true){
                    is_view = 1;
                } 
            }else{  // Check only module
                if(typeof module !== "undefined" && name !== "undefined" && typeof decoded['permissions'][module] !== "undefined"){
                    Object.keys(decoded['permissions'][module]).map((per, ip) => {
                        if(name.includes(per) === true && decoded['permissions'][module][per].value === true){
                            is_view = 1;
                            return;
                        }
                    });
                }
            }    
        }
    }

    return (
        <>
            {
                (is_view === 1) ?  children : (message === "yes") ? (<div className="alert alert-danger" role="alert">
                Don't have permission to access this feature.</div>) : ""
            }
        </>
    )


}
export default WithPermission