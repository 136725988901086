import React,{useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
// import { Wizard } from 'react-use-wizard';
// import BasicDetail from './steps/BasicDetail'
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import helper from '../../helper';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';

const EditHost = (props) => {
        const token = Cookies.get('token');
        // get user data from token in cookie
        const decoded = decodeToken(token);
        const navigate = useNavigate();
        const params = useParams();
        const hostId = params.hostid;
        const assignmentId = params.assignmentId;
        const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
        // Manage State for update assignemnt
        const [basicDetail, setBasicDetail] = useState({
                    id: "",
                    title: "",
                    propertyId: "",
                    hostId: hostId,
                    date: new Date(),
                    startTime: new Date(),
                    endTime: new Date(),
                    checkList: [],
                    cleanerList:[],
                    specificRequest: "",
                    price:0,
                    addedBy: decoded.user_id
                });

        const [properties, setProperties] = useState([]);
        const [checkListArray, setCheckList] = useState([]);
        const [cleanerListArray, setCleanerList] = useState([]);
        const [formValidationPass, setFormValidationPass] = useState([]);
        const [selectedChecklist, setSelectedChecklist] = useState({});

    // Fetch Host Details
    const fetchHostDetails = async () => {

        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'assignment/assignment-detail', {
            id: assignmentId.trim(),
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        });
       
        if(data.status === 1){
            setExistingState(data.data)
            if(data.data.checklistId) {
              getSelectedChecklist(data.data.checklistId)
            }
        }else{
            // navigate('/host-list');
        }
    };

    const setExistingState = (datas) => {
        // Basic information
        basicDetail['id'] = datas._id;
        basicDetail['title'] = datas.title;
        basicDetail['propertyId'] = datas.propertyId;
        basicDetail['hostId'] = datas.hostId;
        basicDetail['date'] = new Date(datas.date);
        basicDetail['startTime'] = new Date(datas.startTime);
        basicDetail['endTime'] = new Date(datas.endTime);
        basicDetail['checkList'] = datas.checklist;
        basicDetail['cleanerList'] = datas.cleaners;
        basicDetail['specificRequest'] = datas.specificRequest;
        basicDetail['price'] = datas.price;
        basicDetail['hours'] = datas.hours;
        basicDetail['minutes'] = datas.minutes;
      }

    useEffect(() => {
        getCheckList();
        fetchHostDetails();
        getHostProperties();
        getCleanerList();
    }, []);

    const getHostProperties = () => {
        axios.post(process.env.REACT_APP_BACKENDURL+'property/get-host-properties', 
          {hostId: hostId}
        )
        .then(function (response) {
          setProperties(response.data.data);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getSelectedChecklist = (id) => {
      axios.post(process.env.REACT_APP_BACKENDURL+'checklist/checklist-detail', 
        {hostId: hostId, id: id}
      )
      .then(function (response) {
        setSelectedChecklist(response.data.data);
        basicDetail['checkList'] = {value: response.data.data._id, label: response.data.data.title}
        setBasicDetail({...basicDetail});
      })
      .catch(function (error) {
          console.log(error);
      });
  }

    const getCleanerList = () => {
      let pageNo = (cleanerListArray) ? cleanerListArray.selected : 0;
        axios.get(process.env.REACT_APP_BACKENDURL+'user/get-cleaner',{
            params: {
                page: pageNo,
                limit: 100,
            }
        })
        .then(function (response) {
            if(response.data.status == 1){
              let newArray = [];

              response.data.data.map((item) => {
                newArray.push({value: item._id, label: item.first_name+ ' ' +item.last_name})
              });
              setCleanerList(newArray);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const updateBasicDetail = (name, value) => {
        if(name === "price"){
            value = rx_live.test(value) ? value : basicDetail.price;
        }
        basicDetail[name] = value;
        setBasicDetail({ ...basicDetail });
        checkFormValidation()
      }

      const checkFormValidation = () => {
        setFormValidationPass(true);
        Object.keys(basicDetail).map(key => {
          if(basicDetail[key] === ""){
              setFormValidationPass(false);
          }
        })
      }
  
      const handleChecklist = (selectedOption) => {
        basicDetail['checkList'] = selectedOption;
        setBasicDetail({ ...basicDetail });
        checkFormValidation();
      };
  
      const handleCleanerlist = (selectedOption) => {
        basicDetail['cleanerList'] = selectedOption;
        setBasicDetail({ ...basicDetail });
        checkFormValidation();
      };

      const getCheckList = () => {
        axios.post(process.env.REACT_APP_BACKENDURL+'checklist/get-checklist', 
        {hostId: hostId}
        )
        .then(function (response) {
            if(response.data.status === 1){
              let newArray = [];
              response.data.data.map((item) => {
                newArray.push({value: item._id, label: item.title})
              });
              setCheckList(newArray);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
      }

    const submitForm = (e) => {
        e.preventDefault();
        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'assignment/update-assignment',basicDetail)
        .then(function (response) {
            props.setLoading(false)
            if(response.data.status === 1){
                navigate('/assignment-list/'+hostId);
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
            }else{
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
            console.log(error);
        });
    }

    return (
        <div className="page-content">
            <div className="container-fluid">


                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Update Assignment</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/host-list">Host List</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/host-edit/"+hostId}>Host Detail</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/assignment-list/"+hostId}>Assignment List</Link></li>
                                    <li className="breadcrumb-item active">Update Assignment</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="live-preview">
                        <div className="card">
                          <div className="card-body">
                              <div className="live-preview">
                                <form onSubmit={submitForm}>
                                  <div className="row gy-4">
                                    
                                    <div className='col-md-7'>
                                      <div className='row'>

                                      <div className="col-xxl-3 col-md-6">
                                          <label className="form-label">Title</label>
                                          <input className="form-control" 
                                              name='title' 
                                              onChange={(e) => updateBasicDetail('title', e.target.value)}
                                              value={basicDetail.title} />
                                        </div>

                                        <div className="col-xxl-3 col-md-6">
                                          <label className="form-label">Select property</label>
                                          <select className="form-control" 
                                              name='type' 
                                              onChange={(e) => updateBasicDetail('propertyId', e.target.value)}
                                              value={basicDetail.propertyId}>
                                                <option  value="" >Select Property</option>
                                                {properties.map(item => {
                                                    return (
                                                        <option key={item._id} value={item._id} >{item.propertyName}</option>
                                                    );
                                                })}
                                          </select>
                                        </div>
                                          {/* Manage Date Logic */}
                                        <div className="col-xxl-2 col-md-3 mt-3">
                                          <label className="form-label w-100">Date</label>
                                            <DatePicker className='form-control' 
                                                selected={basicDetail.date} 
                                                minDate={new Date()}
                                                onChange={(date) => updateBasicDetail('date', date)} />
                                        </div>

                                        <div className="col-xxl-2 col-md-3 mt-3">
                                          <label className="form-label w-100">Time start</label>
                                          <DatePicker
                                                  className='form-control'
                                                  selected={basicDetail.startTime}
                                                  onChange={(date) => updateBasicDetail('startTime', date)}
                                                  showTimeSelect
                                                  showTimeSelectOnly
                                                  timeIntervals={60}
                                                  timeCaption="Time"
                                                  dateFormat="h:mm aa"
                                                />
                                        </div>

                                        <div className="col-xxl-2 col-md-3 mt-3">
                                          <label className="form-label">Time end</label>
                                          <DatePicker
                                                  className='form-control'
                                                  selected={basicDetail.endTime}
                                                  onChange={(date) => updateBasicDetail('endTime', date)}
                                                  showTimeSelect
                                                  showTimeSelectOnly
                                                  minTime={setMinutes(basicDetail.startTime, 40)}
                                                  maxTime={setHours(setMinutes(basicDetail.startTime, 45), 23)}
                                                  timeIntervals={60}
                                                  timeCaption="Time"
                                                  dateFormat="h:mm aa"
                                                />
                                        </div>
                                        <div className="col-xxl-2 col-md-3 mt-3">
                                          <label className="form-label">Price</label>
                                            <input 
                                                type="text"
                                                maxLength={5}
                                                className='form-control'
                                                name='price' 
                                                onChange={(e) => updateBasicDetail('price', e.target.value)}                                           
                                                value={basicDetail.price}/>
                                        </div>
                                          {/* Property Checklist */}
                                        <div className="col-xxl-6 col-md-12 mt-3">
                                          <label className="form-label">Property checklist</label>
                                            <Select 
                                                name='checklist' 
                                                options={checkListArray}
                                                onChange={handleChecklist}                                              
                                                closeMenuOnSelect={true}
                                                value={basicDetail.checkList}/>
                                        </div>

                                        <div className="col-xxl-6 col-md-12 mt-3">
                                          <label className="form-label">Invite Cleaners</label>
                                            <Select 
                                                name='cleaner' 
                                                options={cleanerListArray}
                                                onChange={handleCleanerlist}                                              
                                                closeMenuOnSelect={false}
                                                value={basicDetail.cleanerList}
                                                isMulti />
                                        </div>


                                        {/* Manage Date Logic */}


                                      </div>
                                    </div>

                                    <div className="col-xxl-3 col-md-5">
                                      <label className="form-label">Specific Request</label>
                                      {/* <CKEditor
                                        editor={ ClassicEditor }
                                        data={(basicDetail.specificRequest) ? basicDetail.specificRequest : ''}
                                        config={ {
                                          toolbar: [  'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' , 'link' ],
                                          ui: {
                                            poweredBy: {
                                                forceVisible: false
                                            }
                                        }
                                      } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            updateBasicDetail('specificRequest', data)
                                        } }
                                    /> */}

                                    <textarea 
                                      maxLength={500} 
                                      className="form-control" 
                                      value={basicDetail.specificRequest} 
                                      onChange={(e) => updateBasicDetail('specificRequest', e.target.value)} rows="10" placeholder="Specific Requests"></textarea>
                                      <div className="float-end me-1 word-limit-count">{basicDetail.specificRequest.length}/500 Characters</div>
                                    </div>
                                    

                                    <div className="col-12">
                                      <button className="btn btn-success" disabled={!formValidationPass}>Update</button>
                                    </div>
                                    
                                  </div>
                                </form>
                              </div>
                          </div>
                        </div>
                            
                        </div>
                    </div>
                    
                </div>


                
            </div>
        </div>
    )
}

export default EditHost;
