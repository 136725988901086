import React, { useState } from 'react'
import Home from './screens/home';
import Test from './screens/test';
import Layout from './components/layout/admin';
import Login from './screens/login';
import HostList from './screens/host/list';
import HostAdd from './screens/host/add';
import HostEdit from './screens/host/edit';
import CleanerList from './screens/cleaner/list';
import CleanerAdd from './screens/cleaner/add';
import CleanerEdit from './screens/cleaner/edit';
import AdminProfile from './screens/admin/edit';
import PropertyAdd from './screens/property/add';
import PropertyList from './screens/property/list';
import PropertyEdit from './screens/property/edit';
import SubuserList from './screens/subuser/list';
import SubuserAdd from './screens/subuser/add';
import SubuserEdit from './screens/subuser/edit';
import FeedbackList from './screens/settings/feedback';
import Earning from './screens/earnings';
import EarningDetails from './screens/earningDetails.js';

import { ProtectRoutes } from './hooks/protectRoutes'; 
import { Navigate, Route, Routes } from "react-router-dom";
import ChatIndex from './screens/chat/index';
import CheckList from './screens/host/checklist';
import AddChecklist from './screens/host/addChecklist';
import CreateAssignment from './screens/assignment/add';
import AssignmentList from './screens/assignment/list';
import AssignmentDetail from './screens/assignment/edit';

import TransactionFee from './screens/settings/transaction_fee';
import HostTransations from './screens/hostTransations';
import CleanerTransactions from './screens/cleanerTransactions';
import CleanerEarning from './screens/cleanerEarnings';
import AllNotifications from './screens/allNotification';

import HostInsight from './screens/host/insight/index.js';
import HostEarning from './screens/host/insight/earning.js';
import HostProject from './screens/host/insight/project.js';
import HostPropertyInsight from './screens/host/insight/property.js';
import HostCustomerInsight from './screens/host/insight/customers.js';
import HostReviews from './screens/host/insight/reviews.js';


import CleanerInsight from './screens/cleaner/insight/index.js';
import CleanerInsightEarning from './screens/cleaner/insight/earning.js';
import CleanerInsightCustomer from './screens/cleaner/insight/customers.js';
import CleanerProject from './screens/cleaner/insight/project.js';
import CleanerPerfomanceInsight from './screens/cleaner/insight/perfomance.js';
import CleanerCustomerInsight from './screens/cleaner/insight/customers.js';
import CleanerReviews from './screens/cleaner/insight/reviews.js';

import HostMessages from './screens/chat/hostChat.jsx';
import CleanerMessages from './screens/chat/cleanerChat.jsx';
import PrivacyPolicies from './screens/privacyPolicies.js';
import TermsConditions from './screens/termsConditions.js';

// import Help from './screens/help.js';

import Cookies from 'js-cookie';
import axios from 'axios';

function App() {
  const token = Cookies.get('token');
  // Send token in all axios request
  axios.defaults.headers.common['token'] = token;
  
const [progress, setProgress] = useState(0);
let [loading, setLoading] = useState(false);
  return (
        <Routes>
          <Route path="/login" element={<Login />} />
          
          <Route element={<Layout
                                  loading={loading}
                                  progress={progress} 
                                  setProgress={setProgress} />}>
            {/* Host Routes */} 
            <Route element={<ProtectRoutes module="Host" name="add_host" />}>
              <Route path="/host-add" element={<HostAdd  setLoading={setLoading}/>} />
            </Route>
            <Route element={<ProtectRoutes module="Host" name="list_host" />}>
              <Route path="/host-list" element={<HostList setLoading={setLoading}/>} />
            </Route>
            <Route element={<ProtectRoutes module="Host" name="edit_host" />}>
              <Route path="/host-edit/:id/:step?" element={<HostEdit setLoading={setLoading} />} />
            </Route>

            {/* Property Routes */}

            <Route element={<ProtectRoutes module="Properties" name="list_properties" />}>
              <Route path="/host-propertlist/:hostId" element={<PropertyList setLoading={setLoading} />} />
            </Route>

            <Route element={<ProtectRoutes module="Properties" name="add_properties" />}>
              <Route path="/property-add" element={<PropertyAdd setLoading={setLoading} />} />
            </Route>
            <Route element={<ProtectRoutes module="Properties" name="list_properties" />}>
              <Route path="/property-list" element={<PropertyList setLoading={setLoading}/>} />
            </Route>
            <Route element={<ProtectRoutes module="Properties" name="edit_properties" />}>
              <Route path="/property-edit/:id" element={<PropertyEdit setLoading={setLoading}/>} />
            </Route>
            <Route element={<ProtectRoutes module="Properties" name="add_properties" />}>
              <Route path="/host-property-add/:hostId" element={<PropertyAdd setLoading={setLoading} />} />
            </Route>
            <Route element={<ProtectRoutes module="Properties" name="edit_properties" />}>
              <Route path="/host-property-edit/:propertyId/:hostId" element={<PropertyEdit setLoading={setLoading}/>} />
            </Route>            
            <Route element={<ProtectRoutes module="Cleaner" name="list_cleaner" />}>
              <Route path="/cleaner-list" element={<CleanerList setLoading={setLoading} />} />
            </Route>
            <Route element={<ProtectRoutes module="Cleaner" name="add_cleaner" />}>
              <Route path="/cleaner-add" element={<CleanerAdd  setLoading={setLoading}/>} />
            </Route>
            <Route element={<ProtectRoutes module="Cleaner" name="edit_cleaner" />}>
              <Route path="/cleaner-edit/:id/:step?" element={<CleanerEdit  setLoading={setLoading} />} />
            </Route>
            <Route element={<ProtectRoutes module="Cleaner" name="edit_cleaner" />}>
              <Route path="/cleaner-transactions/:id" element={<CleanerTransactions  setLoading={setLoading} />} />
            </Route>
            <Route element={<ProtectRoutes module="Cleaner" name="edit_cleaner" />}>
              <Route path="/cleaner-earning/:id" element={<CleanerEarning  setLoading={setLoading} />} />
            </Route>
            
            {/* Assigments */}

            <Route element={<ProtectRoutes module="Assignments" name="add_assignments" />}>
              <Route path="/create-assignment/:id?" element={<CreateAssignment setLoading={setLoading} />} />
            </Route>
            <Route element={<ProtectRoutes module="Assignments" name="list_assignments" />}>
              <Route path="/assignment-list/:id?" element={<AssignmentList setLoading={setLoading} />} />
            </Route>
            <Route element={<ProtectRoutes module="Assignments" name="edit_assignments" />}>
              <Route path="/assignment-edit/:hostid/:assignmentId" element={<AssignmentDetail setLoading={setLoading} />} />
            </Route>
            <Route element={<ProtectRoutes module="Feedback" name="list_feedback" />}>
              <Route path="/feedback" element={<FeedbackList setLoading={setLoading} />} />
            </Route>

            {/* Subusers */}

            <Route element={<ProtectRoutes module="Subuser" name="list_subuser" />}>
              <Route path="/subuser-list" element={<SubuserList setLoading={setLoading} />} />
            </Route>
            <Route element={<ProtectRoutes module="Subuser" name="add_subuser" />}>
              <Route path="/subuser-add" element={<SubuserAdd setLoading={setLoading} />} />
            </Route>
            <Route element={<ProtectRoutes module="Subuser" name="edit_subuser" />}>
              <Route path="/subuser-edit/:id" element={<SubuserEdit setLoading={setLoading} />} />
            </Route>

            {/* Host Transaction list */}
            <Route element={<ProtectRoutes module="Host Transaction" name="view_transaction" />}>
              <Route path="/host-transactions/:hostId" element={<HostTransations setLoading={setLoading}/>} />
            </Route>
            
            {/* Host Insight list */}
            <Route element={<ProtectRoutes module="host" name="host_insight" />}>
              <Route path="/host/insight/:hostId" element={<HostInsight />} />
              <Route path="/host/insight/earning/:hostId" element={<HostEarning setLoading={setLoading} />} />
              <Route path="/host/insight/project/:hostId" element={<HostProject setLoading={setLoading} />} />
              <Route path="/host/insight/property/:hostId" element={<HostPropertyInsight setLoading={setLoading} />} />
              <Route path="/host/insight/cleaner/:hostId" element={<HostCustomerInsight setLoading={setLoading} />} />
              <Route path="/host/insight/reviews/:hostId" element={<HostReviews setLoading={setLoading} />} />
            </Route>

             {/* cleaner Insight list */}
            <Route element={<ProtectRoutes module="cleaner" name="cleaner_insight" />}>
              <Route path="/cleaner/insight/:cleanerId" element={<CleanerInsight />} />
              <Route path="/cleaner/insight/earning/:cleanerId" element={<CleanerInsightEarning setLoading={setLoading} />} />
              <Route path="/cleaner/insight/customer/:cleanerId" element={<CleanerInsightCustomer setLoading={setLoading} />} />
              <Route path="/cleaner/insight/project/:cleanerId" element={<CleanerProject setLoading={setLoading} />} />
              <Route path="/cleaner/insight/perfomace/:cleanerId" element={<CleanerPerfomanceInsight setLoading={setLoading} />} />
              <Route path="/cleaner/insight/cleaner/:cleanerId" element={<CleanerCustomerInsight setLoading={setLoading} />} />
              <Route path="/cleaner/insight/reviews/:cleanerId" element={<CleanerReviews setLoading={setLoading} />} />
            </Route>


            <Route element={<ProtectRoutes module="Host" name="host_messages" />}>
              <Route path="host/messages/:userId/:id?" element={<HostMessages setLoading={setLoading} />} />
            </Route>
            <Route element={<ProtectRoutes module="Cleaner" name="cleaner_messages" />}>
              <Route path="cleaner/messages/:userId/:id?" element={<CleanerMessages setLoading={setLoading} />} />
            </Route>

            {/* <Route element={<ProtectRoutes module="Help" name="list_help" />}>
              <Route path="/help" element={<Help setLoading={setLoading} />} />
            </Route> */}
          </Route>

          <Route element={<ProtectRoutes module="" name="" />}>
            <Route element={<Layout
                                loading={loading}
                                progress={progress} 
                                setProgress={setProgress} />}>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<Home />} />
              <Route path="/commission" element={<Earning setLoading={setLoading}/>} />
              <Route path="/commission-detail/:id?" element={<EarningDetails setLoading={setLoading}/>} />
              <Route path="/transaction-fee" element={<TransactionFee />} />
              <Route path="/profile" element={<AdminProfile />} />
              <Route path="/messages/:id?" element={<ChatIndex />} />
              <Route path="/checklist/list/:id" element={<CheckList setLoading={setLoading} />} />
              <Route path="/checklist/add/:hostId" element={<AddChecklist />} />
              <Route path="/checklist/edit/:checkListId/:hostId" element={<AddChecklist />} />
              <Route path="/test" element={<Test />} />
              <Route path="/all-notification" element={<AllNotifications setLoading={setLoading}/>} />
              <Route path="/privacy-policy" element={<PrivacyPolicies setLoading={setLoading} />} />
              <Route path="/terms-and-conditions" element={<TermsConditions setLoading={setLoading} />} />
            </Route>
          </Route>
          
        </Routes>
  );
}

export default App;
