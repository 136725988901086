import Swal from "sweetalert2";

const helper = {
  types: [
    {id:1, name: "Home"},
    {id:2, name: "Hotel"},
    {id:3, name: "Apartment"},
    {id:4, name: "Rv/ Camper"},
    {id:5, name: "Cabin"},
    {id:6, name: "School"},
    {id:7, name: "Restaurant"},
    {id:8, name: "Office"},
    {id:9, name: "Lake House"},
    {id:10, name: "Boat"},
    {id:11, name: "Beach House"}
  ],
  subTypes: [
    { id: 1, name: "Short Term Rental" },
    { id: 2, name: "Residential Building" },
    { id: 3, name: "Commercial Building" },
    { id: 4, name: "Long Term Rental" },
    { id: 5, name: "Government Building" }
  ],
  additionlFields: ["bedrooms", "bed", "bathroom", "kitchen", "garage"],
  propertyWizardSteps: ['Basic Details','Additional Detail', 'Address','Image Upload', 'Preview'],
  // additionlFields: [
  //   {label: "Bedroom", value: "bedroom"},
  //   {label: "Full Bathroom", value: "fullBathroom"},
  //   {label: "Half Bathroom", value: "halfBathroom"},
  //   {label: "Full kitchen", value: "fullKitchen"},
  //   {label: "kitchenetee", value: "kitchen"},
  //   {label: "Living Room", value: "livingRoom"},
  //   {label: "Dining Area", value: "diningArea"},
  //   {label: "Office", value: "office"},
  //   {label: "laundry Room", value: "laundryRoom"}
  //   // {label: "Bed", value: "bed"},
  //   // {label: "bathroom", value: "bathroom"},
  //   // {label: "garage", value: "garage"},
  // ],
  Toast: () =>{
      return Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
  },
  subuserPermissions: {
    "Host" : {
          "list_host" : {
            "name": "Host List",
            "value" : false,
        },
        "add_host" : {
            "name": "Add Host",
            "value" : false,
        },
        "edit_host" : {
            "name" : "Edit Host",
            "value" : false,
        },
        "delete_host" : {
            "name" : "Delete Host",
            "value" : false,
        },
        "change_password" : {
            "name" : "Change Password",
            "value" : false,
        },
        "host_messages" : {
            "name" : "Host Messages",
            "value" : false,
        }
    },
    "Cleaner" : {
        "list_cleaner" : {
            "name" : "Cleaner List",
            "value" : false,
        },
        "add_cleaner" : {
            "name" : "Add Cleaner",
            "value" : false,
        },
        "edit_cleaner" : {
            "name" : "Edit Cleaner",
            "value" : false,
        },
        "delete_cleaner" : {
            "name" : "Delete Cleaner",
            "value" : false,
        },
        "change_password" : {
            "name" : "Change Password",
            "value" : false,
        },
        "cleaner_messages" : {
            "name" : "Cleaner Messages",
            "value" : false,
        }
    },
    "Properties" : {
        "list_properties" : {
            "name" : "Properties List",
            "value" : false,
        },
        "add_properties" : {
            "name" : "Add Properties",
            "value" : false,
        },
        "edit_properties" : {
            "name" : "Edit Properties",
            "value" : false,
        },
        "delete_properties" : {
            "name" : "Delete Properties",
            "value" : false,
        },
    },
    "Assignments" : {
        "list_assignments" : {
            "name" : "List Assignments",
            "value" : false,
        },
        "add_assignments" : {
            "name" : "Add Assignments",
            "value" : false,
        },
        "edit_assignments" : {
            "name" : "Edit Assignments",
            "value" : false,
        },
        "delete_assignments" : {
            "name" : "Delete Assignments",
            "value" : false,
        },
    },
    "Checklist" : {
        "add_checklist" : {
            "name" : "Add Checklist",
            "value" : false,
        },
        "edit_checklist" : {
            "name" : "Edit Checklist",
            "value" : false,
        },
        "delete_checklist" : {
            "name" : "Delete Checklist",
            "value" : false,
        },
    },
    "Subuser" : {
        "list_subuser" : {
            "name" : "List Subuser",
            "value" : false,
        },
        "add_subuser" : {
            "name" : "Add Subuser",
            "value" : false,
        },
        "edit_subuser" : {
            "name" : "Edit Subuser",
            "value" : false,
        },
        "delete_subuser" : {
            "name" : "Delete Subuser",
            "value" : false,
        },
    },
    "TransactionFee" : {
        "view_fee" : {
            "name" : "View fee",
            "value" : false,
        },
        "update_fee" : {
            "name" : "Update fee",
            "value" : false,
        }
    },
    "Host Transaction" : {
        "view_transaction" : {
            "name" : "View transaction",
            "value" : false,
        }
    },
    "Cleaner Transactions" : {
        "view_transaction" : {
            "name" : "View transaction",
            "value" : false,
        }
    },
    "Cleaner Payouts" : {
        "view_payouts" : {
            "name" : "View payouts",
            "value" : false,
        }
    },
    "Insight" : {
        "host_insight" : {
            "name" : "Host Insight",
            "value" : false,
        },
        "cleaner_insight" : {
            "name" : "Cleaner Insight",
            "value" : false,
        }
    },
    "Feedback" : {
        "list_feedback" : {
            "name" : "Feedback List",
            "value" : false,
        }
    },
    // "Help" : {
    //     "list_help" : {
    //         "name" : "Help List",
    //         "value" : false,
    //     }
    // }
    "Commission" : {
        "list_commission" : {
            "name" : "Commission List",
            "value" : false,
        }
    }
  },
  captilizeString: (string =  "") => {
    return string[0].toUpperCase() + string.slice(1);
  },
  removeTag: (str) => {
    if ((str === null) || (str === ''))
        return false;
    else
        str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
  },
  obscureEmail: (email) => {
    if(email !== undefined && email.length > 0) {
      const [name, domain] = email.split('@');
      return `${name[0]}${new Array(name.length-1).join('*')}${new Array(name[name.length-1])}@${domain}`;
    }
    return true;
  },

  obscurePhone: (phone) => {
    if(phone !== undefined && phone.length > 0) {
      return `+1${new Array(4).join('*')}-${new Array(4).join('*')}-${new Array(phone.slice(-4))}`;
    }
    return;
  },

  obscureSSN: (ssn) => {
    if(ssn !== undefined && ssn.length === 9) {
      return `${new Array(4).join('*')}-${new Array(3).join('*')}-${new Array(ssn.slice(-4))}`;
    } else if(ssn !== undefined && ssn.length !== 9) {
      return ssn;
    }
    return;
  },

  obscureTaxID: (taxId) => {
    if(taxId !== undefined && taxId.length > 0) {
      return `${new Array(4).join('*')}-${new Array(3).join('*')}-${new Array(3).join('*')}${new Array(taxId.slice(-2))}`;
    }
    return;
  },

  checkNumericInput: (event) => {
    if (isNaN(event.key) && event.key !== "Backspace") {
      event.preventDefault();
      return false;
    } else {
      if(event.key === ' '){
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    }
  },
  checkAlphabetInput: (event) => {
    var regex = /^[A-Za-z\s]+$/;
    if(!event.key.match(regex) && event.key !== "Backspace" && !isNaN(event.key)) {
      event.preventDefault();
      return false;
    } else {
      if(regex.test(event.key)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
  },
  checkOnlyAlphabetInput: (event) => {
    var regex = /^[A-Za-z]+$/;
    if(!event.key.match(regex) && event.key !== "Backspace" && !isNaN(event.key)) {
      event.preventDefault();
      return false;
    } else {
      if(regex.test(event.key)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
  },
  validateOnlyAlphabetInput: (value) => {
    var regex = /^[A-Za-z]+$/;
    if(!value.match(regex)) {
      return false;
    } else {
      return true;
    }
  },
  isValidHttpUrl: (string) => {
    try {
      const newUrl = new URL(string);
      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
      return false;
    }
  },
  isValidEmail: (inputValue) => {
    // var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // var ValidRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (inputValue.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  },
  checkAlphabetInputWithComma: (event) => {
    var regex = /^[a-zA-Z, ]+$/;
    if(!event.key.match(regex) && event.key !== "Backspace" && !isNaN(event.key)) {
      event.preventDefault();
      return false;
    } else {
      if(regex.test(event.key)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
  },
  isDateFormatCorrect: (dateString, format) => {
    var formatPattern = format
        .replace(/MM/g, '(0[1-9]|1[0-2])') // Month (01-12)
        .replace(/DD/g, '(0[1-9]|[1-2][0-9]|3[0-1])') // Day (01-31)
        .replace(/YYYY/g, '\\d{4}'); // Year (four digits)

    var regex = new RegExp('^' + formatPattern + '$');
    return regex.test(dateString);
  },
  isContainSpace: (value) => {
    const index = value.indexOf(" ");
    if (index !== -1) {
      // The string contains a space character.
    } else {
      // The string does not contain a space character.
    }
  },
  checkSpaceInput: (event) => {
    if(event.key === " ") {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  },
  roundNumber: (value) => {
    const res = Math.round((value + Number.EPSILON)*100)/100;
    return res;
  },
  capitalizeFirstLetter: (str) => {
    return str.trim().charAt(0).toUpperCase() + str.slice(1);
  }
}

export default helper;