import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../auth';
import Cookies from 'js-cookie';
import { decodeToken  } from "react-jwt";
import axios from 'axios';

export const ProtectRoutes = ({module, name}) => {
    var is_view = 0;
    var is_login = 0;
    
    const token = Cookies.get('token');
    if(token !== undefined && token !== ""){

        // get user data from token in cookie
        const decoded = decodeToken(token);

        if((decoded.userlevel === 1 ||  decoded.userlevel === 4)){
            is_login = 1;
            if(decoded.userlevel === 1 || (module === "" || name === "") ){
                is_view = 1;
            }else{
               
                if(typeof decoded['permissions'][module] !== "undefined"  && typeof decoded['permissions'][module][name] !== "undefined" && decoded['permissions'][module][name].value === true){
                    is_view = 1; 
                }else{
                    is_view = 0; 
                }
               
            }
        }

        // axios.interceptors.response.use(response => {
        //     return response;
        //   }, error => {
        //   if (error.response.status === 401) {            
            
        //   }
        //   return error;
        // })
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (401 === error.response.status) {
                // handle error: inform user, go to login, etc
                console.log('go to login')
            } else {
                return Promise.reject(error);
            }
        });

    }

    return (is_login === 1) ?
                (is_view === 1) ? <Outlet/> : <Navigate to='/dashboard' exact />
            : <Navigate to='/login' exact />
};