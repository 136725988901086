import React , { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import WithPermission from '../components/WithPermission';

const CleanerTransaction = (props) => {
    const token = Cookies.get('token');
    const params = useParams();
    const transId = params.id;
    const [earningDetail, setEarningDetail] = useState([]);
    const inputStyle = {
        backgroundColor: "#8080802e"
    }

    useEffect(() => {
        fetchEarningDetail();
    }, []);

    const fetchEarningDetail = async () => {
        props.setLoading(true);
        const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'dashboard/earning-detail',
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'token': token
                            },
                            params: {
                                transactionId: transId
                            }
                        })
                        .catch(function (error) {
                            props.setLoading(false);
                        });

        props.setLoading(false);
        if(data.status === 1){
            setEarningDetail(data.data);
        }
    };

    function convertTimeFormat(d) {
        const timeFormat = new Date(d).toLocaleString("en-US", {
            hour: "numeric",
            minute: "2-digit"
        });
        return timeFormat;
    }

    return (
        <>
        <WithPermission module="Commission" name="list_commission" message="yes" multiple="false">
        <div className="page-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Commission Detail</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                                    <li className="breadcrumb-item"> <Link to="/commission">Commission</Link> </li>
                                    <li className="breadcrumb-item active">Commission Detail</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>


            <div className="row">
                <div className="col-xl-12">
                    {earningDetail.length > 0 ?
                        <>
                        <div className="card mt-0">
                            <div className="card-body">

                            {
                                (earningDetail.length >= 1) ?
                                    earningDetail.map(function(object, i){
                                        return <>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label className='form-label'>Assignment Name</label>
                                                        <input type='text' readOnly className='form-control' value={object.title} style={inputStyle} />
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label className='form-label'>Property Name</label>
                                                        <input type='text' readOnly className='form-control' value={object.propertyName} style={inputStyle} />
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label className='form-label'>Date</label>
                                                        <input type='text' readOnly className='form-control' value={moment(object.date).format('MMM Do YYYY')} style={inputStyle} />
                                                    </div>
                                                </div>

                                                <div className='row mt-2'>
                                                    <div className='col-md-4'>
                                                        <label className='form-label'>Start Time</label>
                                                        <input type='text' readOnly className='form-control' value={convertTimeFormat(object.startTime)} style={inputStyle} />
                                                    </div>
                                                    
                                                    <div className='col-md-4'>
                                                        <label className='form-label'>End Time</label>
                                                        <input type='text' readOnly className='form-control' value={convertTimeFormat(object.endTime)} style={inputStyle} />
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label className='form-label'>Price</label>
                                                        <input type='text' readOnly className='form-control' value={'$'+parseFloat(object.price).toFixed(2)} style={inputStyle} />
                                                    </div>
                                                </div>

                                                <div className='row mt-2'>
                                                    <div className='col-md-4'>
                                                        <label className='form-label'>Commission</label>
                                                        <input type='text' readOnly className='form-control' value={'$'+parseFloat(object.earning).toFixed(2)} style={inputStyle} />
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label className='form-label'>Commission From</label>
                                                        <input type='text' readOnly className='form-control' value={(object.payType === 1) ? "Host" : "Cleaner" } style={inputStyle} />
                                                    </div>
                                                    
                                                    <div className='col-md-4'>
                                                        <label className='form-label'>Cleaner Name</label>
                                                        <input type='text' readOnly className='form-control' value={object.cleanerName} style={inputStyle} />
                                                    </div>
                                                    
                                                    <div className='col-md-4'>
                                                        <label className='form-label'>Host Name</label>
                                                        <input type='text' readOnly className='form-control' value={object.hostName} style={inputStyle} />
                                                    </div>
                                                </div>
                                            </>
                                    })
                                :
                                    <span>Earning detail not available</span>
                            }
                            </div>
                        </div>
                        </>
                        :
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="alert alert-danger" role="alert">Earning detail not available</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
          </div>
        </div>
        </WithPermission>
    </>
    )
}

export default CleanerTransaction;
