/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import helper from '../../helper/index.js';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';
import WithPermission from '../../components/WithPermission.js';

const CheckList = () => {
    const navigate = useNavigate();
    const token = Cookies.get('token');
    // get user data from token in cookie
    const decoded = decodeToken(token);
    const params = useParams();
    const hostId = params.hostId;
    const checkListId = params.checkListId;
    const [title, setTitle] = useState("");
    const [checkListArray, setCheckList] = useState([]);
    const [errorTitle, setFNErrorMessage] = useState("");
    const [isInitialState, setIsInitialState] = useState(true)
    const [updateState, setUpdateState] = useState({index:-1, title: "", options: [], currentState: "add"})
    const [checklistOptions, setChecklistOptions] = useState([''])
    const [option, setOption] = useState("")
    const [checklistData, setChecklistData] = useState([])

    useEffect(() => {
        if(isInitialState === true){
            setIsInitialState(false);
        }
    }, [checkListArray.length])

    const getChecklistDetail = () => {
        // props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'checklist/checklist-detail',
        {id: checkListId, hostId: hostId, addedBy: decoded.user_id},
        {
          headers: {
              'token': token,
              'Content-Type': 'application/json'
          }
        }
      )
      .then(function (response) {
          // props.setLoading(false)
          if(response.data.status === 1){
            setChecklistData(response.data.data)
            setTitle(response.data.data.title)
            setChecklistOptions(response.data.data.checklistData)
          }else{
              helper.Toast().fire({
                  icon: 'error',
                  title: response.data.message
              })
          }
      })
      .catch(function (error) {
          // props.setLoading(false)
          console.log(error);
      });
    }
    
    useEffect(() => {
      if(checkListId){
        getChecklistDetail()
        updateState['currentState'] = "edit"
        setUpdateState({...updateState})
      }
    }, [])

    const createCheckList = () => {
        // props.setLoading(true)
        var arr = checklistOptions;
        var unique = [...new Set(arr)]
        if(arr.findIndex(e => e === "") > -1){
            helper.Toast().fire({ icon: 'warning', title: "All checklist options has been required!" });
            return;
        }else if(arr.length !== unique.length){
            helper.Toast().fire({ icon: 'warning', title: "Please ensure your checklist items are unique. Remove any duplicates or conflicting values." });
            return;
        }
        let url = updateState.currentState === "add" ? 
                    process.env.REACT_APP_BACKENDURL+'checklist/create-checklist' : 
                    process.env.REACT_APP_BACKENDURL+'checklist/update-checklist';
        axios.post(url,
            {
              title: title, 
              checklistData: checklistOptions, 
              id: (checkListId) ? checkListId : null,
              hostId: hostId,
              addedBy: decoded.user_id
            },
            {
              headers: {
                  'token': token,
                  'Content-Type': 'application/json'
              }
          }
        )
        .then(function (response) {
            // props.setLoading(false)
            if(response.data.status === 1){
                navigate('/checklist/list/'+hostId)
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
            }else{
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            // props.setLoading(false)
            console.log(error);
        });
    }

    const addOptions = () => {
    //   if(checklistOptions.indexOf(option.trim()) === -1) {
        checklistOptions.push("");
        setOption('');
        setChecklistOptions([...checklistOptions]);
    // }else{
        // helper.Toast().fire({
        //     icon: 'error',
        //     title: "Enter a unique value, entered value already exist in the checklist item"
        // })
    // }
    }

    const updateChecklistOptionData = (value, index) => {
      checklistOptions[index] = value
      setChecklistOptions(checklistOptions)
    }

    const checkContentLength = (e) => {
        if(e.target.textContent.length > 80 && e.key !== "Backspace") {
            e.preventDefault();
            return;
        }
    }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
              {/* Breadcrumbs */}
              <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">{updateState.currentState} Checklist</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                                    <li className="breadcrumb-item"><Link to="/host-list">Host List</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/host-edit/"+hostId}>Host Detail</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/checklist/list/"+hostId}>Checklist</Link></li>
                                    <li className="breadcrumb-item active">Add Checklist</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row gy-4">

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title mb-0 text-capitalize">Title</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <input type="text" className="form-control"
                                            value={title} 
                                            placeholder='Enter Title'
                                            onChange={(e) => setTitle(e.target.value) }
                                            maxLength={100}/>
                                        {errorTitle && <div className="text-danger"> {errorTitle} </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header  align-items-center d-flex justify-content-between">
                                <div className='d-flex align-items-start flex-column '>
                                    <h4 className="card-title mb-0">Checklist Options</h4>
                                </div>
                                <button 
                                    onClick={addOptions}
                                    className='btn btn-primary'>Add to checklist</button>
                            </div>
                            <div className="card-body">
                                {checklistOptions.map((name, index) =>
                                    <div className='checklist-items d-flex checklist-items-box' key={index}>                                        
                                        <div style={{ "textAlign": "left", "padding" : "4px 10px"}} contentEditable="true" className='item-name'
                                        onKeyDown={(e) => checkContentLength(e)} 
                                        onInput={(e) => updateChecklistOptionData(e.currentTarget.textContent, index) }>{name}</div>
                                        <div style={{ "textAlign": "left", "padding" : "4px 10px"}}>
                                            <div className="hstack gap-3 flex-wrap">
                                                <a href="#javascript"
                                                    onClick={() => setChecklistOptions(checklistOptions.filter(function (item,key) { return key !== index}))} 
                                                    className="link-danger delete-icon fs-18">
                                                    <i className='ri-delete-bin-line'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='card-footer'>
                                <div className="col-md-12">
                                    <WithPermission module="Checklist" name="edit_checklist" message="no" multiple="false" >
                                        <button
                                            className="btn btn-primary"
                                            disabled={(title.trim().length > 2) ? false : true}
                                            onClick={createCheckList}>{updateState.currentState === "add" ? "Save" : "Update" }</button>
                                    </WithPermission>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

              </div>
        </div>
    )
}

export default CheckList;
