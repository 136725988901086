import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from "../hooks/auth";
import WithPermission from './WithPermission';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';
import Avatar from 'react-avatar';
import UnreadChatCount from './common/UnreadChat.js';

const Sidebar = () => {

    const token = Cookies.get('token');
    // get user data from token in cookie
    const decoded = (typeof token !== "undefined") ? decodeToken(token) : {};
    const [unreadChatCount, setUnreadChatCount] = useState(0);
    const { logout } = useAuth();
    function handleLogout(){
        logout();
    }

    return (
        <div className="app-menu navbar-menu">
            <UnreadChatCount
                setUnreadChatCount = {setUnreadChatCount}
            />
            <div className="navbar-brand-box">
                 <Link to="/" className="logo logo-dark">
                    <span className="logo-sm">
                        <img src="/assets/images/logo-sm.png" alt="" height="22" />
                    </span>
                    <span className="logo-lg">
                        <img src="/assets/images/logo-dark.png" alt="" height="40" />
                    </span>
                 </Link>
             
                
                <Link to="/" className="logo logo-light">
                    <span className="logo-sm">
                        <img src="/assets/images/logo-sm.png" alt="" height="22" />
                    </span>
                    <span className="logo-lg">
                        <img src="/assets/images/logo-light.png" alt="" height="40" />
                    </span>
                </Link>
                <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                    id="vertical-hover">
                    <i className="ri-record-circle-line"></i>
                </button>
            </div>

            <div id="scrollbar">
                <div className="container-fluid">

                    <div id="two-column-menu">
                    </div>
                    <ul className="navbar-nav" id="navbar-nav">
                        <li className="menu-title"><span data-key="t-menu">Menu</span></li>
                        <li className="nav-item active">
                            <NavLink to="/" className="nav-link menu-link" activeclassname="active">
                                <i className="ri-dashboard-2-line"></i> <span data-key="t-widgets">Dashboard</span>
                            </NavLink>
                        </li>

                        <WithPermission module="Commission" name={["list_commission"]} message="no" multiple="false" >
                            <li className="nav-item active">
                                <NavLink to="/commission" className="nav-link menu-link" activeclassname="active">
                                    <i className="ri-wallet-3-line"></i> <span data-key="t-widgets">Commission</span>
                                </NavLink>
                            </li>
                        </WithPermission>

                        {/* Host Managmanet */}
                        <WithPermission module="Host" name={["add_host", "list_host"]} message="no" multiple="true" >
                            <li className="nav-item">

                                <Link to="#sidebarHostManage" className="nav-link menu-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarHostManage">
                            
                                    <i className="ri-apps-2-line"></i> <span
                                        data-key="t-authentication">Host Management</span>
                                </Link>
                                <div className="collapse menu-dropdown" id="sidebarHostManage">
                                    <ul className="nav nav-sm flex-column">
                                        <WithPermission module="Host" name="add_host" message="no" multiple="false" >
                                            <li className="nav-item">
                                                <NavLink to="/host-add" className="nav-link" activeclassname="active">
                                                Add New Host
                                                </NavLink>
                                            </li>
                                        </WithPermission>
                                        <WithPermission module="Host" name="list_host" message="no" multiple="false">
                                            <li className="nav-item">
                                                <NavLink to="/host-list" className="nav-link" activeclassname="active">
                                                Host List
                                                </NavLink>
                                            </li>
                                        </WithPermission>
                                    </ul>
                                </div>
                            </li>
                        </WithPermission>

                        <WithPermission module="Cleaner" name={["add_cleaner", "list_cleaner"]} message="no" multiple="true" >
                            <li className="nav-item">
                                <Link className="nav-link menu-link" to="#sidebarCleaner" data-bs-toggle="collapse" role="button"
                                    aria-expanded="false" aria-controls="sidebarCleaner">
                                    <i className="ri-layout-3-line"></i> <span
                                    >Cleaner Management</span>
                                </Link>
                                <div className="collapse menu-dropdown" id="sidebarCleaner">
                                    <ul className="nav nav-sm flex-column">
                                        <WithPermission module="Cleaner" name="add_cleaner" message="no" multiple="false">
                                            <li className="nav-item">
                                                <NavLink to="/cleaner-add" className="nav-link" activeclassname="active"> Add Cleaner
                                                </NavLink>
                                            </li>
                                        </WithPermission>
                                        <WithPermission module="Cleaner" name="list_cleaner" message="no" multiple="false">
                                            <li className="nav-item">
                                                <NavLink to="/cleaner-list" className="nav-link"
                                                    data-key="t-cover" activeclassname="active">Cleaner List
                                                </NavLink>
                                            </li>
                                        </WithPermission>
                                    </ul>
                                </div>
                            </li>
                        </WithPermission>
                        
                        <WithPermission module="Subuser" name={["add_subuser", "list_subuser"]} message="no" multiple="true" >
                            <li className="nav-item">
                                <Link className="nav-link menu-link" to="#subUser" data-bs-toggle="collapse" role="button"
                                    aria-expanded="false" aria-controls="subUser">
                                    <i className="ri-account-pin-box-line"></i> <span
                                    >Subuser Management</span>
                                </Link>
                                <div className="collapse menu-dropdown" id="subUser">
                                    <ul className="nav nav-sm flex-column">
                                        <WithPermission module="Subuser" name="add_subuser" message="no" multiple="false">
                                            <li className="nav-item">
                                                <NavLink to="/subuser-add" className="nav-link"
                                                 activeclassname="active"> Add Subuser
                                                </NavLink>
                                            </li>
                                        </WithPermission>
                                        <WithPermission module="Subuser" name="list_subuser" message="no" multiple="false">
                                        <li className="nav-item">
                                            <NavLink to="/subuser-list" className="nav-link"
                                                data-key="t-cover" activeclassname="active">Subuser List
                                            </NavLink>
                                        </li>
                                        </WithPermission>
                                    </ul>
                                </div>
                            </li>
                        </WithPermission>

                        <WithPermission module="Properties" name={["list_properties", "add_properties"]} message="no" multiple="true" >
                            <li className="nav-item">
                                <Link className="nav-link menu-link" to="#sidebarProperty" data-bs-toggle="collapse" role="button"
                                    aria-expanded="false" aria-controls="sidebarProperty">
                                    <i className="ri-server-line"></i> <span
                                    >Manage Properties</span>
                                </Link>
                                <div className="collapse menu-dropdown" id="sidebarProperty">
                                    <ul className="nav nav-sm flex-column">
                                        <WithPermission module="Properties" name="add_properties" message="no" multiple="false">
                                            <li className="nav-item">
                                                <NavLink to="/property-add" className="nav-link" activeclassname="active"> Add Property </NavLink>
                                            </li>
                                        </WithPermission>
                                        <WithPermission module="Properties" name="list_properties" message="no" multiple="false">
                                            <li className="nav-item">
                                                <NavLink to="/property-list" className="nav-link" data-key="t-cover" activeclassname="active">Property List</NavLink>
                                            </li>
                                        </WithPermission>
                                    </ul>
                                </div>
                            </li>
                        </WithPermission>

                        <li className="nav-item active">
                            <NavLink to="/transaction-fee" className="nav-link menu-link" activeclassname="active">
                            <i className="ri-layout-3-line"></i> <span data-key="t-widgets">Transaction Fee</span>
                            </NavLink>
                        </li>
                        
                        <li className="nav-item d-flex active">
                            <NavLink to="/messages" className="nav-link menu-link" activeclassname="active">
                                <i className="ri-message-2-line"></i> <span data-key="t-widgets">Messages</span>
                            </NavLink>
                            {
                                (unreadChatCount > 0) &&
                                <i className="fs-10 badge rounded-pill unread-msg"><span className='d-none'>{unreadChatCount}</span></i>
                            }
                        </li>

                        <WithPermission module="Feedback" name="list_feedback" message="no" multiple="false">
                            <li className="nav-item d-flex active">
                                <NavLink to="/feedback" className="nav-link menu-link" activeclassname="active">
                                    <i className="ri-feedback-line"></i> <span data-key="t-widgets">Feedback</span>
                                </NavLink>
                            </li>
                        </WithPermission>
                        
                        {/* <WithPermission module="Help" name="list_help" message="no" multiple="false">
                            <li className="nav-item d-flex active">
                                <Link to="/help" className="nav-link menu-link">
                                    <i className="ri-global-line"></i> <span data-key="t-widgets">Help/Support</span>
                                </Link>
                            </li>
                        </WithPermission> */}

                        {/* <WithPermission module="Privacy" name="edit_privacy" message="no" multiple="false"> */}
                            <li className="nav-item d-flex active">
                                <NavLink to="/privacy-policy" className="nav-link menu-link" activeclassname="active">
                                    <i className="ri-eye-off-line"></i> <span data-key="t-widgets">Privacy Policies</span>
                                </NavLink>
                            </li>
                            <li className="nav-item d-flex active">
                                <NavLink to="/terms-and-conditions" className="nav-link menu-link" activeclassname="active">
                                    <i className="bx bx-spreadsheet"></i> <span data-key="t-widgets">Terms & Conditions</span>
                                </NavLink>
                            </li>
                        {/* </WithPermission> */}

                        <li className="nav-item active">
                            <NavLink to="/profile" className="nav-link menu-link" activeclassname="active">
                                <i className="ri-account-circle-line"></i> <span data-key="t-widgets">Profile</span>
                            </NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <Link className="nav-link menu-link cursor-pointer logout-btn" onClick={handleLogout}>
                                <i className="ri-honour-line text-start"></i><span>Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
        
            </div>

            <div className="sidebar-background"></div>

            <div className="navbar-brand-box">
                <div className="dropdown header-item topbar-user">
                    <button type="button" className="btn" >
                        <span className="d-flex align-items-center">
                            
                            <Avatar name={decoded.name} color="#8F368D" size={40} round="40px"/>
                            <span className="text-start ms-xl-2">
                                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{decoded.name}</span>
                            </span>
                        </span>
                    </button>
                </div>
            </div>


            <div className="d-flex align-items-center">

                
            </div>
        </div>
        
        //  <div className="vertical-overlay"></div>
    )
}

export default Sidebar;
