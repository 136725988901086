import React,{useState, useEffect} from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Wizard } from 'react-use-wizard';
import BasicDetail from './steps/BasicDetail'
import AdditionalInformation from './steps/AdditionalInformation'
import Address from './steps/Address'
import Images from './steps/Images'
// import Checklist from './steps/Checklist'
import Preview from './steps/Preview'
import helper from '../../helper/index.js';
import { PropertyContext } from '../../Contexts/PropertyContext';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';
import '../../styles/incrementButton.css';

const EditProperty = (props) => {
    const token = Cookies.get('token');
    // get user data from token in cookie
    const decoded = decodeToken(token);

    const navigate = useNavigate();
    const params = useParams();

    const propertyId = (params.id) ? params.id : params.propertyId; 
    const hostId = (params.hostId) ? params.hostId : 0; 
    useEffect(() => {
      getHostList();
      fetchPropertDetails();
      updateNotificationReadStatus();
    }, []);

    // Fetch Host Details
    const fetchPropertDetails = async () => {
        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'property/property-detail', {
            id: propertyId.trim(),
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        });
      
        if(data.status === 1){
          setExistingState(data.data)
        }else{
            navigate('/host-list');
        }
    };

    const getHostList = () => {
        axios.get(process.env.REACT_APP_BACKENDURL+'user/get-host', {
            params: {
                limit: 10000
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                setHostList(response.data.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const updateNotificationReadStatus = () => {
        axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-notification-status',
        {
            role_id: 1,
            redirect_id: propertyId.trim(),
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
    }

    const setExistingState = (datas) => {
      // Basic information
      basicDetailArray['id'] = datas._id;
      basicDetailArray['hostId'] = datas.hostId;
      basicDetailArray['propertyName'] = datas.propertyName;
      basicDetailArray['type'] = datas.type;
      basicDetailArray['subType'] = datas.subType;
      basicDetailArray['accessibility'] = datas.accessibility;
      basicDetailArray['description'] = datas.description;
      setBasicDetailArray({ ...basicDetailArray });

      // Additional Information
      additionalArray['bed'] = datas.bed;
      additionalArray['bedrooms'] = datas.bedrooms;
      additionalArray['bathroom'] = datas.bathroom;
      additionalArray['kitchen'] = datas.kitchen;
      additionalArray['kitchen'] = datas.kitchen;
      additionalArray['garage'] = datas.garage;
      setAdditionalArray({ ...additionalArray });
      // Address Array
      addressArray['fullAddress'] = datas.fullAddress;
      addressArray['city'] = datas.city;
      addressArray['state'] = datas.state;
      addressArray['pincode'] = datas.pincode;
      addressArray['country'] = datas.country;
      addressArray['streetAddress'] = datas.streetAddress;
      addressArray['lat'] = Number(datas.lat);
      addressArray['lng'] = Number(datas.lng);
      
      setAddressArray({ ...addressArray });

      // setUploadedFiles
      setUploadedFiles(datas.images);
      setUploadedInstructionImages(datas.accessibilityImages);
    //   setCheckList(datas.checklist);

    // set size state
      setSelectedSizeType(datas.sizeType)
      setPropertySize(datas.size)

      setStep1Validation(true);
      setStep2Validation(true);
      setStep3Validation(true);
      setStep4Validation(true);
    //   setStep5Validation(true);
    }

    const [hostList, setHostList] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    
    // eslint-disable-next-line no-unused-vars
    const [pageName, setPageName] = useState('edit property');

    const [selectedSizeType, setSelectedSizeType] = useState('m');
    const [propertySize, setPropertySize] = useState(100);
    const [instructionImages, setInstructionImages] = useState([]);
    const [instructionImagesPreview, setInstructionImagesPreview] = useState([]);
    const [uploadedInstructionImages, setUploadedInstructionImages] = useState([]);

    useEffect(() => {
        setStep2Validation(true);
        if(propertySize <= 0 || propertySize === ""){
            setStep2Validation(false);
        }
        if(selectedSizeType === ""){
            setStep2Validation(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertySize,selectedSizeType])

    // const [title, setTitle] = useState("");
    // const [checkListArray, setCheckList] = useState([]);
    // const [errorTitle, setFNErrorMessage] = useState("");
    // const [updateState, setUpdateState] = useState({index:-1, title: "", currentState: "add"})

    const stepNames = helper.propertyWizardSteps;
    
    const types = helper.types;

    const subTypes = helper.subTypes;

    const [ basicDetailArray, setBasicDetailArray ] = useState({
            id: "",
            hostId: "",
            type: 1,
            subType: 1,
            propertyName: '',
            description: '',
            accessibility: ''
        })

    const [ addressArray, setAddressArray ] = useState({
        fullAddress: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
        streetAddress: "",
        lat: 37.09024,
        lng: -95.712891
    })
    
    const [additionalArray, setAdditionalArray] = useState({ 
                                                              bedrooms: 0 , 
                                                              bed: 0, 
                                                              bathroom: 0, 
                                                              kitchen: 0, 
                                                              garage: 0 
                                                            })
    const [files, setFiles] = useState([]);
    // Step 2, Manage Additional information state
    // ****************************************************
    const additionlFields = helper.additionlFields;
    

    const handleOnChange = e => {
        const { name, value } = e.target;
        setAdditionalArray({ [name]: parseInt(value) });
    };

    // const DecreaseItem = (name) => {
    //     setAdditionalArray(prev => ({ ... formField, [name]: parseInt(prev[name]) - 1 }));
    // }

    const handleAdditionalItemsValue = (name, value) => {
        additionalArray[name] = value;
        setAdditionalArray({ ...additionalArray });        
    }

    // ****************************************************
    // Need to check all is required
    const [step1Validation, setStep1Validation] = useState(false);
    const [step2Validation, setStep2Validation] = useState(false);
    const [step3Validation, setStep3Validation] = useState(false);
    const [step4Validation, setStep4Validation] = useState(false);
    // const [step5Validation, setStep5Validation] = useState(false);

    // Handle basic detail form state callback
    const handleBasicDetailCallback = ( keyValue, value) => {
        setStep1Validation(true)
        basicDetailArray[keyValue] = value
        setBasicDetailArray({ ...basicDetailArray })
        // eslint-disable-next-line array-callback-return
        Object.keys(basicDetailArray).map(key => {
            if(basicDetailArray[key] === ""){
                setStep1Validation(false)
            }
        })        
    }
    // Handle Address form state callback
    const handleAddressCallback = ( keyValue, value) => {
        setStep3Validation(true)
        addressArray[keyValue] = value
        setAddressArray({ ...addressArray })
        Object.keys(addressArray).map(key => {
            if(addressArray[key] === "" ){
                setStep3Validation(false)
            }
        })
    }

    const submitForm = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        Object.keys(additionalArray).map((x,i) => {
            formData.append(x, additionalArray[x]);
        })
        Object.keys(basicDetailArray).map((x,i) => {
            formData.append(x, basicDetailArray[x]);
        })
        Object.keys(addressArray).map((x,i) => {
            formData.append(x, addressArray[x]);
        })
        selectedFiles.forEach((images, index) => {
            formData.append(`images`, images);
        });
        instructionImages.forEach((accessibilityImages, index) => {
            formData.append(`accessibilityImages`, accessibilityImages);
        });

        formData.append(`sizeType`,selectedSizeType)
        formData.append(`size`,propertySize)
        formData.append(`addedBy`,decoded.user_id)
        // checkListArray.forEach((checklist, index) => {
        //     formData.append(`checklist`, checklist);
        // });
       
        let i = 1;
        uploadedFiles.forEach((uploadedFile, index) => {
            if( (uploadedFile !== null)  && uploadedFile.filename !== "" && uploadedFile.filename !== null ){
                Object.keys(uploadedFile).forEach((ind, val) => {
                    formData.append(`uploadedFiles[${i}][${ind}]`, uploadedFile[ind]);
                });
            }
            i++;
        });

        let j = 1;
        uploadedInstructionImages.forEach((uploadedInsFile, index) => {
            if( (uploadedInsFile !== null)  && uploadedInsFile.filename !== "" && uploadedInsFile.filename !== null ){
                Object.keys(uploadedInsFile).forEach((ind, val) => {
                    formData.append(`uploadedInstructionImages[${j}][${ind}]`, uploadedInsFile[ind]);
                });
            }
            j++;
        });

        props.setLoading(true);
        axios.post(process.env.REACT_APP_BACKENDURL+'property/update-property',formData)
        .then(function (response) {
            props.setLoading(false);
            if(response.data.status === 1){
                if(hostId !== 0){
                    navigate('/host-propertlist'/hostId);
                }else{
                    navigate('/property-list');
                }
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
            }else{
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false);
            console.log(error);
        });
    };

    return (
        <div className="page-content">
            <div className="container-fluid">


                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Edit Property</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    {(hostId !== 0) ?
                                        <>
                                            <li className="breadcrumb-item"><Link to="/host-list">Host List</Link></li>
                                            <li className="breadcrumb-item"><Link to={"/host-edit/"+hostId}>Host Detail</Link></li>
                                            <li className="breadcrumb-item"><Link to={"/host-propertlist/"+hostId}>Property List</Link></li>
                                            <li className="breadcrumb-item active">Edit Property</li>
                                        </>
                                        :
                                        <>
                                            <li className="breadcrumb-item"><Link to="/property-list">Property List</Link></li>
                                            <li className="breadcrumb-item active">Edit Property</li>
                                        </>
                                    }
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <form onSubmit={submitForm} method="post" encType="multipart/form-data">
                        <PropertyContext.Provider value={{types, subTypes, stepNames}}>
                        <Wizard >
                            <BasicDetail 
                                parentCallback={handleBasicDetailCallback}
                                basicDetail={basicDetailArray}
                                step1Validation={step1Validation}
                                hostList={hostList}
                                currentStep="Basic Details"
                                setInstructionImages={setInstructionImages}
                                instructionImages={instructionImages}
                                setInstructionImagesPreview={setInstructionImagesPreview}
                                instructionImagesPreview={instructionImagesPreview} 
                                setUploadedInstructionImages={setUploadedInstructionImages} 
                                uploadedInstructionImages={uploadedInstructionImages}
                                pageName={pageName}
                            />

                            <AdditionalInformation 
                                selectedSizeType={selectedSizeType}
                                setSelectedSizeType={setSelectedSizeType}
                                propertySize={propertySize}
                                setPropertySize={setPropertySize}
                                handleAdditionalItemsValue={handleAdditionalItemsValue}
                                handleOnChange={handleOnChange}
                                formField={additionalArray}
                                step2Validation={step2Validation}
                                additionlFields={additionlFields}/>

                            <Address 
                                
                                addressArray={addressArray}
                                step3Validation={step3Validation}
                                parentCallback={handleAddressCallback}/>

                            <Images 
                                setFiles={setFiles}
                                files={files}
                                selectedFiles={selectedFiles} 
                                setSelectedFiles={setSelectedFiles}
                                setStep4Validation={setStep4Validation}
                                pageName={pageName}
                                uploadedFiles={uploadedFiles}
                                setUploadedFiles={setUploadedFiles}
                                step4Validation={step4Validation} />


                            {/* <Checklist 
                                title={title}
                                setTitle={setTitle}
                                checkListArray={checkListArray}
                                setCheckList={setCheckList}
                                errorTitle={errorTitle}
                                setFNErrorMessage={setFNErrorMessage}
                                updateState={updateState}
                                setUpdateState={setUpdateState}
                                step5Validation={step5Validation}
                                setStep5Validation={setStep5Validation}
                                pageName={pageName} /> */}

                            <Preview 
                                // checkListArray={checkListArray}
                                additionalArray={additionalArray}
                                basicDetailArray={basicDetailArray}
                                addressArray={addressArray}
                                pageName={pageName}
                                uploadedFiles={uploadedFiles}
                                files={files}
                                uploadedInstructionImages={uploadedInstructionImages} 
                                instructionImages={instructionImages}
                            />

                        </Wizard>
                        </PropertyContext.Provider>
                        </form>
                    </div>
                    
                </div>


                
            </div>
        </div>
    )
}

export default EditProperty;
