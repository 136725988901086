import React, {useState, useEffect} from 'react'
import Cookies from 'js-cookie';
import { ResponsiveBar } from '@nivo/bar'
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
// import { BarChart } from '@mui/x-charts/BarChart';
import Breadcrump from './component/breadcrump'

const Earning = (props) => {

    let params = useParams()
    let hostId = params.hostId

    const token = Cookies.get('token');
    const [earningData, setEarningData] = useState({
      "totalBookingsByMonth": [],
      "totalPayout": [
        {
          "totalEarning": 0
        }
      ],
      "completedAssignmentsCount": 0,
      "totalEnquires": 0,
      "currentYearPayout":  [
        {
          "totalEarning": 0
        }
      ],
      "year": 0
  })

  const collectData = async() => {
      try {
          await axios.post(process.env.REACT_APP_BACKENDURL+'api/host/insight/earning', 
          {'from':  'admin','userId': hostId},
          {
              headers: {
                  'Content-Type': 'application/json',
                  'token': token
              }
          }).then((response) => {
              if(response.data.status === 1) {
                  setEarningData(response.data.data)
              }
          })
          .catch((error) => {
              console.error("Error: Something went wrong", error);
          });

      } catch (error) {
          
      }
  }

  useEffect(() => {
      collectData()
  }, [])

  return (
    <div className="page-content">
        <div className="container-fluid">

            <Breadcrump name="Payouts" isInsight={true} hostId={hostId} />

            <div className="card-body">
                <div className="row earning-detail-boxs">

                    {/* Current year earning */}
                    <div className="col-xl-6 col-md-6">
                        <div className="card card-animate">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Current Year Payout</p>
                                        <h6 className="earning-time ">in 2024</h6>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                        <span className="counter-value" data-target="119.25">${earningData.currentYearPayout[0].totalEarning}</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* All Total Earnings by Today */}
                    <div className="col-xl-6 col-md-6">
                        <div className="card card-animate">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Total Payed Out YTD</p>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                        <span className="counter-value" data-target="119.25">${earningData.totalPayout[0].totalEarning}</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
              <div className="row">
                <div className="col-xxl-12 col-md-12">
                    <div className="card">

                        <div className="card-header align-items-center d-flex justify-content-between">
                            <div className="d-flex align-items-start flex-column ">
                                <h4 className="card-title mb-0 flex-grow-1">Payout's by month in year {earningData.year}</h4>                                
                            </div>

                        </div>

                        <div className="col-md-12">
                            <div className="card card-animate">
                                <div className="card-body" style={{'height': '50vh'}}>
                                    <ResponsiveBar
                                        data={earningData.totalBookingsByMonth}
                                        keys={[
                                            'total'
                                        ]}
                                        indexBy="month"
                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                        padding={0.3}
                                        valueScale={{ type: 'linear' }}
                                        indexScale={{ type: 'band', round: true }}
                                        // colors={{ scheme: 'blueColor' }}
                                        colors={['#8f368d','#000080']}
                                        axisTop={null}
                                        axisRight={null}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        labelTextColor="white"
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: '',
                                            legendPosition: 'middle',
                                            legendOffset: -40,
                                            truncateTickAt: 0,
                                            format: (e) => (Math.floor(e) === e ? `$${e}` : "")
                                        }}
                                        legends={[
                                            {
                                                dataFrom: 'keys',
                                                anchor: 'bottom-right',
                                                direction: 'column',
                                                justify: false,
                                                translateX: 120,
                                                translateY: 0,
                                                itemsSpacing: 2,
                                                itemWidth: 100,
                                                itemHeight: 20,
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 0.85,
                                                symbolSize: 20,
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemOpacity: 1
                                                        }
                                                    }
                                                ]
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    </div>    
  )
}

export default Earning;
