import React, { useState, useEffect } from 'react'
import ProgressBar from '../../../components/common/ProgressBar'
import {  useWizard } from 'react-use-wizard';
import parse  from 'html-react-parser';
import { useContext } from 'react';
import { PropertyContext } from '../../../Contexts/PropertyContext';
import helper from '../../../helper/index.js'
import FsLightbox from "fslightbox-react";
const Preview = (props) => {
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
    const [instructionImagesArray, setInstructionImagesArray] = useState([]);

    function openLightboxOnSlide(number) {
        setLightboxController({
          toggler: !lightboxController.toggler,
          slide: number
        });
    }
    
    useEffect(() => {
        let imgurl = [];
        let path = process.env.REACT_APP_BACKENDURL+'images/';
        if(props.uploadedInstructionImages.length > 0) {
          props.uploadedInstructionImages.map((img, i) => (
              imgurl.push(path+img.filename)
          ));
        }

        if(props.instructionImages.length > 0) {
            props.instructionImages.map((img, i) => (
                imgurl.push(img.preview)
            ));
        }
        setInstructionImagesArray(imgurl);
      }, [props.uploadedInstructionImages, props.instructionImages])

    const {types, subTypes} = useContext(PropertyContext);
    const { previousStep } = useWizard();
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };
      
    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    };
    
    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };
    
    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
    };

    const imgContainer = {
        height: '90px',
        width: '150px'
    }
    
    const imgStyle = {
        height: '100%',
        width: '100%',
        objectFit: 'cover'
    }

  return (
    <>
    <ProgressBar/>
    <div className="row gx-lg-5">


        <div className="col-xl-12">

            <div className='row'>
                {/* Basic Information */}
                <div className='col-md-8'>
                    <div className="card">
                        <div className="card-header">
                            <h6 className="card-title mb-0">Basic Information</h6>
                        </div>
                        <div className="card-body">
                            <div className="mt-4 text-muted">
                                <h5 className="fs-14">Property Name :</h5>
                                <p>{props.basicDetailArray.propertyName}</p>
                            </div>

                            <div className="mt-4 text-muted">
                                <h5 className="fs-14">Property Description :</h5>
                                {parse(props.basicDetailArray.description)}
                            </div>

                            <div className="mt-4 text-muted">
                                <h5 className="fs-14">Which of these best describes your place? :</h5>
                                <p>{ types[(props.basicDetailArray.type - 1)].name }</p>
                            </div>
                            <div className="mt-4 text-muted">
                                <h5 className="fs-14">Which of the following descriptions suites you place most? :</h5>
                                <p>{ subTypes[(props.basicDetailArray.subType - 1)].name }</p>
                            </div>
                            <div className="mt-4 text-muted">
                                <h5 className="fs-14">Cleaning Duration :</h5>
                                <p>{props.basicDetailArray.duration}</p>
                            </div>
                            <div className="mt-4 text-muted">
                                <h5 className="fs-14">Property Accessibility :</h5>
                                <p>{props.basicDetailArray.accessibility}</p>
                            </div>
    
                            {(instructionImagesArray.length > 0) &&
                                <div className='row'>
                                    <div className='col-12'><h5 className="fs-14">Property Accessibility Images:</h5></div>
                                    {instructionImagesArray.map((img, i) => (
                                        <div className='col-4 mb-1' style={imgContainer} key={i}>
                                            <img src={img} alt={img} style={imgStyle} onClick={() => openLightboxOnSlide(i+1)} />
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        {(instructionImagesArray.length > 0) &&
                            <FsLightbox
                            toggler={lightboxController.toggler}
                            sources={instructionImagesArray}
                            slide={lightboxController.slide}
                            />
                        }                                
                    </div>
                </div>

                {/* Additional Information */}
                <div className='col-md-4'>
                    <div className="card">
                        <div className="card-header">
                            <h6 className="card-title mb-0">Additional Information</h6>
                        </div>
                        <div className="card-body">
                        <table className="table mb-0">
                        <tbody>
                            {Object.keys(props.additionalArray).map((x, i) =>
                                <tr key={x}>
                                    <th scope='row'>{helper.captilizeString(x)}</th>
                                    <td>{props.additionalArray[x]}</td>
                                </tr>
                            )}
                        </tbody>
                        </table>
                        </div>
                    </div>
                </div>

                 {/* Address Information */}
                 <div className='col-md-8'>
                    <div className="card">
                        <div className="card-header">
                            <h6 className="card-title mb-0">Address Information</h6>
                        </div>
                        <div className="card-body">
                            <table className="table mb-0">
                                <tbody>
                                    {Object.keys(props.addressArray).map((x, i) => 
                                    <tr key={x}>
                                        <th scope="row" style={{ 'width': '200px' }}>{helper.captilizeString(x)}</th>
                                        <td>{props.addressArray[x]}</td>
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* Additional Information */}
{/* 
                <div className='col-md-4'>
                    <div className="card">
                        <div className="card-header">
                            <h6 className="card-title mb-0">Checklist</h6>
                        </div>
                        <div className="card-body">

                        <ul class="list-group">
                            {props.checkListArray.map((name, index) =>
                                <li class="list-group-item">{name}</li>
                            )}
                        </ul>
                        </div>
                    </div>
                </div> */}

                <div className='col-md-12'>
                    <div className="card">
                        <div className="card-header">
                            <h6 className="card-title mb-0">Property Images</h6>
                        </div>
                        <div className="card-body">
                        <aside style={thumbsContainer}>
                            {props.files.length > 0 &&
                            props.files.map((image, index) => (
                                <div style={thumb} key={image.name}>
                                    <div style={thumbInner}>
                                        <img src={`${URL.createObjectURL(image)}`} style={img} key={index} alt={image.name} />
                                    </div>
                                </div>
                            ))}
                            {(props.pageName === "edit property" && props.uploadedFiles && props.uploadedFiles.length > 0) ? 
                            props.uploadedFiles.map((img, i) => (
                            <div style={thumb} key={img.filename}>
                                <div style={thumbInner}>                          
                                    <img src={process.env.REACT_APP_BACKENDURL+'images/'+img.filename} style={img} key={i} alt={img.filename} />
                                </div>
                            </div>
                            ))
                            : <></>}
                        </aside>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <button className="btn btn-success me-2" onClick={() => previousStep()}>Previous</button>
                    <button className="btn btn-success me-2" type='submit'>Submit</button>
                </div>

            </div>
        </div>

    </div>
    </>
  )
}

export default Preview;