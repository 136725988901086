import React,{useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import WithPermission from '../../components/WithPermission';
import Cookies from 'js-cookie';
import { decodeToken  } from "react-jwt";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { socket } from '../../socket';
import helper from '../../helper';
import Address from '../../components/Address';
import { updateUserName } from '../../helper/fs-common.js';
import FsLightbox from "fslightbox-react";

const EditCleaner = (props) => {
    // const fullurl =  window.location.href;
    // const segment = fullurl.split("/").pop(); 

    const token = Cookies.get('token');
    const decoded = decodeToken(token);
    const params = useParams();
    const cleanerId = params.id;
    const segment = params.id;

    const [authData, setAuthData] = useState({
        first_name : '',
        last_name : '',
        phone_number : '',
        email : '',
        dob : new Date(),
        languages: '',
        bio: '',
        image: {},
        describeBest: '',
        experience: '',
        shortTermRental: '',
        status: 0,
        isApproved: 0,
    });

    const [authValidations, setAuthValidations] = useState({
        first_name : '',
        last_name : '',
        phone_number : '',
        email : '',
        dob : '',
        languages: '',
        bio: '',
        image: '',
        describeBest: '',
        experience: '',
        shortTermRental: '',
        isImageUploaded: ''
    });

    const placeHolderImage = "/assets/images/Placeholder/document-card.png";
    const [documentType, setDocumentType] = useState('passport');
    const [passportFrontImage, setPassportFrontImage] = useState(placeHolderImage);
    const [drivingFrontImage, setDrivingFrontImage]   = useState(placeHolderImage);
    const [drivingBackImage, setDrivingBackImage]     = useState(placeHolderImage);
    const [website, setWebsite] = useState("");
    const [percentage, setPercentage] = useState(0);
    const [isIdentityDocumentUploaded, setIsIdentityDocumentUploaded] = useState(0);

    const [docValidation, setDocValidation] = useState({
        social_security_number: "",
        website: "",
        tax_payer_name: "",
        front_image: "",
        back_image: "",
        isFrontPassImgUploaded: 0,
        isFrontDrivingImgUploaded: 0,
        isBackDrivingImgUploaded: 0,
    });
    
    const [tax_payer_id, setTaxPayerId] = useState("");
    const [tax_payer_name, setTaxPayerName] = useState("");
    const [social_security_number, setSocialSecurityNumber] = useState("");

    const [type, setType] = useState('password');
    const [confirmType, setConfirmType] = useState('password');

    /**
     * Manage Radio Iputs
     * */ 
    const RadioInput = (props) => {
        return (
            <>
                <label className="list-group-item">
                    <input 
                        className="form-check-input me-1" 
                        name="describeBest" 
                        type="radio" 
                        checked={(props.value === authData.describeBest)}
                        onChange={(e) => {manageTabData('describeBest', e.target.value)}}
                        value={props.value}/>
                        {props.value}
                </label>
            </>
        )
    }

    const manageCheckBox = (e) => {
        if(e.target.checked){
            authData['shortTermRental'] = 'yes';
        }else{
            authData['shortTermRental'] = 'no';
        }
        setAuthData({...authData})
    }


    function handleFileChange(e,type) {
        switch(type) {
            case '0':
                authValidations['isImageUploaded'] = 1;
                authData['image'] = URL.createObjectURL(e.target.files[0]);
                setAuthValidations({ ...authValidations });
                return setAuthData({ ...authData });
                //return setProfileImage(URL.createObjectURL(e.target.files[0]));
            case '1':
                docValidation['isFrontPassImgUploaded'] = 1;
                setDocValidation({ ...docValidation });
                return setPassportFrontImage(URL.createObjectURL(e.target.files[0]));
            case '3':
                docValidation['isFrontDrivingImgUploaded'] = 1;
                setDocValidation({ ...docValidation });
                return setDrivingFrontImage(URL.createObjectURL(e.target.files[0]));
            case '4':
                docValidation['isBackDrivingImgUploaded'] = 1;
                setDocValidation({ ...docValidation });
                return setDrivingBackImage(URL.createObjectURL(e.target.files[0]));
            default:
              //default code goes here
        }
    }


    const getAddressFromPlace = (place) => {
        addressArray['lat'] = place.geometry.location.lat()
        addressArray['lng'] = place.geometry.location.lng()
        addressArray['fullAddress'] = place.name
        addressArray['streetAddress'] = place.name
        for (var j = 0; j < place.address_components.length; j++) {
            for (var k = 0; k < place.address_components[j].types.length; k++) {

                if (place.address_components[j].types[k] === "locality") {
                    addressArray['city'] = place.address_components[j].short_name
                }
                if (place.address_components[j].types[k] === "postal_code") {
                    addressArray['pincode'] = place.address_components[j].short_name
                }
                if (place.address_components[j].types[k] === "country") {
                    addressArray['country'] = place.address_components[j].long_name
                }
                if (place.address_components[j].types[k] === "administrative_area_level_1") {
                    addressArray['state'] = place.address_components[j].long_name
                }
            }
        }
        setAddressArray({...addressArray})
    }

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorNewPassword, setNewPassMessage] = useState("");
    const [errorConfirmPass, setConfPassMessage] = useState("");

    const [ addressArray, setAddressArray ] = useState({
        fullAddress: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
        streetAddress: "",
        lat: 37.09024,
        lng: -95.712891
    })

    const [validateAddress, setValidateAddress] = useState({
        fullAddress: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
        streetAddress: "",
    })

    const [is_profile_completed, setIsProfileCompleted] = useState({});
    const [profileErrorFields, setProfileErrorFields] = useState("");
    const [profileCompleteStatus, setProfileCompleteStatus] = useState(0);
    const [activeStep, setActiveStep] = useState(1);
    const [stepKey, setStepKey] = useState(1);
    const [SSNFocus, setSSNFocus] = useState(false);
    const [documentVerificationError, setDocumentVerificationError] = useState(false);
    const [urlArr, setUrlArr] = useState([
        '/cleaner/payments-and-transactions/bank-details',
        '/cleaner/profile',
        '/cleaner/payments-and-transactions'
    ]);
    const errorDot = {
        'background':'#f06548',
        'borderRadius': '100%',
        'width': '10px',
        'position': 'relative',
        'paddingTop': '10px'
    }

    const [ documentImgArr, setDocumentImgArr ] = useState([]);
    const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	});

    // eslint-disable-next-line no-unused-vars
    const [place, setPlace] = React.useState(null)

    const updateNotificationReadStatus = () => {
        axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-notification-status',
        {
            role_id: 1,
            redirect_id: cleanerId.trim(),
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        }).then((result) => {
            if(result.data.status === 1) {
                socket.emit('send-notification-signal-to-admin',1)
            }
        });
    }

    useEffect(() => {
        if(place !== null && place !== undefined && place.geometry !== undefined){
            getAddressFromPlace(place);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[place])

    useEffect(() => {
        let data = fetchCleanerDetails()
        setAuthData(data);
        updateNotificationReadStatus();
        getProfileStatus();
    }, [])

    useEffect(() => {
        const ptype = (params.step) ? params.step : null;
        setStepKey(stepKey + 1);
        if(ptype !== null){
            if(ptype === "personalDetails"){
                setActiveStep(1);
            }else if(ptype === "address"){
                setActiveStep(2);
            }else if(ptype === "documentVerification"){
                setActiveStep(3);
            }
        }
    }, [params])

    useEffect(() => {
        setDocumentVerificationError(false);
        setSSNFocus(false);
        if(profileErrorFields === "SSN") {
            setSSNFocus(true);
            setDocumentVerificationError(true);
        }
    }, [profileErrorFields])

    const fetchCleanerDetails = async () => {

        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'user/cleaner-detail', {
            id: segment.trim(),
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if(data.status === 1){
            setExistingState(data.data);
            //setPercentage(data.percentage);
        }
    };

    const setExistingState = (data) => {
        let {first_name, last_name, phone_number, email, dob, status, isApproved } = data.userData;
        let {school} = data;

        //set general details
        authData['first_name'] = first_name;
        authData['last_name'] = last_name;
        authData['phone_number'] = phone_number;
        authData['email'] = email;
        authData['dob'] = (typeof dob !== "undefined") ? new Date(dob) : "";
        authData['describeBest'] = data.describeBest;
        authData['experience'] = data.experience;
        authData['shortTermRental'] = data.shortTermRental;
        authData['languages'] = data.languages;
        authData['bio'] = data.bio;
        authData['status'] = status;
        authData['isApproved'] = isApproved;
        authData['school'] = school;
        
        authData['image'] = (data.profile_image !== null) ? data.profile_image : '/assets/images/image-placeholder.png';
        setAuthData({...authData})

        authValidations['isImageUploaded'] = (Object.keys(data.image).length !== 0) ?  1 : 0;
        setAuthValidations({...authValidations})


        addressArray['fullAddress'] = data.address.fullAddress
        addressArray['city'] = data.address.city
        addressArray['state'] = data.address.state
        addressArray['pincode'] = data.address.pincode
        addressArray['country'] = data.address.country
        addressArray['streetAddress'] = data.address.streetAddress
        addressArray['lat'] = data.address.lat
        addressArray['lng'] = data.address.lng
        setAddressArray({...addressArray})

        setSocialSecurityNumber(data.ssn_no);
        setWebsite(data.website);
        setTaxPayerId(data.taxPayerId);
        setTaxPayerName(data.businessName);

        setPassportFrontImage((Object.keys(data.passport.frontImage).length !== 0) ? process.env.REACT_APP_BACKENDURL+"images/"+data.passport.frontImage.filename : placeHolderImage);
        setDrivingFrontImage((Object.keys(data.drivingLicense.frontImage).length !== 0) ? process.env.REACT_APP_BACKENDURL+"images/"+data.drivingLicense.frontImage.filename : placeHolderImage);
        setDrivingBackImage((Object.keys(data.drivingLicense.backImage).length !== 0) ? process.env.REACT_APP_BACKENDURL+"images/"+data.drivingLicense.backImage.filename : placeHolderImage);
        
        if(data.documentType !== ''){
            setDocumentType(data.documentType);
        }

        if( (Object.keys(data.passport.frontImage).length !== 0) || (Object.keys(data.drivingLicense.frontImage).length !== 0) ){
            setIsIdentityDocumentUploaded(1)
        }

        let tempArr = [];
        if(data.documentType === "passport"){
            if(Object.keys(data.passport.frontImage).length !== 0){
                docValidation['isFrontPassImgUploaded'] = 1;
                setDocValidation({ ...docValidation });
                tempArr.push(process.env.REACT_APP_BACKENDURL+"images/"+data.passport.frontImage.filename);
            }

            // if(Object.keys(data.passport.frontImage).length !== 0){
            //     docValidation['isBackPassImgUploaded'] = 1;
            //     setDocValidation({ ...docValidation });
            // }
        }else if(data.documentType === "driving_licence"){

            if(Object.keys(data.drivingLicense.frontImage).length !== 0){
                docValidation['isFrontDrivingImgUploaded'] = 1;
                setDocValidation({ ...docValidation });
                tempArr.push(process.env.REACT_APP_BACKENDURL+"images/"+data.drivingLicense.frontImage.filename);
            }

            if(Object.keys(data.drivingLicense.backImage).length !== 0){
                docValidation['isBackDrivingImgUploaded'] = 1;
                setDocValidation({ ...docValidation });
                tempArr.push(process.env.REACT_APP_BACKENDURL+"images/"+data.drivingLicense.backImage.filename);
            }
        }
        setDocumentImgArr(tempArr);
    }

    const PasswordUpdate = async (event) => {
        event.preventDefault();

        setNewPassMessage("");
        setConfPassMessage("");
        let error = 0;
        if(newPassword.trim().length === 0) {
            setNewPassMessage('Password field is required.');
            error = 1;
        }

        if(confirmPassword.trim().length === 0) {
            setConfPassMessage('Confirm Password field is required.');
            error = 1;
        }else{
            if(newPassword.trim() !== confirmPassword.trim()){
                setConfPassMessage('Confirm Password is not matched with password field.');
                error = 1;
            }
        }

        if(error === 1){
            return 0;
        }

        axios.post(process.env.REACT_APP_BACKENDURL+'user/password-update', {
            new_password: newPassword.trim(),
            confirm_password: confirmPassword.trim(),
            id: segment.trim(),
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                helper.Toast().fire({ icon: 'success', title: response.data.message });
                setNewPassword("");
                setConfirmPassword("");
            }else{
                helper.Toast().fire({ icon: 'error', title: response.data.message });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    function capitalizeFirstLetter(str) {
        return str.trim().charAt(0).toUpperCase() + str.slice(1);
    }

    const manageAuthState = (e) => {
        let keyValue = e.target.name;
        let value = '';
        if(keyValue === 'first_name' || keyValue === 'last_name') {
            value = capitalizeFirstLetter(e.target.value);
        } else {
            value = e.target.value;
        }
        authData[keyValue] = value
        setAuthData({ ...authData })
    }

    const managePhone = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        let keyValue = e.target.name;
        authData[keyValue] = value
        setAuthData({ ...authData })
    }

    const manageTabData = (key, value) => {
        authData[key] = value;
        setAuthData({...authData})
    }


    // Handle Address form state callback
    const handleAddressCallback = ( keyValue, value) => {
        addressArray[keyValue] = value
        setAddressArray({ ...addressArray })
    } 
    
    
    const manageDocType = (value) => {
        setDocumentType(value)
    }

    const submitPersonalData = async (event) => {
        event.preventDefault();

        authValidations['first_name'] = '';
        authValidations['last_name'] = '';
        authValidations['phone_number'] = '';
        authValidations['email'] = '';
        authValidations['dob'] = '';
        authValidations['languages'] ='';
        authValidations['bio'] ='';
        authValidations['image'] ='';
        authValidations['describeBest'] ='';
        authValidations['experience'] ='';
        authValidations['shortTermRental'] ='';

        setAuthValidations({ ...authValidations })

        let error = 0;
        if (authData.first_name.trim().length === 0) {
            authValidations['first_name'] ='First Name field is required.';
            error = 1;
        }
        if (authData.last_name.trim().length === 0) {
            authValidations['last_name'] = 'Last Name field is required.';
            error = 1;
        }

        // if (authData.email.trim().length === 0) {
        //     authValidations['email'] = 'Email Address field is required.';
        //     error = 1;
        // }else{
        //     // check email address in valid
        //     let vEmail = String(authData.email)
        //     .toLowerCase()
        //     .match(
        //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //     );
            
        //     if(vEmail === null){
        //         authValidations['email'] = 'Email Address is invalid.';
        //         error = 1;
        //     }
        // }

        if (authData.phone_number.trim().length === 0) {
            authValidations['phone_number'] = 'Phone Number field is required.';
            error = 1;
        }else if((authData.phone_number.trim().length !== 10)){
            authValidations['phone_number'] = 'Phone Number is invalid.';
            error = 1;
        }

        if (authData.dob === null) {
            authValidations['dob'] = 'Date of birth field is required.';
            error = 1;
        }else if(moment().diff(moment(authData.dob), 'years') < 18){
            authValidations['dob'] = 'The minimum age requirement is 18 years or older.';
            error = 1;
        } else if(moment().diff(moment(authData.dob), 'years') > 100) {
            authValidations['dob'] = 'Date of birth should not be greater than 100 years';
            error = 1;
        } else if(!helper.isDateFormatCorrect(moment(authData.dob).format('MM/DD/YYYY'), "MM/DD/YYYY")) {
            authValidations['dob'] = 'Date of birth is not correct';
            error = 1;
        }

        if (authData.languages.trim().length === 0) {
            authValidations['languages'] = 'Language field is required.';
            error = 1;
        }

        // if (authData.bio.trim().length === 0) {
        //     authValidations['bio'] = 'About/Bio field is required.';
        //     error = 1;
        // }

        if (authData.experience.trim().length === 0) {
            authValidations['experience'] = 'Experience selection is required.';
            error = 1;
        }

        if (authData.describeBest.trim().length === 0) {
            authValidations['describeBest'] = 'Describe Best selection is required.';
            error = 1;
        }

        // if(authValidations.isImageUploaded === 0){
        //     authValidations['image'] = "Image field is required.";
        //     error = 1;
        // }

        if (error === 1) {
            setAuthValidations({ ...authValidations })
            return;
        }

        const formData = new FormData(event.target); // Some date append from inputs
        formData.append(`shortTermRental`,authData.shortTermRental);
        formData.append(`id`,segment.trim());
        formData.append(`added_by`, decoded.user_id);

        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'user/update-cleaner',formData,{
            headers: {
                'token': token,
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                getProfileStatus();
                const firestoreData = {
                    'name': authData.first_name+' '+authData.last_name,
                    'email': authData.email
                }
                updateUserName(firestoreData);
                props.setLoading(false)
                setPercentage(response.data.percentage);
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
                
            }else{
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
            console.log(error);
        });
    };

    const submitAddressData = async (event) => {

        event.preventDefault();

        validateAddress['fullAddress'] = '';
        validateAddress['city'] = '';
        validateAddress['state'] = '';
        validateAddress['pincode'] = '';
        validateAddress['country'] = '';
        validateAddress['streetAddress'] = '';
        setValidateAddress({ ...validateAddress })

        let error = 0;
        if (addressArray.city.trim().length === 0) {
            validateAddress['city'] = "City field is required.";
            error = 1;
        }

        if (addressArray.state.trim().length === 0) {
            validateAddress['state'] = "State field is required.";
            error = 1;
        }

        if (addressArray.pincode.trim().length === 0) {
            validateAddress['pincode'] = "Pincode field is required.";
            error = 1;
        }

        if (addressArray.country.trim().length === 0) {
            validateAddress['country'] = "Country field is required.";
            error = 1;
        }

        if (addressArray.streetAddress.trim().length === 0) {
            validateAddress['streetAddress'] = "Street address field is required.";
            error = 1;
        }

        if(error === 1){
            setValidateAddress({...validateAddress });
            return false;
        }

        const formData = new FormData(event.target);
        formData.append(`lat`,addressArray.lat);
        formData.append(`lng`,addressArray.lng);
        formData.append(`fullAddress`,addressArray.fullAddress);
        formData.append(`id`,segment.trim());

        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'user/update-address-details',formData,{
            headers: {
                'token': token,
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                getProfileStatus();
                props.setLoading(false);
             
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
            }else{
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
            console.log(error);
        });
    }
  
    /* Submit identity proof code start */
    const submitIdentityProofData = async (event) => {
        event.preventDefault();

        docValidation['social_security_number'] = "";
        docValidation['website'] = "";
        docValidation['tax_payer_name'] = "";
        docValidation['front_image'] = "";
        docValidation['back_image'] = "";
        setDocValidation({...docValidation });

        let error = 0;
        if (social_security_number.trim().length === 0) {
            docValidation['social_security_number'] = 'SSN field is required.'
            error = 1;
        }else if(social_security_number.trim().length !== 9){
            docValidation['social_security_number'] = 'SSN is invalid.'
            error = 1;
        }

        if (tax_payer_name.trim().length === 0) {
            docValidation['tax_payer_name'] = 'Legal Name field is required.'
            error = 1;
        }

        if (website.trim().length > 0) {
            let web = website.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if(web === null){
                docValidation['website'] = 'Website url is invalid.'
                error = 1;
            }
        }

        if(documentType === "passport"){
            if(docValidation.isFrontPassImgUploaded === 0){
                docValidation['front_image'] = 'Front image is required.';
                error = 1;
            }
        }else{
            if(docValidation.isFrontDrivingImgUploaded === 0){
                docValidation['front_image'] = 'Front image is required.';
                error = 1;
            }

            if(docValidation.isBackDrivingImgUploaded === 0){
                docValidation['back_image'] = 'Front image is required.';
                error = 1;
            }
        }

        if(error === 1){
            setDocValidation({...docValidation });
            return false;
        }

        const formData = new FormData(event.target);
        formData.append(`id`,segment.trim());
        if(formData.get('documentType') === null){
            formData.append(`documentType`,documentType);
        }

        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'user/update-documents',formData,{
            headers: {
                'token': token,
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                getProfileStatus();
                fetchCleanerDetails();
                props.setLoading(false);
                
                // setPercentage(response.data.percentage);
                setIsIdentityDocumentUploaded(1);
                
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
            }else{
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
            console.log(error);
        });
    };

    const handleToggle = (e, fieldName) => {
        e.preventDefault();
        if(fieldName === 'password') {
            if (type === 'password'){
                setType('text');
            } else {
                setType('password');
            }
        } else if(fieldName === 'confirm_password') {
            if (confirmType === 'password'){
                setConfirmType('text');
            } else {
                setConfirmType('password');
            }
        }
    }

    const asteriskMarkComponent = () => {
        return (<span style={{font: 'caption'}}>*</span>);
    }

    const updateProfileCompletedStatus = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-profile-read-status',
        { 
            role_id: 1,
            readStatus: 1,
            userId: cleanerId
        },
        {
        headers: {
            'token': token,
        }
        })
        .then(function (response) {
            if(response.data.status === 1){
                getProfileStatus();
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getProfileStatus = async () => {
        await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/profile-status',
        { role_id: 1, userId: cleanerId, roleId: 3},
        {
          headers: {
              'token': token,
          }
        })
        .then(function (response) {
          if(response.data.status === 1){
            setProfileCompleteStatus(response.data.data.is_profile_completed);
            setIsProfileCompleted(response.data.data);
            setProfileErrorFields(response.data.data.error_fields);
          }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number
		});
	}

    return (
        <div className="page-content">
            <div className="container-fluid">


                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Add New Cleaner</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/cleaner-list">Cleaner List</Link></li>
                                    <li className="breadcrumb-item active">Update Cleaner Details</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                {(is_profile_completed && is_profile_completed.is_profile_completed === 0) ?
                    <div className="alert alert-message-box align-items-center d-flex justify-content-between">
                        <div className="messagr-text align-items-center d-flex gap-2">
                            <i className="ri-home-fill"></i>
                            <h6 className="mb-0 p-1">{is_profile_completed.is_profile_completed_msg}</h6>
                        </div>
                        {(is_profile_completed.is_profile_completed_url !== '' && !urlArr.includes(is_profile_completed.is_profile_completed_url)) ?
                        <Link className="btn white-btn waves-effect waves-light" to={is_profile_completed.is_profile_completed_url}> Click here </Link>
                        : <></>}
                    </div>
                    :
                    <>
                    {(is_profile_completed && is_profile_completed.profileReadStatus === 0 && is_profile_completed.isAdminVerificationPending === 1) &&
                    <div className="alert alert-message-box align-items-center d-flex justify-content-between">
                        <div className="messagr-text align-items-center d-flex gap-2">
                            <i className="ri-home-fill"></i>
                            <h6 className="mb-0 p-1">Congratulations, Your account is verified successfully.</h6>
                        </div>
                        
                        <button type="button" onClick={(e) => updateProfileCompletedStatus(e) } style={{background: 'transparent', border: '0px'}}>
                            <i className="ri-close-fill" style={{color: 'white', fontSize: 'x-large'}}></i>
                        </button>
                    </div>}

                    {(is_profile_completed && is_profile_completed.profileReadStatus === 0 && is_profile_completed.isAdminVerificationPending === 0) &&
                        <div className="alert alert-message-box align-items-center d-flex justify-content-between">
                            <div className="messagr-text align-items-center d-flex gap-2">
                                <i className="ri-home-fill"></i>
                                <h6 className="mb-0 p-1">{is_profile_completed.is_profile_completed_msg}</h6>
                            </div>
                        </div>
                    }
                    </>
                }

                <div className="row row-cols-xxl-5 row-cols-lg-5 row-cols-1 topMain">
                    <div className="col">
                        <div className="card card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <i className="ri-money-dollar-box-line"></i>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    <h5 className="card-title mb-1">Transactions</h5>
                                </div>
                            </div>
                            <Link to={'/cleaner-transactions/'+segment.trim()} className="btn btn-primary btn-sm">View Details</Link>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <i className="ri-wallet-2-line"></i>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    <h5 className="card-title mb-1">Payouts</h5>
                                </div>
                            </div>
                            <Link to={'/cleaner-earning/'+segment.trim()} className="btn btn-primary btn-sm">View Details</Link>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <i className="ri-bar-chart-grouped-fill"></i>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    <h5 className="card-title mb-1">Insight</h5>
                                </div>
                            </div>
                            <Link to={'/cleaner/insight/'+segment.trim()} className="btn btn-primary btn-sm">View Details</Link>
                        </div>
                    </div>

                </div>
                
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card" key={stepKey}>
                            <div className="card-body">
                               
                                <ul className="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link className={"nav-link "+((activeStep === 1) ? "active" : "")} data-bs-toggle="tab" to="#personal-information" role="tab" aria-selected="true">
                                            <span className="d-block d-sm-none"><i className="mdi mdi-home-variant"></i></span>
                                            <span className="d-none d-sm-block">Personal Information</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className={"nav-link "+((activeStep === 2) ? "active" : "")} data-bs-toggle="tab" to="#address-information" role="tab" aria-selected="true">
                                            <span className="d-block d-sm-none"><i className="mdi mdi-home-variant"></i></span>
                                            <span className="d-none d-sm-block">Address Information</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className={"nav-link "+((activeStep === 3) ? "active" : "")} data-bs-toggle="tab" to="#document-information" role="tab" aria-selected="true">
                                            <span className="d-block d-sm-none"><i className="mdi mdi-home-variant"></i></span>
                                            <span className="d-none d-sm-block">Document Information&nbsp;
                                                {documentVerificationError && <i className="fs-10 badge rounded-pill" style={errorDot}><span className='d-none'>0</span></i>}
                                            </span>
                                        </Link>
                                    </li>
                                    <WithPermission module="Cleaner" name="change_password" message="no" multiple="false">
                                        <li className="nav-item" role="presentation">
                                            <Link className="nav-link" data-bs-toggle="tab" to="#change-password" role="tab" aria-selected="false" tabIndex="-1">
                                                <span className="d-block d-sm-none"><i className="mdi mdi-account"></i></span>
                                                <span className="d-none d-sm-block">Change Password</span>
                                            </Link>
                                        </li>
                                    </WithPermission>
                                    
                                </ul>

                                <div className="tab-content text-muted">
                                    <div className={"tab-pane "+((activeStep === 1) ? "active show" : "")} id="personal-information" role="tabpanel">
                                        <form 
                                            onSubmit={submitPersonalData}
                                            method="post" 
                                            encType="multipart/form-data"
                                        >
                                            <div className="row gy-4">

                                                <div className="col-md-3">
                                                    <div className="text-center">
                                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-2">
                                                            <img src={authData.image}
                                                                className="rounded-circle avatar-xl img-thumbnail"
                                                                alt="user-profile" />
                                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                                <input id="profile-img-file-input" 
                                                                    type="file"
                                                                    name="image"
                                                                    className="profile-img-file-input"
                                                                    accept="image/png, image/jpeg"
                                                                    onChange={(e) => handleFileChange(e,'0')}
                                                                />
                                                                <label htmlFor="profile-img-file-input"
                                                                    className="profile-photo-edit avatar-xs">
                                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                                        <i className="ri-camera-fill"></i>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <h5 className="fs-16 mb-1">{authData.first_name} {authData.last_name}</h5>
                                                        {authValidations.image && <div className="text-danger"> {authValidations.image} </div>}
                                                    </div>
                                                </div>

                                                <div className="col-md-9">
                                                    <div className='row'>
                                                        <div className="col-md-6 mb-3">
                                                            <label className="form-label">First Name{asteriskMarkComponent()}</label>
                                                        
                                                            <input type="text" 
                                                                className="form-control" 
                                                                name='first_name'
                                                                onKeyDown={(e) => helper.checkOnlyAlphabetInput(e)} 
                                                                onChange={manageAuthState}
                                                                placeholder='Enter First Name'
                                                                value={authData.first_name}
                                                                maxLength={30} />
                                                            {authValidations.first_name && <div className="text-danger"> {authValidations.first_name} </div>}
                                                        
                                                        </div>

                                                        <div className="col-md-6 mb-3">
                                                            <label className="form-label">Last Name{asteriskMarkComponent()}</label>
                                                        
                                                            <input type="text" 
                                                                className="form-control" 
                                                                placeholder='Enter Last Name'
                                                                value={authData.last_name}
                                                                name='last_name'
                                                                onKeyDown={(e) => helper.checkOnlyAlphabetInput(e)} 
                                                                onChange={manageAuthState}
                                                                maxLength={30} />
                                                            {authValidations.last_name && <div className="text-danger"> {authValidations.last_name} </div>}
                                                            
                                                        </div>

                                                        <div className="col-md-6 mb-3">
                                                            <label className="form-label">Email{asteriskMarkComponent()}</label>
                                                        
                                                            <input type="text" 
                                                                className="form-control" 
                                                                placeholder='Enter Email'
                                                                onKeyDown={(e) => helper.checkSpaceInput(e)}
                                                                value={authData.email}
                                                                name='email'
                                                                // onChange={manageAuthState}
                                                                maxLength={50} 
                                                                readOnly 
                                                                style={{backgroundColor: '#cdccc747'}} />
                                                            {authValidations.email && <div className="text-danger"> {authValidations.email} </div>}
                                                            
                                                        </div>

                                                        <div className="col-md-6 mb-3">
                                                            <label className="form-label">Phone Number{asteriskMarkComponent()}</label>
                                                            
                                                            <input type="text" 
                                                                className="form-control" 
                                                                placeholder='Enter Phone Number'
                                                                value={authData.phone_number}
                                                                name='phone_number'
                                                                onKeyDown={(e) => helper.checkNumericInput(e)}
                                                                onChange={managePhone}
                                                                maxLength={10} />
                                                            {authValidations.phone_number && <div className="text-danger"> {authValidations.phone_number} </div>}
                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">Languages{asteriskMarkComponent()}</label>
                                                    
                                                    <input type="text" 
                                                        className="form-control" 
                                                        placeholder='Enter Languages'
                                                        name='languages'
                                                        value={authData.languages}
                                                        onKeyDown={(e) => helper.checkAlphabetInputWithComma(e)}
                                                        onChange={manageAuthState}
                                                        maxLength={50} />
                                                    {authValidations.languages && <div className="text-danger"> {authValidations.languages} </div>}
                                                
                                                </div>

                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">Date Of Birth{asteriskMarkComponent()}</label>
                                                    
                                                    <DatePicker 
                                                        selected={authData.dob}
                                                        className='form-control'
                                                        maxDate={new Date()}
                                                        name='dob'
                                                        autoComplete='off'
                                                        value={authData.dob}
                                                        placeholderText="Select Date Of Birth"
                                                        onChange={(date) => {manageTabData('dob',date)}} />
                                                        {authValidations.dob && <div className="text-danger"> {authValidations.dob} </div>}
                                                </div>

                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">School(optional)</label>
                                                    <input type="text" 
                                                        className="form-control" 
                                                        placeholder='Enter School'
                                                        name='school'
                                                        value={authData.school}
                                                        onKeyDown={(e) => helper.checkAlphabetInput(e)}
                                                        onChange={(e) => {manageTabData('school', e.target.value)}}
                                                        maxLength={50} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">What describes you best?{asteriskMarkComponent()}</label>                                                     
                                                    <div className="list-group">
                                                        <RadioInput 
                                                            value="I am an independent professional cleaner" />
                                                        <RadioInput 
                                                            value="I run a cleaning company with employees" />
                                                        <RadioInput 
                                                        value="I am cleaner for a company" />
                                                    </div>
                                                    {authValidations.describeBest && <div className="text-danger"> {authValidations.describeBest} </div>}
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">About/Bio (Optional)</label>
                                                    <textarea
                                                        type="text" 
                                                        className="form-control ptextBoxHeight" 
                                                        name='bio'
                                                        placeholder='Enter Bio'
                                                        value={authData.bio}
                                                        rows={4}
                                                        onChange={(e) => {manageTabData('bio', e.target.value)}}
                                                        maxLength={250} 
                                                    />
                                                    <div className="float-end me-1 word-limit-count">{authData.bio ? authData.bio.length : 0}/250 characters</div>
                                                    {authValidations.bio && <div className="text-danger"> {authValidations.bio} </div>}
                                                </div>
                                                
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">Years of experiene as a cleaner?{asteriskMarkComponent()}</label>
                                                    <select 
                                                        className="form-select"
                                                        name='experience'
                                                        value={authData.experience}
                                                        onChange={(e) => {manageTabData('experience', e.target.value)}}
                                                        aria-label="Default select example">
                                                        <option value="">Select your Experiene</option>
                                                        <option value="0">Less than 1 year</option>
                                                        <option value="1">1 year</option>
                                                        <option value="2">2 years</option>
                                                        <option value="3">3 years</option>
                                                        <option value="4">4 years</option>
                                                        <option value="5">5 years</option>
                                                        <option value="6">More than 5+ years</option>
                                                    </select>
                                                    {authValidations.experience && <div className="text-danger"> {authValidations.experience} </div>}
                                                </div>
                                        

                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">Have you cleaned short term rental{asteriskMarkComponent()}</label>
                                                    <div className="form-check form-switch">
                                                        <label htmlFor="withradios-showcode" className="form-label text-muted">Yes</label>
                                                        <input 
                                                            className="form-check-input code-switcher" 
                                                            type="checkbox" 
                                                            role="switch" 
                                                            id="termRental"
                                                            onChange={manageCheckBox}
                                                            checked={authData.shortTermRental === "yes"}
                                                            value={authData.shortTermRental} />    

                                                        {authValidations.shortTermRental && <div className="text-danger"> {authValidations.shortTermRental} </div>}                                                              
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label">Status{asteriskMarkComponent()}</label>
                                                    <select
                                                        name="status"
                                                        value={authData.status} 
                                                        onChange={e => {manageTabData('status',e.target.value)}}
                                                        className="form-control"
                                                    >
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </select>
                                                    
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label">Is Approved{asteriskMarkComponent()}</label>
                                                    <select
                                                        name="is_approved"
                                                        value={authData.isApproved} 
                                                        onChange={e => {manageTabData('isApproved',e.target.value)}}
                                                        className="form-control"
                                                    >
                                                        <option value="0">Pending</option>
                                                        <option value="1">Approved</option>
                                                        <option value="2">Declined</option>
                                                    </select>
                                                    
                                                </div>
                                                
                                                <div className="col-12">
                                                    <div className="text-end">
                                                        <button className="btn btn-success" type="submit">Update Details</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </div>


                                    <div className={"tab-pane "+((activeStep === 2) ? "active show" : "")} id="address-information" role="tabpanel">
                                        <Address 
                                            addressArray={addressArray}
                                            parentCallback={handleAddressCallback}
                                            submitAddressData={submitAddressData}
                                            validation={validateAddress}
                                        />
                                    </div>

                                    <div className={"tab-pane "+((activeStep === 3) ? "active show" : "")} id="document-information" role="tabpanel">
                                        <form 
                                            onSubmit={submitIdentityProofData} 
                                            method="post" 
                                            encType="multipart/form-data"
                                        >
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="form-label">SSN{asteriskMarkComponent()}</label>
                                                    <div className="mb-4">
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder='Enter SSN'
                                                            name='social_security_number'
                                                            value={social_security_number}
                                                            onKeyDown={(e) => helper.checkNumericInput(e)}
                                                            onChange={(e) => setSocialSecurityNumber(e.target.value)}
                                                            maxLength={9}
                                                            style={{borderColor: SSNFocus && 'red'}} 
                                                        />
                                                            
                                                            {docValidation.social_security_number && <div className="text-danger"> {docValidation.social_security_number} </div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">Website (Optional)</label>
                                                    <div className="mb-4">
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder='Enter Website URL'
                                                            name='website'
                                                            value={website}
                                                            onChange={(e) => setWebsite(e.target.value)}
                                                            maxLength={30} />
                                                        {docValidation.website && <div className="text-danger"> {docValidation.website}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">Business Tax ID (TIN) (Optional)</label>
                                                    <div className="mb-4">
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder='Business Tax ID (TIN)'
                                                            name='tax_payer_id'
                                                            value={tax_payer_id}
                                                            onKeyDown={(e) => helper.checkNumericInput(e)}
                                                            onChange={(e) => setTaxPayerId(e.target.value)}
                                                            maxLength={9}
                                                            style={{borderColor: (tax_payer_id !== "" && SSNFocus) && 'red'}}  
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">Legal Name{asteriskMarkComponent()}</label>
                                                    <div className="mb-4">
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder='Legal Name'
                                                            name='tax_payer_name'
                                                            value={tax_payer_name}
                                                            onKeyDown={(e) => helper.checkAlphabetInput(e)}
                                                            onChange={(e) => setTaxPayerName(e.target.value)}
                                                            maxLength={60} />

                                                        {docValidation.tax_payer_name && <div className="text-danger"> {docValidation.tax_payer_name}</div>}
                                                    </div>
                                                </div>


                                                <div className="com-md-12">
                                                    <h5 className="mb-3">Choose Document Type</h5>

                                                    <div className="d-flex gap-2">
                                                        { (isIdentityDocumentUploaded === 0 || (isIdentityDocumentUploaded === 1 && documentType === "passport")) ?
                                                        <div>
                                                            <input 
                                                                type="radio" 
                                                                className="btn-check" 
                                                                id="passport"
                                                                value="passport"
                                                                name="documentType"
                                                                defaultChecked={documentType === "passport"}
                                                                onChange={(e) => {manageDocType(e.target.value)}}
                                                                {...setDocumentType}
                                                            />
                                                            <label className="btn btn-outline-list" htmlFor="passport">Passport</label>
                                                        </div>
                                                            : <></>
                                                        }
                                                        { (isIdentityDocumentUploaded === 0 || (isIdentityDocumentUploaded === 1 && documentType !== "passport")) ?
                                                        <div>
                                                            <input 
                                                                type="radio" 
                                                                className="btn-check" 
                                                                id="driving-licence"
                                                                name="documentType"
                                                                value="driving_licence"
                                                                defaultChecked={documentType === "driving_licence"}
                                                                onChange={(e) => {manageDocType(e.target.value)}}
                                                                {...setDocumentType}
                                                            />
                                                            <label className="btn btn-outline-list" htmlFor="driving-licence">Driving Licence
                                                                </label>
                                                        </div>
                                                            : <></>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                <div className={"col-md-4 " + ((documentType !== "passport") ? " d-none" : "") }>
                                                    <div className="mb-2">
                                                        <h5>Passport Front Image{asteriskMarkComponent()}</h5>
                                                        { (isIdentityDocumentUploaded > 0 && (!profileCompleteStatus || (profileCompleteStatus && profileCompleteStatus !== 1))) && 
                                                            <div className='py-1'>
                                                                <input type="file" name="passport[frontImage]" accept="image/png, image/jpeg" onChange={(e) => handleFileChange(e, '1')} /> 
                                                            </div>
                                                        }
                                                        <div className="dropzone d-flex align-items-center">
                                                            { (isIdentityDocumentUploaded === 0) ? 
                                                            <div className="fallback">
                                                                <input type="file" name="passport[frontImage]" onChange={(e) => handleFileChange(e, '1')} />
                                                            </div>
                                                                : <></>
                                                            }
                                                            <div className="dz-message needsclick text-center">
                                                                <div className="d-image">
                                                                    <img src={passportFrontImage} alt="passportfrontimg" onClick={() => openLightboxOnSlide(1)} />
                                                                </div>
                                                                {(isIdentityDocumentUploaded === 0) && 
                                                                    <div className="h-image"> 
                                                                        <img src="/assets/images/Placeholder/add-icon.png" alt="passportfrontimg" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {docValidation.front_image && <div className="text-danger"> {docValidation.front_image}</div>}
                                                    </div>
                                                </div>
                                                <div className={"col-md-4 " + ((documentType !== "driving_licence") ? " d-none" : "") }>
                                                    <div className="mb-2">
                                                        <h5>Driving Licence Front Image{asteriskMarkComponent()}</h5>
                                                        { (isIdentityDocumentUploaded > 0 && (!profileCompleteStatus || (profileCompleteStatus && profileCompleteStatus !== 1))) && 
                                                            <div className='py-1'>
                                                                <input type="file" name="drivingLicense[frontImage]" accept="image/png, image/jpeg" onChange={(e) => handleFileChange(e, '3')} />
                                                            </div>
                                                        }
                                                        <div className="dropzone d-flex align-items-center">
                                                            { (isIdentityDocumentUploaded === 0) ?
                                                                <div className="fallback">
                                                                    <input type="file" name="drivingLicense[frontImage]" onChange={(e) => handleFileChange(e, '3')} />
                                                                </div>
                                                                : <></>
                                                                }
                                                            <div className="dz-message needsclick text-center">
                                                                <div className="d-image">
                                                                    <img src={drivingFrontImage} alt="drivingFrontImage" onClick={() => openLightboxOnSlide(1)} />
                                                                </div>
                                                                {(isIdentityDocumentUploaded === 0) && 
                                                                    <div className="h-image"> 
                                                                        <img src="/assets/images/Placeholder/add-icon.png" alt="drivingFrontImage" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {docValidation.front_image && <div className="text-danger"> {docValidation.front_image}</div>}
                                                    </div>
                                                </div>
                                                <div className={"col-md-4 " + ((documentType !== "driving_licence") ? " d-none" : "") }>
                                                    <div className="mb-2">
                                                        <h5>Driving Licence Back Image{asteriskMarkComponent()}</h5>
                                                        { (isIdentityDocumentUploaded > 0 && (!profileCompleteStatus || (profileCompleteStatus && profileCompleteStatus !== 1))) && 
                                                            <div className='py-1'>
                                                                <input type="file" name="drivingLicense[backImage]" accept="image/png, image/jpeg" onChange={(e) => handleFileChange(e, '4')} />
                                                            </div>
                                                        }
                                                        <div className="dropzone d-flex align-items-center">
                                                            { (isIdentityDocumentUploaded === 0) ?
                                                            <div className="fallback">
                                                                <input type="file" name="drivingLicense[backImage]" onChange={(e) => handleFileChange(e, '4')} />
                                                            </div>
                                                            : <></>
                                                            }
                                                            <div className="dz-message needsclick text-center">
                                                                <div className="d-image">
                                                                    <img src={drivingBackImage} alt="drivingBackImage" onClick={() => openLightboxOnSlide(2)} />
                                                                </div>
                                                                {(isIdentityDocumentUploaded === 0) && 
                                                                    <div className="h-image"> 
                                                                        <img src="/assets/images/Placeholder/add-icon.png" alt="drivingBackImage" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {docValidation.back_image && <div className="text-danger"> {docValidation.back_image}</div>}
                                                    </div>
                                                </div>

                                                <div>
                                                    <FsLightbox
                                                        toggler={lightboxController.toggler}
                                                        slide={lightboxController.slide}
                                                        sources={documentImgArr}
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <div className="text-end mt-3">
                                                        <button type="submit" className="btn btn-success">Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                    <WithPermission module="Cleaner" name="change_password" message="no" multiple="false">
                                        <div className="tab-pane" id="change-password" role="tabpanel">
                                            <form id="UpdatePassForm" onSubmit={PasswordUpdate}>
                                                <div className="row gy-4">
                                                    <div className="col-md-6">
                                                        <label className="form-label">New Password</label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <input type={type} 
                                                            className="form-control" 
                                                            value={newPassword} 
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                            maxLength={30} />
                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={(e) => handleToggle(e, 'password')} ><i className="ri-eye-fill align-middle"></i></button>
                                                        </div>
                                                        {errorNewPassword && <div className="text-danger"> {errorNewPassword} </div>}
                                                    </div>
                                                    
                                                    <div className="col-md-6">
                                                        <label className="form-label">Confirm Password</label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <input type={confirmType} 
                                                            className="form-control" 
                                                            value={confirmPassword} 
                                                            onChange={(e) => setConfirmPassword(e.target.value)} 
                                                            maxLength={30} />
                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="confirm-password-addon" onClick={(e) => handleToggle(e, 'confirm_password')} ><i className="ri-eye-fill align-middle"></i></button>
                                                        </div>

                                                        {errorConfirmPass && <div className="text-danger"> {errorConfirmPass} </div>}
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="text-end mt-3">
                                                            <button className="btn btn-success" type="submit">Update Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </WithPermission>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default EditCleaner;
