import React, {useState} from 'react'
import AutoComplete from '../components/common/AutoComplete';

const Home = () => {
  const [place, setPlace] = useState(null);
  
  return (
  <div className="page-content">
    <div className="container-fluid">
      <div className="row">
          <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Test</h4>

                  <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item"><a href="#javascript">Dashboards</a></li>
                          <li className="breadcrumb-item active">Test</li>
                      </ol>
                  </div>

              </div>
          </div>
      </div>
      <div className="row">
          <div className="col">
              <div className="h-100">
              <AutoComplete place={place} setPlace={setPlace}/>
              </div> 
          </div>
      </div>
    </div>
</div>        
  )
}

export default Home;
