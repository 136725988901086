import React , { useEffect, useState } from 'react'
import axios from 'axios';
import helper from '../../helper/index';
import WithPermission from '../../components/WithPermission';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';

const Transaction_fee = () => {

    const token = Cookies.get('token');
    const decoded = decodeToken(token);

    const [cleanerTransactionFee, setCleanerTransactionFee] = useState(0);
    const [hostTransactionFee, setHostTransactionFee] = useState(0);
    const [errorTFC, setTFCErrMsg] = useState("");
    const [errorTFH, setTFHErrMsg] = useState("");

    const collectData = async (event) => {
        event.preventDefault();
        
        setTFCErrMsg("");
        setTFHErrMsg("");

        let error = 0;
        if(cleanerTransactionFee.trim().length === 0) {
            setTFCErrMsg('Cleaner Transaction Fee field is required.');
            error = 1;
        }
        if(hostTransactionFee.trim().length === 0) {
            setTFHErrMsg('Host Transaction Fee field is required.');
            error = 1;
        }
        if(cleanerTransactionFee < 1 || cleanerTransactionFee > 100){
            setTFCErrMsg('Cleaner Transaction Fee must be between 1-100.');
            error = 1;
        }
        if(hostTransactionFee < 1 || hostTransactionFee > 100){
            setTFCErrMsg('Host Transaction Fee must be between 1-100.');
            error = 1;
        }

        if(error === 1){
            return;
        }
       
        try {
            // Update transaction fee for host and cleaner
            const {data} = await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-transaction-fees', {
                cleanerTransactionFee: cleanerTransactionFee.trim(),
                hostTransactionFee: hostTransactionFee.trim()
            },{
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            });

            if(data.status === 1){
                helper.Toast().fire({ icon: 'success', title: data.message });
            }else{
                helper.Toast().fire({ icon: 'error', title: data.message });
            }
        } catch (error) {
            console.log('Error',error);
        }
    };

    //Fetch transaction fee details
    const fetchTransactionFee = async () => {
        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/transaction-fees', {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
        if (data.status === 1) {
            setCleanerTransactionFee(data.data.cleaner_fee);
            setHostTransactionFee(data.data.host_fee);
        }
    };

    useEffect(() => {
        fetchTransactionFee()
    }, [])

    return (
        <WithPermission module="TransactionFee" name="view_fee" message="yes" multiple="false">
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Transaction Fee</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item active">Transaction Fee</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={collectData}>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="form-label">Transaction Fee Cleaner(%)</label>
                                                <input type="text" className="form-control" 
                                                    name="transactionFeeCleaner"
                                                    value={cleanerTransactionFee} 
                                                    onChange={(e) => setCleanerTransactionFee(e.target.value)}
                                                    placeholder="Transaction Fee (Cleaner)" />
                                                {errorTFC && <div className="text-danger"> {errorTFC}</div>}
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Transaction Fee Host(%)</label>
                                                <input type="text" className="form-control" 
                                                    name="transactionFeeHost"
                                                    value={hostTransactionFee} 
                                                    onChange={(e) => setHostTransactionFee(e.target.value)}
                                                    placeholder="Transaction Fee (Host)" />
                                                {errorTFH && <div className="text-danger"> {errorTFH}</div>}
                                            </div>
                                            <div className="col-md-6"></div>
                                            <div className="col-md-3">
                                                <div className="text-start mt-3">
                                                    <WithPermission module="TransactionFee" name="update_fee" message="yes" multiple="false" >
                                                        <button className="btn btn-success" type="submit">Update Fee</button>
                                                    </WithPermission>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Row */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </WithPermission>
    )
}

export default Transaction_fee;
