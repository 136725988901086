import React from 'react'
import { useWizard } from 'react-use-wizard';
import ProgressBar from '../../../components/common/ProgressBar'
import helper from '../../../helper';

const Additional = (props) => {
  const { nextStep, previousStep } = useWizard();

  return (
      <>
      <ProgressBar />
      <div className="card">
        <div className="card-body">
            <div className="live-preview">
          {/* Bedrooms */}
          <div className='row'>

            <div className='col-md-6'>
              <div className="col-md-12 my-3">
                <h3>Share some basic detail about your place</h3>
              </div>
              { props.additionlFields.map((item) => 
                <div className="col-md-12 incement-button" key={item}>
                  <div className="quantity mb-3">
                    <input  type="text" 
                      className="form-control" 
                      defaultValue={helper.captilizeString(item)}
                      disabled/>

                    {/* <a className="quantity__minus">
                      <span>-</span>
                    </a> */}
                      <select className='quantity__input' 
                        defaultValue={props.formField[item]}
                        onChange={(e) => props.handleAdditionalItemsValue(item, e.target.value)}>
                        {[...Array(11)].map((x, i) =>
                          <option value={i} key={i}>{i}</option>
                        )}
                      </select>
                      {/* <input name={item} type="text" 
                        className="quantity__input" 
                        onChange={(e) => props.handleAdditionalItemsValue(item, e.target.value)}
                        defaultValue={props.formField[item]} /> */}
                    {/* <a className="quantity__plus" 
                      onClick={props.incrementItem(item)}>
                      <span>+</span>
                    </a> */}
                  </div>                                            
                </div>
              )}
            </div>
            <div className='col-md-6'>
              <div className="col-md-12 my-3">
                  <h3>Size</h3>
              </div>

              <div className='row'>
                <label className="form-label" name='sub_type'>Approximately how big is your property when measuerd</label>
                <div className='col-md-6'>
                  <input type='number' 
                    className='form-control' 
                    value={props.propertySize} 
                    onChange={(e) => props.setPropertySize(e.target.value)} />
                </div>

                <div className='col-md-3'>
                  <button className={`btn ${props.selectedSizeType === 'ft' ? 'btn-primary' : 'btn-light'} w-100`} 
                      type='button' 
                      onClick={(e) => props.setSelectedSizeType('ft')}>ft²</button>
                </div>
                <div className='col-md-3'>
                  <button className={`btn ${props.selectedSizeType === 'm' ? 'btn-primary' : 'btn-light'} w-100`} 
                      type='button' 
                      onClick={() => props.setSelectedSizeType('m')}>m²</button>
                </div>
              </div>

            </div>

            <div className="col-12">
                <button className="btn btn-success me-2" 
                  onClick={() => previousStep()}>Previous</button>
                <button className="btn btn-success me-2" 
                  disabled={ !props.step2Validation ? true : false} 
                  onClick={() => nextStep()}>Next</button>
            </div>

          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Additional;