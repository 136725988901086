import React from 'react'
import { Link } from 'react-router-dom'

const breadcrump = (props) => {
  return (
    <div className="row">
          <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">{props.name}</h4>

                  <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                              <Link to="/">Home</Link>
                          </li>
                          <li className="breadcrumb-item"><Link to="/host-list">Host List</Link></li>
                          <li className="breadcrumb-item"><Link to={"/host-edit/"+props.hostId}>Host Detail</Link></li>
                          {props.isInsight ?
                            <li className="breadcrumb-item"><Link to={"/host/insight/"+props.hostId}>Insight</Link></li> :
                            <></>                          
                          }
                          <li className="breadcrumb-item active">{props.name}</li>
                      </ol>
                  </div>

              </div>
          </div>
      </div>
  )
}

export default breadcrump