import { useRef, useEffect } from "react";
const AutoComplete = (props) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
      componentRestrictions: { country: ["us"] },
      fields: ["address_components", "geometry", "icon", "name"],
      types: ["address"]
  };
  

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    inputRef.current,
    options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      props.setPlace(place);
     });
  }, []);

  return (
    <>
      <input ref={inputRef} className="form-control"/>
    </>
  );
};
export default AutoComplete;