import React , { useEffect, useState, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import WithPermission from '../components/WithPermission';
import helper from '../helper/index.js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import generateInvoicePdf from '../helper/cleaner-transaction-invoice-pdf.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const CleanerTransaction = (props) => {
    const token = Cookies.get('token');

    const params = useParams();
    const cleanerId = (params.id) ? params.id.trim() : null; 

    const manageModal = useRef(null)
    const [modalFlag, setModalFlag] = useState(0);

    const [stripe_transfer_list, setStripeTransferList] = useState([]);
    const [MAmount, setMAmount] = useState('');
    const [MTFee, setMTFee] = useState('');
    const [MTFeeP, setMTFeeP] = useState('');
    const [MTAmount, setMTAmount] = useState('');
    const [MCreatedDate, setMCreatedDate] = useState('');
    const [MTitle, setMTitle] = useState('');
    const [MPaymentId, setMTransId] = useState('');

    const [MTransGroup, setMTransGroup] = useState('');
    //Bank account details constants ends
    const [logoBase64, setLogoBase64] = useState("");
    const [TransData, setTransData] = useState({});

    useEffect(() => {
        // getData();
        fetchStripeTransferList()
        getBase64ImageFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBase64ImageFromUrl = async() => {
        props.setLoading(true);
        var res = await fetch('../assets/images/das-logo.png');
        var blob = await res.blob();
        if(blob.type !== 'image/png') {
            props.setLoading(false);
            return true;
        }
        return new Promise((resolve, reject) => {
            var reader  = new FileReader();
            reader.addEventListener("load", function () {
                setLogoBase64(reader.result);
                props.setLoading(false);
                resolve(reader.result);
            }, false);
        
            reader.onerror = () => {
                props.setLoading(false);
                return reject(this);
            };
            reader.readAsDataURL(blob);
        })
    }

    // Fetch Bank Account Details
    const fetchStripeTransferList = async () => {
        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/fetch-transfers-list',{'userId': cleanerId, role_id: 1},{
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
        if(data.status === 1){
            setStripeTransferList(data.data.data);
        }
    };

    const ViewInvoice = async (object) => {
        let formdata = {
          'transaction_id'  : object.id,
          'amount'          : (object.amount)/100,
          'created_date'    : object.created,
          'transfer_group'  : object.transfer_group,
          'userId': cleanerId,
          'from': 'admin'
        };
    
        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/transaction-detail',formdata,{
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
        manageModal.current.click()

        setMTransId('')
        setMCreatedDate('')
        setMTransGroup('')
        setMTitle('')
        setMAmount('')
        setMTFee('')
        setMTFeeP('')
        setMTAmount('')

        if(data.status === 1){
            setMTransId(data.data.transactionId)
            setMCreatedDate(data.data.createdDate)
            setMTransGroup(data.data.transferGroup)
            setMTitle(data.data.assignmentTitle)
            setMAmount(data.data.currentTrans.amount)
            setMTFee(data.data.currentTrans.transactionFee)
            setMTFeeP(data.data.currentTrans.transFeePerct)
            setMTAmount(data.data.currentTrans.totalAmount)
            setModalFlag(1)
        }else{
            setModalFlag(0)
        }
    };

    const fetchTranasctionDetail = async (object) => {
        let formdata = {
            'transaction_id'  : object.id,
            'amount'          : (object.amount)/100,
            'created_date'    : object.created,
            'transfer_group'  : object.transfer_group,
            'userId': cleanerId,
            'from': 'admin'
        };
      
        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/transaction-detail',formdata,{
                            headers: {
                                'Content-Type': 'application/json',
                                'token': token
                            }
                        })
        
        if(data.status === 1){
            setTransData(data.data);
        } else {
            setTransData({});
            helper.Toast().fire({ icon: 'error', title: data.message });
        }
    };
    
    const openNewWindow = async (object) => {        
        await fetchTranasctionDetail(object);
    }

    useEffect(() => {
        if(Object.keys(TransData).length > 0) {
            const invoiceContent = generateInvoicePdf(TransData, logoBase64);
            if(Object.keys(invoiceContent).length > 0) {
                try {
                    pdfMake.createPdf(invoiceContent).open();
                } catch(err) {
                    if(err === "Open PDF in new window blocked by browser") {
                        alert('Please allow popup for housekeeper site to view invoice pdf.');
                    }
                }
            }
            setTransData({});
        }
    }, [TransData])

    return (
        <>
        <WithPermission module="Cleaner Transactions" name="view_transaction" message="yes" multiple="false">
        <div className="page-content">
          <div className="container-fluid">


              <div className="row">
                  <div className="col-12">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                          <h4 className="mb-sm-0">Transactions</h4>

                          <div className="page-title-right">
                              {cleanerId === null ?
                              <ol className="breadcrumb m-0">
                                  <li className="breadcrumb-item">
                                      <Link to="/">Home</Link>
                                  </li>
                                  <li className="breadcrumb-item active">Transactions</li>
                              </ol>
                              :
                              <ol className="breadcrumb m-0">
                                  <li className="breadcrumb-item">
                                      <Link to="/">Home</Link>
                                  </li>
                                  <li className="breadcrumb-item"><Link to="/cleaner-list">Cleaner List</Link></li>
                                  <li className="breadcrumb-item"><Link to={"/cleaner-edit/"+cleanerId}>Cleaner Detail</Link></li>
                                  <li className="breadcrumb-item active">Transactions</li>
                              </ol>
                              }
                          </div>

                      </div>
                  </div>
              </div>


              <div className="row">

                  <div className="col-xl-12">
                  {stripe_transfer_list.length > 0 ?
                    <>
                      <div className="card mt-4">
                          <div className="card-header align-items-center d-flex justify-content-between">
                              <div className="d-flex align-items-center gap-3 ">
                                  <h4 className="card-title mb-0 flex-grow-1 d-none">Transaction</h4>

                              </div>
                          </div>

                          <div className="card-body">
                              <div className="table-responsive table-card data-table" id="transaction-table">
                                  <table className="table table-borderless table-nowrap align-middle mb-0 ">
                                      <thead className="">
                                          <tr className="text-muted">
                                              <th scope="col">Payment Number</th>
                                              <th scope="col">Date & Time</th>
                                              <th scope="col">Amount</th>
                                              <th scope="col">Status</th>
                                              <th scope="col">Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                      {
                                          (stripe_transfer_list.length >= 1) ?
                                              stripe_transfer_list.map(function(object, i){
                                                  return <tr key={i}>
                                                              <td className="number">{object.id}</td>
                                                              <td className="number">{moment.unix(object.created).format('MMM Do YYYY, hh:mm A') }</td>
                                                              <td>
                                                                  <div className="number">${parseFloat(object.amount/100).toFixed(2)}</div>
                                                              </td>
                                                              <td><span className="badge completed p-2 text-capitalize">Success</span>
                                                              </td>
                                                              <td>
                                                                <span className="p-2 text-capitalize badge view-receipt-color" onClick={(e)=>ViewInvoice(object)}>View Receipt</span>
                                                                <span className = "btn btn-sm btn-warning ms-2" onClick={(e)=>openNewWindow(object)}><i className = "bx bx-download"></i> Invoice</span>
                                                              </td>
                                                          </tr>;
                                              })
                                          :
                                          <>
                                              <tr>
                                                  <td colSpan="4">Transaction not recorded yet</td>
                                              </tr>
                                          </>
                                      }
                                      </tbody>
                                  </table>
                              </div>
                              
                              
                          </div>
                          
                      </div>
                    </>
                      :
                      <div className="row">
                          <div className="col-xl-12">
                              <div className="alert alert-danger" role="alert">No transaction recorded yet</div>
                          </div>
                      </div>
                  }
                      
                  </div>
              </div>
          </div>
        </div>

        <button style={{'display': 'none'}} type="button" data-bs-toggle="modal" data-bs-target="#transactionHistoryModal" ref={manageModal} className="btn btn-add w-100 mb-2 me-2">View Receipt</button>
                <div id="transactionHistoryModal" className="modal fade" tabIndex="-1" aria-labelledby="transactionHistoryModalLabel" aria-hidden="true" style={{'display': 'none'}}> 
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Transaction Details</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                { 
                                (modalFlag === 1) ? 
                                    <>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="flex-grow-1">
                                                <p className="fw-medium text-muted mb-0 number">
                                                    Transaction ID: {MPaymentId}<br></br>
                                                    Transaction Date: {moment.unix(MCreatedDate).format('MMM Do YYYY, hh:mm A') } 
                                                    
                                                </p>
                                                </div>
                                                <div>
                                                <h4 className="fs-20 fw-semibold ff-secondary">
                                                    <span className="badge completed p-2 text-capitalize">Success</span>
                                                </h4>
                                                </div>
                                            </div> 
                                            <div className="d-flex align-items-center pt-2 mb-2 border-top">
                                                <div className="flex-grow-1">
                                                    <p className="text-uppercase fw-semibold text-muted mb-0 number ">Assigment Name</p>
                                                </div>
                                                <div>
                                                    <p className="fw-medium ff-secondary mb-0">{MTitle}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center pt-2 border-top">
                                                <div className="flex-grow-1">
                                                    <p className="text-uppercase  fw-semibold text-muted mb-0 number ">Amount</p>
                                                    <p className="text-uppercase  fw-semibold text-muted mb-0 number ">Transaction Fee ({MTFeeP}%)</p>
                                                </div>
                                                <div>
                                                    <p className="fw-medium ff-secondary mb-0 text-end">${parseFloat(MTAmount).toFixed(2)}</p>
                                                    <p className="fw-medium ff-secondary mb-0 text-end">-${parseFloat(MTFee).toFixed(2)}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center pt-2 border-top">
                                                <div className="flex-grow-1">
                                                    <h5 className="text-uppercase  fw-semibold text-muted mb-0 number ">Total Amount</h5>
                                                </div>
                                                <div>
                                                    <h4 className="fs-20 fw-medium ff-secondary">${parseFloat(MAmount).toFixed(2)}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    </> 
                                : 
                                    <>
                                    Sorry! We are unable to find more details.
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </WithPermission>
    </>
    )
}

export default CleanerTransaction;
