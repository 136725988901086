import React,{useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from '../../fireabse';
import { doc, setDoc } from 'firebase/firestore';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';
import helper from '../../helper/index';

const AddSubuser = (props) => {
    const token = Cookies.get('token');
    // get user data from token in cookie
    const decoded = decodeToken(token);

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [EmailAddress, setEmailAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, SetConfirmPassword] = useState("");

    // Errors

    const [errorFirstName, setFNErrorMessage] = useState("");
    const [errorLastName, setLNErrorMessage] = useState("");
    const [errorEmail, setEErrorMessage] = useState("");
    const [errorPhone, setPErrorMessage] = useState("");
    const [errorPassword, setPDErrorMessage] = useState("");
    const [errorConfirmPassword, setCPErrorMessage] = useState("");

    const [type, setType] = useState('password');
    const [confirmType, setConfirmType] = useState('password');
    
    const [permissions, setPermissions] = useState({...helper.subuserPermissions});
    const [formStep, setFormStep] = useState(1);

    const submitFormData = async (event) => {
        event.preventDefault();
        
        setFNErrorMessage("");
        setLNErrorMessage("");
        setEErrorMessage("");
        setPErrorMessage("");
        setPDErrorMessage("");
        setCPErrorMessage("");

        let error = 0;
        if(firstName.trim().length === 0) {
            setFNErrorMessage('First Name field is required.');
            error = 1;
        }
        if(lastName.trim().length === 0) {
            setLNErrorMessage('Last Name field is required.');
            error = 1;
        }
        if(EmailAddress.trim().length === 0) {
            setEErrorMessage('Email Address field is required.');
            error = 1;
        }
        if(phone.trim().length === 0) {
            setPErrorMessage('Phone Number field is required.');
            error = 1;
        }
        if(password.trim().length === 0) {
            setPDErrorMessage('Password field is required.');
            error = 1;
        }
        if(confirmPassword.trim().length === 0) {
            setCPErrorMessage('Confirm Password field is required.');
            error = 1;
        }else{
            if(password.trim() !== confirmPassword.trim()){
                setCPErrorMessage('Confirm Password is not matched with password field.');
                error = 1;
            }
        }

        if(error === 1){
            return;
        }

        try {
            props.setLoading(true);
            // Create Host User
            const response = await axios.post(process.env.REACT_APP_BACKENDURL+'user/create-subuser', {
                first_name: firstName.trim(),
                last_name: lastName.trim(),
                phone_number: phone.trim(), 
                email: EmailAddress.trim(),
                password: password.trim(),
                added_by: decoded.user_id,
                permissions: permissions
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            props.setLoading(false);
            if(response.data.status === 1){
                // Authenticate firebase user
                const userCredential =  await createUserWithEmailAndPassword(auth, EmailAddress, response.data.id);
                const user = userCredential.user;

                // Save firebase uid in the database
                const resp = await axios.post(process.env.REACT_APP_BACKENDURL+'user/update-firebase-uid', {
                    uid: user.uid,
                    user_id: response.data.id,
                },{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if(resp.data.status === 1){

                    // Save name in firebase user
                    const update = await updateProfile(user, {
                        displayName: firstName + ' ' + lastName,
                    });

                    setDoc(doc(db, "users", user.uid), {
                        uid: user.uid,
                        name: firstName + ' ' + lastName,
                        email: EmailAddress,
                        role_id: 4
                    });

                    helper.Toast().fire({ icon: 'success', title: response.data.message }).then(() => {
                        navigate('/subuser-list');
                    });

                }else{
                    helper.Toast().fire({ icon: 'success', title: response.data.message }).then(() => {
                        navigate('/subuser-list');
                    });
                }
            }else{
                helper.Toast().fire({ icon: 'error', title: response.data.message });
            }
            
        } catch (error) {
            props.setLoading(false);
            helper.Toast().fire({ icon: 'error', title: "Something went wrong. Please try again." });
        }
    };

    const handleChange = (e) => {
        const result = e.target.value.replace(/\D/g, '');
        setPhone(result);
    };

    function capitalizeFirstLetter(str) {
        return str.trim().charAt(0).toUpperCase() + str.slice(1);
    }

    const handleToggle = (e, fieldName) => {
        e.preventDefault();
        if(fieldName === 'password') {
            if (type === 'password'){
                setType('text');
            } else {
                setType('password');
            }
        } else if(fieldName === 'confirm_password') {
            if (confirmType === 'password'){
                setConfirmType('text');
            } else {
                setConfirmType('password');
            }
        }
    }

    const asteriskMarkComponent = () => {
        return (<span style={{font: 'caption'}}>*</span>);
    }

    const permissionOnChange = (main, per_name) => {
        if(permissions[main][per_name].value === true){
            permissions[main][per_name].value = false;
        }else{
            permissions[main][per_name].value = true;
        }
        setPermissions({...permissions});
    };

    const step1FormVerification = async() => {        
        setFNErrorMessage("");
        setLNErrorMessage("");
        setEErrorMessage("");
        setPErrorMessage("");
        setPDErrorMessage("");
        setCPErrorMessage("");

        let error = 0;
        if(firstName.trim().length === 0) {
            setFNErrorMessage('First Name field is required.');
            error = 1;
        }
        if(lastName.trim().length === 0) {
            setLNErrorMessage('Last Name field is required.');
            error = 1;
        }
        if(EmailAddress.trim().length === 0) {
            setEErrorMessage('Email Address field is required.');
            error = 1;
        }
        if(phone.trim().length === 0) {
            setPErrorMessage('Phone Number field is required.');
            error = 1;
        }
        if(password.trim().length === 0) {
            setPDErrorMessage('Password field is required.');
            error = 1;
        }
        if(confirmPassword.trim().length === 0) {
            setCPErrorMessage('Confirm Password field is required.');
            error = 1;
        }else{
            if(password.trim() !== confirmPassword.trim()){
                setCPErrorMessage('Confirm Password is not matched with password field.');
                error = 1;
            }
        }

        if(error === 1){
            return false;
        }
        return true;
    }

    const changeStep = async(step) => {
        if(step === "next") {
            const res = await step1FormVerification();
            if(res) {
                setFormStep(2);
            }
        } else if( step === "back") {
            setFormStep(1);
        }
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Add New Subuser</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/subuser-list">Subuser List</Link></li>
                                    <li className="breadcrumb-item active">Add New Subuser</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <ul className="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link className={"nav-link "+ ((formStep === 1) ? "active" : "disabled")} data-bs-toggle="tab" to="#personal-information" role="tab" aria-selected="true">
                                            <span className="d-block d-sm-none"><i className="mdi mdi-home-variant"></i></span>
                                            <span className="d-none d-sm-block">Personal Information</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className={"nav-link "+ ((formStep === 2) ? "active" : "disabled")} data-bs-toggle="tab" to="#permissions" role="tab" aria-selected="false" tabIndex="-1">
                                            <span className="d-block d-sm-none"><i className="mdi mdi-account"></i></span>
                                            <span className="d-none d-sm-block">Permissions</span>
                                        </Link>
                                    </li>
                                </ul>

                                <div className={"tab-content text-muted activeStep"+formStep}>
                                    <div className={"tab-pane "+ ((formStep === 1) ? "active show" : "")} id="personal-information" role="tabpanel">
                                        <div className="row gy-4">
                                            <div className="col-xxl-3 col-md-6">
                                                <label className="form-label">First Name{asteriskMarkComponent()}</label>
                                                <input type="text" className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value = capitalizeFirstLetter(e.target.value))}maxLength={25}/>
                                                {errorFirstName && <div className="text-danger"> {errorFirstName} </div>}
                                            </div>
                                            
                                            <div className="col-xxl-3 col-md-6">
                                                <label className="form-label">Last Name{asteriskMarkComponent()}</label>
                                                <input type="text" className="form-control" value={lastName} onChange={(e) => setLastName(e.target.value = capitalizeFirstLetter(e.target.value))} maxLength={25} />
                                                {errorLastName && <div className="text-danger"> {errorLastName} </div>}
                                            </div>

                                            <div className="col-xxl-3 col-md-6">
                                                <label className="form-label">Email{asteriskMarkComponent()}</label>
                                                <input type="text" className="form-control" value={EmailAddress} onChange={(e) => setEmailAddress(e.target.value)} maxLength={50} />
                                                {errorEmail && <div className="text-danger"> {errorEmail} </div>}
                                            </div>
                                            
                                            <div className="col-xxl-3 col-md-6">
                                                <label className="form-label">Phone Number{asteriskMarkComponent()}</label>
                                                <input type="text" className="form-control" value={phone} onChange={handleChange} maxLength={12} />
                                                {errorPhone && <div className="text-danger"> {errorPhone} </div>}
                                            </div>

                                            <div className="col-xxl-3 col-md-6">
                                                <label className="form-label">Password{asteriskMarkComponent()}</label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <input type={type} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} maxLength={50} />
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={(e) => handleToggle(e, 'password')} ><i className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                                {errorPassword && <div className="text-danger"> {errorPassword} </div>}
                                            </div>
                                            
                                            <div className="col-xxl-3 col-md-6">
                                                <label className="form-label">Confirm Password{asteriskMarkComponent()}</label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <input type={confirmType} className="form-control" value={confirmPassword} onChange={(e) => SetConfirmPassword(e.target.value)} maxLength={50}  />
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="confirm-password-addon" onClick={(e) => handleToggle(e, 'confirm_password')} ><i className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                                {errorConfirmPassword && <div className="text-danger"> {errorConfirmPassword} </div>}
                                            </div>
                                            
                                            <div className="col-12">
                                                <button className="btn btn-success float-end" onClick={() => changeStep('next')}> Next </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"tab-pane "+ ((formStep === 2) ? "active show" : "")} id="permissions" role="tabpanel">
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(permissions).map((index,i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{index}</td>
                                                                
                                                            <td>
                                                                {Object.keys(permissions[index]).map((per, ip) => {
                                                                    return (
                                                                        <div className="form-check form-check-inline" key={ip}>
                                                                            <input 
                                                                                className="form-check-input" 
                                                                                type="checkbox" 
                                                                                id={`formCheck${i}${ip}`}
                                                                                checked={permissions[index][per].value }
                                                                                onChange={() => permissionOnChange(index, per)}
                                                                             />
                                                                            <label className="form-check-label ms-1" htmlFor={`formCheck${i}${ip}`}>{permissions[index][per].name}</label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </td>
                                                        </tr>
                                                        )                                       
                                                })}
                                            </tbody>
                                        </table>

                                        <div className="col-12">
                                            <button className="btn btn-success" onClick={() => changeStep('back')}> Back </button>
                                            <button className="btn btn-success float-end" onClick={submitFormData} type="button">Submit</button>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    
                </div>


                
            </div>
        </div>
    )
}

export default AddSubuser;
