import React, {useState, useEffect} from 'react'
import Cookies from 'js-cookie';
import {  useParams } from 'react-router-dom';
import axios from 'axios';
// import { BarChart } from '@mui/x-charts/BarChart';
import CleanerInsightBreadcrump from './component/breadcrump'

const Insight = (props) => {

    const token = Cookies.get('token');;
    let params = useParams()
    let cleanerId = params.cleanerId

    const [perfomanceData, setPerfomanceData] = useState({
        "totalEarning": 0,
        "completedAssignmentsCount": 0,
        "reviewsCount": 0,
        "responseRate": 0,
        "totalEnquires": 0,
        "repeatCustomerRatio": 0
    })

    const collectData = async() => {
        try {
            await axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/insight/perfomance', {'from':  'admin','userId': cleanerId},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            }).then((response) => {
                if(response.data.status === 1) {
                    setPerfomanceData(response.data.data)
                }
            })
            .catch((error) => {
                console.error("Error: Something went wrong", error);
            });

        } catch (error) {
            
        }
    }

    useEffect(() => {
        collectData()
    }, [])
    
  return (
    <div className="page-content">
        <div className="container-fluid">

        <CleanerInsightBreadcrump name="Perfomance" isInsight={true} cleanerId={cleanerId} />

          <div className="row">
              <div className="col-xxl-12 col-md-12">
                  <div className="card">
                      <div className="card-header align-items-center d-flex justify-content-between">
                          <div className="d-flex align-items-start flex-column ">
                              <h4 className="card-title mb-0 flex-grow-1">Performance</h4>
                          </div>

                      </div>
                      <div className="card-body">
                          <div className="row earning-detail-boxs">
                              <div className="col-xl-4 col-md-6">
                                
                                  <div className="card card-animate">
                                      <div className="card-body">
                                          <div className="d-flex align-items-center">
                                              <div className="flex-grow-1 overflow-hidden">
                                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Total Earning</p>
                                              </div>
                                              <div className="flex-shrink-0">
                                                  <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                    <span className="counter-value" data-target="119.25">${(perfomanceData.totalEarning?.totalEarning === undefined) ? 0.00 : parseFloat(perfomanceData.totalEarning?.totalEarning).toFixed(2)}</span>
                                                  </h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-xl-4 col-md-6">
                                <div className="card card-animate">
                                      <div className="card-body">
                                          <div className="d-flex align-items-center">
                                              <div className="flex-grow-1 overflow-hidden">
                                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Completed Assignment</p>
                                              </div>
                                              <div className="flex-shrink-0">
                                                  <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                    <span className="counter-value" data-target="119.25">{perfomanceData.completedAssignmentsCount}</span>
                                                  </h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-xl-4 col-md-6">
                                <div className="card card-animate">
                                      <div className="card-body">
                                          <div className="d-flex align-items-center">
                                              <div className="flex-grow-1 overflow-hidden">
                                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Total Reviews</p>
                                              </div>
                                              <div className="flex-shrink-0">
                                                  <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                    <span className="counter-value" data-target="119.25">{perfomanceData.reviewsCount}</span>
                                                  </h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-xl-4 col-md-6">
                                <div className="card card-animate">
                                      <div className="card-body">
                                          <div className="d-flex align-items-center">
                                              <div className="flex-grow-1 overflow-hidden">
                                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Total Inquires</p>
                                              </div>
                                              <div className="flex-shrink-0">
                                                  <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                    <span className="counter-value" data-target="119.25">{perfomanceData.totalEnquires}</span>
                                                  </h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-xl-4 col-md-6">
                                <div className="card card-animate">
                                      <div className="card-body">
                                          <div className="d-flex align-items-center">
                                              <div className="flex-grow-1 overflow-hidden">
                                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Response Rate</p>
                                              </div>
                                              <div className="flex-shrink-0">
                                                  <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                    <span className="counter-value" data-target="119.25">
                                                        {perfomanceData.responseRate}
                                                    </span>
                                                  </h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-xl-4 col-md-6">
                                <div className="card card-animate">
                                      <div className="card-body">
                                          <div className="d-flex align-items-center">
                                              <div className="flex-grow-1 overflow-hidden">
                                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Repeat customer ratio</p>
                                                  <h6 className="earning-time">in last 6 months</h6>
                                              </div>
                                              <div className="flex-shrink-0">
                                                  <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                    <span className="counter-value" data-target="119.25">{perfomanceData.repeatCustomerRatio}</span>
                                                  </h4>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              
                          </div>

                      </div>
                  </div>
              </div>
          </div>            
        </div>
    </div>    
  )
}

export default Insight;
