import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from "../hooks/auth";
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';
import UnreadChatCount from './common/UnreadChat.js';
import axios from 'axios';
import moment from 'moment';
import { socket } from '../socket.js';

const Header = () => {
    const [isConnected, setIsConnected] = useState(socket.connected);
    const token = Cookies.get('token');
    // get user data from token in cookie
    const decoded = decodeToken(token);
    
    const [unreadChatCount, setUnreadChatCount] = useState(0);
    const [userNotifications, setUserNotifications] = useState([]);
    const defaultProfile =  '/assets/images/image-placeholder.png';
    const filePath = process.env.REACT_APP_BACKENDURL+'images/';

    const { logout } = useAuth();
    function handleLogout(){
        logout();
    }

    const fetchNotifications = async() => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/fetch-notification',
                            {
                                params: {
                                    "role_id": 1
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    'token': token
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });

            if(data.status === 1){
                setUserNotifications(data.data);
            }
        } catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {  

        function onConnect() {
            setIsConnected(true);
          }
      
          function onDisconnect() {
            setIsConnected(false);
          }
          
          socket.on('connect', onConnect);
          socket.on('disconnect', onDisconnect);
      
      
          socket.on('save-socket-id', (socketId) => {
              UpdateSocketId(socketId, decoded.userlevel)
          });
          
          fetchNotifications();
          socket.on('fetch-system-notification', () => {
            fetchNotifications();
          });
      
          return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('fetch-system-notification', () => {
                // 
            });
          };
        // const interval = setInterval(() => {
        //         fetchNotifications();
        // }, 10000);
    
        // return () => clearInterval(interval);
    }, []);

    const UpdateSocketId = (socketId, roleId) => {        
        axios.post(
                    process.env.REACT_APP_BACKENDURL+"api/common/update-socket", 
                    {
                        socketId: socketId,
                        role_id: roleId
                    },
                    {
                        headers: {'Content-Type': 'application/json', 'token': token}
                    }
                )
        }

    return (
    <header id="page-topbar">
        <UnreadChatCount
            setUnreadChatCount = {setUnreadChatCount}
        />
        <div className="layout-width">
            <div className="navbar-header">
                <div className="d-flex">
                    
                    <div className="navbar-brand-box horizontal-logo">
                        <a href="index.html" className="logo logo-dark">
                            <span className="logo-sm">
                                <img src={`/assets/images/logo-sm.png`} alt="" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img src={`/assets/images/logo-dark.png`} alt="" height="17" />
                            </span>
                        </a>

                        <a href="index.html" className="logo logo-light">
                            <span className="logo-sm">
                                <img src={`/assets/images/logo-sm.png`} alt="" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img src={`/assets/images/logo-light.png`} alt="" height="17" />
                            </span>
                        </a>
                    </div>

                    {/* <button type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                        id="topnav-hamburger-icon" onClick={handleToggleButton}>
                        <span className="hamburger-icon" ref={inputRef}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </button> */}

                
                </div>

                <div className="d-flex align-items-center">

                    <div className="dropdown d-md-none topbar-head-dropdown header-item">
                        <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                            id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i className="bx bx-search fs-22"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                            aria-labelledby="page-header-search-dropdown">
                            <form className="p-3">
                                <div className="form-group m-0">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search ..."
                                            aria-label="Recipient's username" />
                                        <button className="btn btn-primary" type="submit"><i
                                                className="mdi mdi-magnify"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="ms-1 header-item">
                        <Link to={'/messages'} className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                id="page-header-chat-notifications" title={`${ unreadChatCount > 0 ? unreadChatCount : 0 } Unread Message`}>
                                <i className='bx bx-chat fs-22'></i>
                                <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                                    { unreadChatCount > 0 ? unreadChatCount : 0 }
                                    <span className="visually-hidden">unread messages</span>
                                </span>
                            </button>
                        </Link>
                    </div>
                    <div className="dropdown topbar-head-dropdown ms-1 header-item">
                        <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                            id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i className='bx bx-bell fs-22'></i>
                            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                                { userNotifications ? userNotifications.filter(data =>  data.is_read === false).length : 0 }
                                <span className="visually-hidden">unread messages</span>
                            </span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                            aria-labelledby="page-header-notifications-dropdown">

                            <div className="dropdown-head bg-primary bg-pattern rounded-top">
                                <div className="p-3">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                        </div>
                                        <div className="col-auto dropdown-tabs">
                                            <span className="badge badge-soft-light fs-13"> { userNotifications ? userNotifications.filter(data =>  data.is_read === false).length : 0 } New</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="tab-content" id="notificationItemsTabContent">
                                <div className="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                                    <div data-simplebar style={{"maxHeight":"356px"}} className="pe-2">
                                        {(
                                        (userNotifications.length === 0) ? <div className="text-reset notification-item d-block dropdown-item position-relative">
                                                <div className="text-center">
                                                    No new notification
                                                </div>
                                            </div> 
                                        :
                                        (
                                        <div>
                                            {userNotifications.map((value, index) => {
                                            const timestamp = moment(value.createdAt).fromNow();
                                            const profileImg = value.userProfile.image ? filePath+value.userProfile.image.filename : defaultProfile;
                                            let linkTo = "";
                                            if(value.type === "Property") {
                                                linkTo = `/property-edit/${value.redirect_id}`;
                                            } else if(value.type === "Host") {
                                                linkTo = `/host-edit/${value.redirect_id}`;
                                            } else if(value.type === "Cleaner") {
                                                linkTo = `/cleaner-edit/${value.redirect_id}`;
                                            } else if(value.type === "Help") {
                                                linkTo = `/feedback`;
                                            }
                                            return (
                                            <React.Fragment key={index}>
                                            {(index < 3) && 
                                            <div className={`text-reset notification-item d-block dropdown-item position-relative ${value.is_read === false ? 'active' : ''}`} key={index}>
                                                <div className="d-flex">
                                                    <img src={profileImg} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                                    <div className="flex-1">
                                                        <Link to={linkTo}>
                                                            <div className="stretched-link">
                                                                <h6 className="mt-0 mb-1 fs-13 fw-semibold">{value.userDetail.name}</h6>
                                                            </div>
                                                            <div className="fs-13 text-muted">
                                                                <p className="mb-1">{value.message}</p>
                                                            </div>
                                                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                <span><i className="mdi mdi-clock-outline"></i> {timestamp}</span>
                                                            </p>
                                                        </Link>
                                                    </div>
                                                    
                                                </div>
                                            </div>}
                                            </React.Fragment>
                                            );
                                            })}

                                            <div className="my-2 text-center">
                                                <Link to={`/all-notification`}>
                                                    <button type="button" className="btn btn-soft-success waves-effect waves-light">
                                                        View All Notifications <i className="ri-arrow-right-line align-middle"></i>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                        )

                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dropdown ms-sm-1 header-item">
                        <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span className="d-flex align-items-center" style={{'width':'32px', 'height':'32px'}}>
                                {(decoded?.image) ?
                                <img className="rounded-circle header-profile-user" style={{'width':'100%', 'height':'100%', 'objectFit': 'cover'}} src={decoded?.image}
                                    alt="Header Avatar" />
                                    :
                                    <img className="rounded-circle header-profile-user" style={{'width':'100%', 'height':'100%', 'objectFit': 'cover'}} src="/assets/images/image-placeholder.png"
                                    alt="Header Avatar" />
                                }   
                            </span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                        
                            <h6 className="dropdown-header">Welcome {decoded?.name}!</h6>
                            <Link to="/profile" className="dropdown-item">
                                <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                                <span className="align-middle">Profile</span>
                            </Link>

                            {/* <a className="dropdown-item" href="" onClick={handleLogout}> */}
                            <button className="dropdown-item" href="" onClick={handleLogout}>
                                <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> 
                                <span className="align-middle" data-key="t-logout">Logout</span>
                            </button>
                            {/* </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    );
}

export default Header;
