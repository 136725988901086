import React , { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import helper from '../../helper/index.js';
import WithPermission from '../../components/WithPermission.js';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

let limit = 10;
const Home = (props) => {
    const token = Cookies.get('token');
    const types = helper.types;
    const subTypes = helper.subTypes;
    const [data, setData] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const params = useParams();
    const hostId = (params.hostId) ? params.hostId.trim() : null; 
    const [filterRecords, setFilterRecords] = useState({
                                                hostId: "",
                                                sortBy: "asc",
                                                searchText: "",
                                                type: 0,
                                                subType: 0,
                                            });
    const [hostList, setHostList] = useState([]);

    useEffect(() => {
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterRecords]);
    
    useEffect(() => {
        getHostList();
    }, [])
    

    const getTypeName = (id) => {
        var foundValue = types.filter(obj => obj.id === id);
        return foundValue[0].name;
    }

    const getSubTypeName = (id) => {
        var foundValue = subTypes.filter(obj => obj.id === id);
        return foundValue[0].name;
    }

    const getData = (datas) => {
        props.setLoading(true);
        let pageNo = (datas) ? datas.selected : 0;
        axios.get(process.env.REACT_APP_BACKENDURL+'property/get-properties',{
            params: {
                page: pageNo,
                limit: limit,
                hostId: hostId,
                filterRecords: filterRecords
            }
        })
        .then(function (response) {
            props.setLoading(false);
            if(response.data.status === 1){
                setPageCount(response.data.total_pages);
                setData(response.data.data);
            }
        })
        .catch(function (error) {
            props.setLoading(false);
            console.log(error);
        });
    }

    const getHostList = () => {
        axios.get(process.env.REACT_APP_BACKENDURL+'user/get-all-hosts')
        .then(function (response) {
            if(response.data.status === 1){
                setHostList(response.data.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleFilters = ( keyValue, value) => {
        filterRecords[keyValue] = value
        setFilterRecords({ ...filterRecords })
    }

    const removeProperty = async(id) => {
        Swal.fire({
            title: "Do you want to in-activate this property?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then( async(result) => {
            if (result.isConfirmed) {
                const {data} = await axios.post(process.env.REACT_APP_BACKENDURL+'property/remove-property',{
                                        id: id
                                    },
                                    {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'token': token
                                        }
                                    });
                if(data.status === 1){
                    helper.Toast().fire({ icon: 'success', title: data.message });
                    getData();
                } else {
                    helper.Toast().fire({ icon: 'error', title: data.message });
                }
            }
        });
    }

    const activateProperty = async(id) => {
        Swal.fire({
            title: "Do you want to activate this property?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `No`
        }).then( async(result) => {
            if (result.isConfirmed) {
                const {data} = await axios.post(process.env.REACT_APP_BACKENDURL+'property/activate-property',{
                                        id: id
                                    },
                                    {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'token': token
                                        }
                                    });
                if(data.status === 1){
                    helper.Toast().fire({ icon: 'success', title: data.message });
                    getData();
                } else {
                    helper.Toast().fire({ icon: 'error', title: data.message });
                }
            }
        });
    }

    return (
        <>
        <div className="page-content">
        <div className="container-fluid">


            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Property Listing</h4>

                        <div className="page-title-right">
                            {hostId === null ?
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Property Listing</li>
                            </ol>
                            :
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item"><Link to="/host-list">Host List</Link></li>
                                <li className="breadcrumb-item"><Link to={"/host-edit/"+hostId}>Host Detail</Link></li>
                                <li className="breadcrumb-item active">Host Property Listing</li>
                            </ol>
                            }
                        </div>

                    </div>
                </div>
            </div>


            <div className="row">

                <div className="col-xl-12 text-end mb-3">
                    <div className="card-body border border-dashed border-end-0 border-start-0">
                        <form>
                            <div className="row g-3">

                                <div className="col-md-2">
                                    <input type="text" className="form-control" 
                                        name="searchText"
                                        onChange={(e) => handleFilters('searchText', e.target.value)}
                                        value={filterRecords.searchText}
                                        placeholder="Search for something" />
                                </div>

                                <div className="col-md-2">
                                    <div className="input-light">
                                        <select className="form-control" 
                                            name="hostId" 
                                            value={hostId ? hostId : filterRecords.hostId}
                                            disabled={hostId ? true : false}
                                            onChange={(e) => handleFilters('hostId', e.target.value)}>
                                            <option value="">Filter by Host</option>
                                            {hostList.map((item, index) => {
                                                return(
                                                    <option key={index} value={item._id}>{item.first_name} {item.last_name}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="input-light">
                                        <select className="form-control" 
                                            name="type" 
                                            onChange={(e) => handleFilters('type', e.target.value)}>
                                            <option value="">Filter by type</option>
                                            {types.map((item, index) => {
                                                return(
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="input-light">
                                        <select className="form-control" 
                                            name="type" 
                                            onChange={(e) => handleFilters('subType', e.target.value)}>
                                            <option value="">Filter by sub type</option>
                                            {subTypes.map((item, index) => {
                                                return(
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="input-light">
                                        <select className="form-control" name="sortBy" 
                                            value={filterRecords.sortBy}
                                            onChange={(e) => handleFilters('sortBy', e.target.value)}>
                                            <option value="">Sort by property title</option>
                                            <option value="asc" >Ascending</option>
                                            <option value="desc" >Descending </option>
                                        </select>
                                    </div>
                                </div>
                                <WithPermission module="Properties" name="add_properties" message="no" multiple="false" >
                                <div className="col-md-2">
                                    {hostId === null ?
                                    <Link className='btn btn-success w-100' to={"/property-add/"}>Add New Property</Link>
                                    :
                                    <Link className='btn btn-success' to={"/host-property-add/"+hostId}>Add New Property</Link>
                                    }
                                </div>
                                </WithPermission>
                            </div>
                        </form>
                    </div>
                   
                </div>
                <div className="col-xl-12">
                {data.length > 0 ?
                    <div className="card">

                        <div className="card-body">
                            <div className="live-preview">
                                <div className="table-responsive mb-3">
                                
                                    <table className="table table-striped table-nowrapp align-middle mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Property Name</th>
                                                <th scope='col'>Host Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Sub Type</th>
                                                <th scope="col" width="30%">Address</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.propertyName}</td>
                                                        <td>{item.hostName}</td>
                                                        <td>{getTypeName(item.type)}</td>
                                                        <td>{getSubTypeName(item.subType)}</td>
                                                        <td>{item.fullAddress}</td>
                                                        <td>{ (item.isRemoved === 0) ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span> }</td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <WithPermission module="Properties" name="edit_properties" message="no" multiple="false" >
                                                                    <div className="edit">
                                                                        {hostId === null ?
                                                                            <Link to={`/property-edit/${item._id}`} className="btn btn-sm btn-success edit-item-btn" >View Details</Link>
                                                                            :
                                                                            <Link to={`/host-property-edit/${item._id}/${hostId}`} className="btn btn-sm btn-success edit-item-btn" >View Details</Link>
                                                                        }
                                                                    </div>

                                                                    {hostId === null &&
                                                                        <>
                                                                            {item.isRemoved === 0 ?
                                                                                <div className="edit">
                                                                                    <button onClick={(e) => removeProperty(item._id)} className="btn btn-sm btn-danger edit-item-btn" >Inactive</button>
                                                                                </div>
                                                                                :
                                                                                <div className="edit">
                                                                                    <button onClick={(e) => activateProperty(item._id)} className="btn btn-sm btn-secondary edit-item-btn" >Active</button>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </WithPermission>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                    
                                    
                                </div>
                                <ReactPaginate
                                        previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                        nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                        breakLabel="..."
                                        pageCount={PageCount}
                                        pageRangeDisplayed={3}
                                        onPageChange={getData}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        renderOnZeroPageCount={null}
                                        activeClassName={'active'}
                                    />
                            </div>

                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="alert alert-danger" role="alert">No Property found.</div>
                        </div>
                    </div>
                }
                    
                </div>
            </div>
        </div>
    </div>
      
    </>
    )
}

export default Home;
