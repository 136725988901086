import React, { useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import helper from '../helper';


const PrivacyPolicies = (props) => {
    const token = Cookies.get('token');
    const [descriptionError, setdescriptionError] = useState('');
    const [policyContent, setPolicyContent] = useState('');

    const fetchContent = async() => {
        const {data} = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/get-privacy-policy-content',{
                            headers: {
                                'Content-Type': 'application/json',
                                'token': token
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
        if(data.status === 1) {
            setPolicyContent(data.data.content);
        }
    }

    const submitForm = async (event) => {
        event.preventDefault();
        axios.post(process.env.REACT_APP_BACKENDURL+'api/common/add-privacy-policy',{
            'content': policyContent
        },{
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .then(function (response) {
            props.setLoading(false);
            if(response.data.status === 1){
                helper.Toast().fire({ icon: 'success', title: response.data.message })
            }else{
                helper.Toast().fire({ icon: 'error', title: response.data.message })
            }
        })
        .catch(function (error) {
            props.setLoading(false);
            helper.Toast().fire({ icon: 'error', title: "Something went wrong. Please try again." })
        });
    }

    useEffect(() => {
        fetchContent();
    }, [])

    return (
    <>
        <div className="page-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Update Privacy Policies</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Update Privacy Policies</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="live-preview">
                            <form onSubmit={submitForm} method="post">
                                <div className="row gy-4">
                                    <div className="col-md-12">
                                        <label className="form-label">Update Privacy Policies</label>
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ paddingTop: '50px', overflow: 'auto', maxHeight: 'calc(80vh - 100px)' }}>
                                                <div style={{ position: 'sticky', top: '0' }}>
                                                    <CKEditor
                                                        editor={ ClassicEditor }
                                                        data={(policyContent) ? policyContent : ''}
                                                        config={ {
                                                            // toolbar: [  'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' , 'link' ],
                                                            toolbar: [ 'bold', 'bulletedList', 'numberedList', 'link' ],
                                                            // ui: {
                                                            //     poweredBy: {
                                                            //         forceVisible: false
                                                            //     }
                                                            // }
                                                        } }
                                                        onChange={ ( event, editor ) => {
                                                            const data = editor.getData();
                                                            // let contentText = helper.removeTag(data)
                                                            // if(contentText.length > 500){
                                                            //     let error = `Character limit exceeded. Allowed 500 characters, you have entered ${a.length}.`;
                                                            //     setdescriptionError(error)
                                                            //     return;
                                                            // }else{
                                                                setdescriptionError('')
                                                                setPolicyContent(data);
                                                            // }
                                                        } }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {descriptionError && <div className="text-danger"> {descriptionError} </div>}
                                    </div>
                                    <div className="col-md-12">
                                        <button type='submit' className='btn btn-primary'>Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default PrivacyPolicies;